import React, { useContext, useState, useEffect, useRef } from "react";
import logopicture from "../../assets/Group-2394-2.png";
import { ThemeContext } from "../context/themeContext/ThemeContext";
import Avatar from "../Avatar/Avatar";
import { useSelector } from "react-redux";

function NavigationBar({
  setSidebarVisible,
  sidebarVisible,
  openAsideBar,
  toggleAsideBar,
}) {
  const { color, textColor, backgroundColor, setSideBarOpen, sideBarOpen } =
    useContext(ThemeContext);
  const [avatarDropdown, setAvatarDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const username = useSelector((state) => state.auth.username);
  const lastname = useSelector((state) => state.auth.lastname);

  const toggleSidebar = () => {
    setSidebarVisible((prevSidebarVisible) => !prevSidebarVisible);
    setSideBarOpen((prevSideBarOpen) => !prevSideBarOpen);
  };

  const toggleAvatarDropdown = () => {
    setAvatarDropdown(!avatarDropdown);
  };

  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start rtl:justify-end">
            <div className="flex items-center ms-2 md:me-24">
              <img
                src={logopicture}
                className="h-14 w-36 me-9"
                alt="FlowBite Logo"
              />
              <button
                onClick={toggleSidebar}
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-2xl hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Toggle sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          <div className="relative">
            <div className="flex items-center">
              <div className={`hidden xl:block md:hidden`}>
                <h1
                  className="text-3xl font-semibold mr-6"
                  style={{ color: "#9d9fa2" }}
                >
                  Welcome {username} {lastname}
                </h1>
              </div>

              <div className="flex items-center ms-3">
                <div className="p-1 mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className={`size-6`}
                    fill={`${textColor}`}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div ref={dropdownRef} onClick={toggleAvatarDropdown}>
                  <Avatar avatarDropdown={avatarDropdown} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
