import React, { useEffect, useState, useContext } from "react";
import "chart.js/auto";

import MonthlyWatering from "../../../components/charts/MonthlyWatering";
import EstimatedQuarterlyWaterSavings from "../../../components/charts/EstimatedQuarterlyWaterSavings";
import ApiLink from "../../../components/api/ApiLink";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function AccountCompanyInfo() {
  const { companyName } = useParams();

  // console.log("Company from accountcompanyInfo: ", companyName);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const usersType = useSelector((state) => state.auth.userType);
  const companyId = useSelector((state) => state.auth.company_id);

  const [companyLocationData, setCompanyLocationData] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const { updateSelectedLocation } = useSelectedCompany();
  const [loading, setLoading] = useState(false);

  const [selectedCompanyName, setSelectedCompanyName] = useState({
    companyName: null,
    company_id: null,
    city: null,
    state: null,
    zip: null,
    type: null,
  });

  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
    sideBarOpen,
  } = useContext(ThemeContext);

  useEffect(() => {
    const fetchCompanyList = async () => {
      if (usersType !== 1) {
        setSelectedCompanyName({
          companyName: null,
          company_id: companyId,
          city: null,
          state: null,
          zip: null,
          type: null,
        });
        return;
      }
      try {
        const response = await ApiLink.post("/api/company-list", {
          email: email,
          password: password,
        });

        if (response.status === 200) {
          const companies = response.data.Data;
          const matchingCompany = companies.find(
            (company) => company.company_id == companyName
          );

          if (matchingCompany) {
            console.log("Matching company found:", matchingCompany);
            setSelectedCompanyName({
              companyName: matchingCompany.name,
              company_id: matchingCompany.company_id,
              city: matchingCompany.city,
              state: matchingCompany.state,
              zip: matchingCompany.zip,
              type: matchingCompany.type,
            });
          } else {
            console.log(
              "No matching company found for company_id:",
              companyName
            );
            setSelectedCompanyName({
              companyName: null,
              company_id: null,
              city: null,
              state: null,
              zip: null,
              type: null,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching company list:", error);
      }
    };

    fetchCompanyList();
  }, [companyName, email, password]);

  useEffect(() => {
    const fetchUserList = async () => {
      setLoading(true);
      try {
        const response = await ApiLink.post("/api/user-list", {
          email: email,
          password: password,
          company_id: selectedCompanyName.company_id,
        });

        if (response.status === 200) {
          if (Array.isArray(response.data.Data)) {
            setUserListData(response.data.Data);
          }
        } else {
          console.error("Error:", response.data);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCompanyName.company_id) {
      fetchUserList();
    }
  }, [email, password, selectedCompanyName.company_id]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await ApiLink.post("/api/company-locations", {
          email: email,
          password: password,
          company_id: selectedCompanyName.company_id,
        });

        if (response.status === 200) {
          if (Array.isArray(response.data.Data.Company_location)) {
            setCompanyLocationData(response.data.Data.Company_location);
          } else {
            setCompanyLocationData([]);
          }
        }
      } catch (error) {}
    };
    if (selectedCompanyName.company_id) {
      fetchLocation();
    }
  }, [email, password, selectedCompanyName.company_id]);

  const handleLocationClick = (
    locationId,
    locationName,
    companyId,
    city,
    state,
    zip,
    address
  ) => {
    updateSelectedLocation({
      locationId,
      locationName,
      companyId,
      city,
      state,
      zip,
      address,
    });
  };

  return (
    <>
      <div
        className={`min-h-screen flex justify-center flex-col bg-gray-100 dark:bg-gray-700 transition-all duration-300 mt-20 p-4 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "" }}
      >
        <div className="p-4 shadow-md bg-white border-gray-200 border-dashed rounded-lg dark:border-gray-700 dark:bg-gray-800">
          <div className="">
            <MonthlyWatering />
          </div>
          <div className="mt-6">
            <EstimatedQuarterlyWaterSavings />
          </div>
          <div className="flex flex-col lg:flex-col xl:flex-row gap-6 relative overflow-x-auto">
            <table className="border animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 font-inter">
              <caption className="p-5 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                Sustainable Sprinklers Users
              </caption>
              <thead
                className="text-sm uppercase leading-4 border border-black"
                style={{
                  backgroundColor: `${tableHeaderColor}`,
                  color: `${tableHeaderTextColor}`,
                }}
              >
                <tr>
                  <th scope="col" className="px-6 py-3 border border-black">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 border border-black">
                    Address
                  </th>
                  <th scope="col" className="px-6 py-3 border border-black">
                    Phone
                  </th>
                </tr>
              </thead>
              <tbody className="text-black dark:text-white leading-4">
                {loading ? (
                  <tr>
                    <td colSpan="3" className="h-64">
                      <div
                        role="status"
                        className="flex justify-center items-center h-full"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                ) : userListData.length > 0 ? (
                  userListData.map((userlist) => (
                    <tr
                      key={userlist.usersid}
                      className="odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white border-b dark:bg-gray-800 dark:border-gray-700 "
                    >
                      <td className="border border-black dark:border-white px-6 py-4">
                        {userlist.first_name} {userlist.last_name}
                      </td>
                      <td className="border border-black dark:border-white px-6 py-4">
                        {userlist.address_line1}
                      </td>
                      <td className="border border-black dark:border-white px-6 py-4">
                        {userlist.phone}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                      </svg>

                      <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                        No Sustainable Sprinklers Users found
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <table className="border animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 font-inter">
              <caption className="p-5 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                Sustainable Sprinklers Account Locations
              </caption>
              <thead
                className="text-sm uppercase leading-4"
                style={{
                  backgroundColor: `${tableHeaderColor}`,
                  color: `${tableHeaderTextColor}`,
                }}
              >
                <tr>
                  <th scope="col" className="px-6 py-3 border border-black">
                    Location Name
                  </th>
                  <th scope="col" className="px-6 py-3 border border-black">
                    Address
                  </th>
                </tr>
              </thead>
              <tbody className="text-black dark:text-white text-sm leading-4">
                {loading ? (
                  <tr>
                    <td colSpan="3" className="h-64">
                      <div
                        role="status"
                        className="flex justify-center items-center h-full"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                ) : companyLocationData.length > 0 ? (
                  companyLocationData.map((location) => (
                    <tr
                      key={location.company_id}
                      className="odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white border-b dark:bg-gray-800 dark:border-gray-700 "
                    >
                      <td className="border border-black dark:border-white px-6 py-4">
                        <h1
                          className="hover:underline cursor-pointer"
                          // onClick={() =>
                          //   handleLocationClick(
                          //     location.company_location_id,
                          //     location.name,

                          //     location.company_id,
                          //     location.city,
                          //     location.state,
                          //     location.zip,
                          //     location.address_line1
                          //   )
                          // }
                        >
                          <Link
                            to={`/location/${location.name.replace(
                              /\s+/g,
                              "-"
                            )}`}
                          >
                            {location.name}
                          </Link>
                        </h1>
                      </td>
                      <td className="border border-black dark:border-white px-6 py-4">
                        {location.address_line1}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                      </svg>

                      <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                        No Account Locations found
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
