import React, { useContext } from "react";
import UniversalChangePass from "../../../../components/AvatarDropdownContent/UniversalChangePass";
import { ThemeContext } from "../../../../components/context/themeContext/ThemeContext";

export default function AccountChangePassword() {
  const { sideBarOpen } = useContext(ThemeContext);
  return (
    <div
      className={`min-h-screen bg-white dark:bg-gray-700 transition-all duration-300 `}
      style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
    >
      <div className="p-4 mt-20">
        <UniversalChangePass pageParent="Account Menu" />
      </div>
    </div>
  );
}
