import { logout } from "./authSlice";
import { resetSelectedCompanyState } from "./selectedCompanySlice";
import { persistStore, PURGE } from "redux-persist";

// Define the logout action and purge the persist
export const logoutAndClearState = () => (dispatch) => {
  dispatch(logout()); // Logout action to clear auth state
  dispatch(resetSelectedCompanyState()); // Clear selected company state
  dispatch({ type: PURGE, result: () => null }); // Clear persisted state
};
