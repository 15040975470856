import React from "react";
import { Route, Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LocationAside from "../../LocationAside";
import ApiLink from "../../../components/api/ApiLink"; // Adjust the path as needed
import { useState, useEffect } from "react";

const LocationRouteWrapper = () => {
  const { companyName } = useParams();
  const auth = useSelector((state) => state.auth);
  const { userType, user } = auth;
  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companyVendorData, setCompanyVendorData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // Define API functions first
  const postCompanyData = async () => {
    try {
      const response = await ApiLink.post("/api/company-info", {
        email: user.email,
        password: auth.password,
      });

      if (response.status === 200) {
        const companyInfo = response.data.Data.Company;
        setCompanyData(companyInfo);
        return companyInfo;
      }
    } catch (error) {
      console.error("Error posting company data:", error);
      return null;
    }
  };

  const postCompanyVendorData = async () => {
    try {
      const response = await ApiLink.post("/api/get-land-scape-data", {
        email: user.email,
        password: auth.password,
      });

      if (response.status === 200) {
        setCompanyVendorData(response.data.Data);
      }
    } catch (error) {
      console.error("Error posting vendor data:", error);
      return null;
    }
  };

  // Then use them in useEffect
  useEffect(() => {
    const fetchData = async () => {
      if (counter < 3 && user.company_location_id === null) {
        const companyResponse = await postCompanyData();
        if (companyResponse?.type === 8) {
          await postCompanyVendorData();
        }
        setCounter((prev) => prev + 1);
        setIsInitialLoading(false);
      }
    };
    fetchData();
  }, [counter, user.company_location_id]);

  // Wait for initial data load
  if (isInitialLoading) {
    return null; // or return a loading spinner
  }

  // For userType 1, allow access to all locations
  if (userType === 1) {
    return <LocationAside />;
  }

  // Function to post data to API and state to track loading
  const postLocationData = async () => {
    try {
      const response = await ApiLink.post("/api/company-locations", {
        email: user.email,
        password: auth.password,
        company_id: user.company_id,
        status: 1,
      });

      if (response.status === 200) {
        setLocationData(response.data.Data.Company_location);
      } else {
        throw new Error(`Request failed with status code ${response.status}`);
      }
    } catch (error) {
      console.error("Error posting location data:", error);
      // Don't throw the error, just log it
      return null;
    }
  };

  // For userType 2 or 4, check if companyName is in the allowed list
  if (user.company_location_id !== null) {
    if (userType === 2 || userType === 4) {
      const allowedLocationIds = user?.company_location_id || [];
      return allowedLocationIds.includes(companyName) ? (
        <LocationAside />
      ) : (
        <Redirect to="/notfound" />
      );
    }
  }

  // Check if company is type 8 and companyName exists in vendor data
  if (companyData.type === 8) {
    const isVendorLocation = companyVendorData.some(
      (vendor) => vendor.company_location_id.toString() === companyName
    );
    return isVendorLocation ? <LocationAside /> : <Redirect to="/notfound" />;
  }

  const isCompanyNameInLocationData = () => {
    if (!locationData || !locationData.length) return false;
    return locationData.some(
      (location) => location.company_location_id.toString() === companyName
    );
  };

  if (locationData.length > 0 && !isCompanyNameInLocationData()) {
    return <Redirect to="/notfound" />;
  }

  return <LocationAside />;
};

const LocationRoute = () => {
  return (
    <Route
      path="/location/:companyName"
      render={(props) => <LocationRouteWrapper {...props} />}
    />
  );
};

export default LocationRoute;
