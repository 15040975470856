import React, { useState } from "react";
import ApiLink from "../components/api/ApiLink";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ForgotPassword() {
  const history = useHistory();
  const swal = require("sweetalert2");

  const [email, setEmail] = useState("");

  const handResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiLink.post("/api/forgot-password-reset-link", {
        email: email,
      });

      if (response.status === 200) {
        swal.fire({
          title: "A password reset link has been sent to your email.",
          icon: "success",
          toast: true,
          timer: 4000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        history.push("/");
      }
    } catch (error) {
      swal.fire({
        title: "Something went wrong. Please try again later.",
        icon: "warning",
        toast: true,
        timer: 4000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  return (
    <div className="min-h-screen flex items-center justify-center px-6 py-12 sm:px-6 lg:px-8 bg-gray-100">
      <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-center text-2xl font-semibold text-gray-700">
          Forgot Password
        </h2>
        <p className="text-center text-sm text-gray-500">
          Please use your registered email!
        </p>
        <form onSubmit={handResetPassword} className="mt-6">
          <div>
            <label htmlFor="email" className="font-medium text-[#6a6c6f]">
              Email Address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="user@domain.com"
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-green-200 focus:border-[#62CB31] focus:z-10 sm:text-sm"
            />
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-[#62CB31] hover:bg-[#74D348] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#62CB31]"
            >
              Request Password Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
