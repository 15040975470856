import { Legend, plugins } from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";

const SeverityCharts = ({ severityCounts }) => {
  const labels = Object.keys(severityCounts);
  const data = {
    labels: labels.map(
      (label) => label.charAt(0).toUpperCase() + label.slice(1)
    ),
    datasets: [
      {
        data: Object.values(severityCounts),
        backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#FF9F40"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#FF9F40"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce(
              (acc, val) => acc + val,
              0
            );
            const percentage =
              total > 0 ? ((value / total) * 100).toFixed(1) + "%" : "0%";
            return `${label}: ${value} (${percentage})`;
          },
        },
      },
    },
  };

  const total = Object.values(severityCounts).reduce(
    (acc, val) => acc + val,
    0
  );

  return (
    <div className="flex flex-col justify-center items-center w-full md:w-72">
      <h1 className="mb-4 text-lg font-medium text-black dark:text-white">
        Severity alerts distribution
      </h1>
      <div className="w-full max-w-xs">
        <Pie data={data} options={options} />
      </div>
      <div className="mt-4 text-sm text-gray-600 dark:text-gray-400">
        Total Alerts: {total}
      </div>
    </div>
  );
};

export default SeverityCharts;
