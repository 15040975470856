// FloatingSettings.js
import React, { useState, useEffect, useContext, useRef } from "react";
import { ThemeContext } from "./context/themeContext/ThemeContext";
import "animate.css";
import lightmode from "../assets/lightmode.png";
import darkmode from "../assets/darkmode.png";
import { SketchPicker } from "react-color";

export default function FloatingSettings({}) {
  const [showSettings, setShowSettings] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const {
    color,
    handleThemeColor,
    handleTextColor,
    textColor,
    handleBackgroundColor,
    handleTableHeaderColor,
    backgroundColor,
    tableHeaderColor,
    handleTableHeaderTextColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const [rotation, setRotation] = useState(0);
  const [animation, setAnimation] = useState("");
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [showTableHeaderColorPicker, setShowTableHeaderColorPicker] =
    useState(false);
  const [showTableHeaderTextColorPicker, setShowTableHeaderTextColorPicker] =
    useState(false);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const settingsRef = useRef(null);

  const toggleShowSettings = () => {
    if (showSettings) {
      setAnimation("animate__backOutRight");
      setTimeout(() => {
        setShowSettings(false);
      }, 500);
    } else {
      setShowSettings(true);
      setAnimation("animate__bounceInRight");
    }
    setRotation(rotation + 90);
  };

  const handleRadioChange = (event) => {
    const mode = event.target.value;
    if ((mode === "dark" && !isDarkMode) || (mode === "light" && isDarkMode)) {
      toggleDarkMode();
    }
  };

  const handleTextColorChange = (newColor) => {
    if (newColor && newColor.hex) {
      handleTextColor(newColor.hex);
    }
  };

  const handleNewBackgroundColor = (newColor) => {
    if (newColor && newColor.hex) {
      handleBackgroundColor(newColor.hex);
    }
  };

  const handleNewTableHeaderColor = (newColor) => {
    if (newColor && newColor.hex) {
      handleTableHeaderColor(newColor.hex);
    }
  };

  const handleNewTableHeaderTextColor = (newColor) => {
    if (newColor && newColor.hex) {
      handleTableHeaderTextColor(newColor.hex);
    }
  };

  const toggleShowColorPicker = () => {
    setShowColorPicker(!showColorPicker);
    setShowTextColorPicker(false);
    setShowTableHeaderColorPicker(false);
    setShowTableHeaderTextColorPicker(false);
  };

  const toggleShowTextColorPicker = () => {
    setShowTextColorPicker(!showTextColorPicker);
    setShowColorPicker(false);
    setShowTableHeaderColorPicker(false);
    setShowTableHeaderTextColorPicker(false);
  };

  const toggleTableHeaderColorPicker = () => {
    setShowTableHeaderColorPicker(!showTableHeaderColorPicker);

    setShowColorPicker(false);
    setShowTextColorPicker(false);
    setShowTableHeaderTextColorPicker(false);
  };

  const toggleTableHeaderTextColorPicker = () => {
    setShowTableHeaderTextColorPicker(!showTableHeaderTextColorPicker);
    setShowColorPicker(false);
    setShowTextColorPicker(false);
    setShowTableHeaderColorPicker(false);
  };

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        closeSettings();
      }
    };

    if (showSettings) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSettings]);
  const closeSettings = () => {
    setAnimation("animate__backOutRight");
    setTimeout(() => {
      setShowSettings(false);
    }, 500);
  };

  return (
    <div className="font-inter">
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          width: "50px",
          height: "50px",
          borderRadius: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          zIndex: 99,
        }}
      >
        <button
          style={{ transform: `rotate(${rotation}deg)`, borderRadius: "50%" }}
          className="relative inline-flex items-center justify-center mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
          onClick={toggleShowSettings}
        >
          <span
            className="relative px-4 transition-all ease-in-out duration-500 bg-white dark:bg-gray-900 group-hover:bg-opacity-0"
            style={{ transform: `rotate(${rotation}deg)` }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </span>
        </button>
      </div>

      {showSettings && (
        <div
          className={`animate__animated ${animation} h-full overflow-y-auto`}
          style={{
            position: "fixed",
            right: "5px",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            zIndex: 99,
          }}
          ref={settingsRef}
        >
          <div className="h-full bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-900 w-full max-w-md">
            <div className="px-3 py-4">
              <div className="p-1 bg-white dark:bg-gray-900 border border-black dark:border-white rounded-md">
                <div className="flex justify-between items-center p-1">
                  <h1 className="font-bold text-lg text-gray-900 dark:text-white pl-1 pt-2">
                    Settings
                  </h1>
                  <button
                    onClick={toggleShowSettings}
                    className=" absolute pr-6 right-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6 dark:text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <h1 className="font-semibold text-gray-900 dark:text-white pl-4 pt-2">
                  Appearance:
                </h1>
                <p
                  className="mt-1 textalign pl-4 text-left text-sm text-black dark:text-gray-300 leading-tight"
                  style={{ width: 300 }}
                >
                  Choose the mode in which you want to view your helpdesk.
                </p>
                <div className="flex justify-center">
                  <div className="m-4 p-1 border-2 border-black dark:border-white focus:ring-blue-300">
                    <img src={lightmode} alt="lightmode" className="h-48" />
                    <h1></h1>
                  </div>

                  <div className="m-4 p-1 border-2 border-black dark:border-white">
                    <img src={darkmode} alt="darkmode" className="h-48" />
                  </div>
                </div>
                <div className="flex items-center justify-center gap-14 pb-2">
                  <div className="flex items-center">
                    <input
                      checked={!isDarkMode}
                      id="default-radio-2"
                      type="radio"
                      value="light"
                      name="default-radio"
                      className={`w-4 h-4 text-${color}-600 bg-gray-100 border-gray-300 focus:ring-${color}-500 dark:focus:ring-${color}-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                      onChange={handleRadioChange}
                    />
                    <label
                      htmlFor="default-radio-2"
                      className="ms-2 text-sm font-medium text-black dark:text-white"
                    >
                      Light Mode
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      checked={isDarkMode}
                      id="default-radio-1"
                      type="radio"
                      value="dark"
                      name="default-radio"
                      className={`w-4 h-4 text-${color}-600 bg-gray-100 border-gray-300 focus:ring-${color}-500 dark:focus:ring-${color}-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                      onChange={handleRadioChange}
                    />
                    <label
                      htmlFor="default-radio-1"
                      className="ms-2 text-sm font-medium text-black dark:text-white"
                    >
                      Dark Mode
                    </label>
                  </div>
                </div>
              </div>
              <div className="colorThemes p-1 pb-2 mt-2 bg-white rounded-md dark:bg-gray-900 border border-black dark:border-white">
                <div className="">
                  <h3 className="font-bold text-gray-900 dark:text-white pl-1">
                    Theme Colors
                  </h3>
                  <p
                    className="mt-1 textalign pl-4 text-left text-sm text-black dark:text-gray-300 leading-tight"
                    style={{ width: 300 }}
                  >
                    Select the theme to be applied to your help desk.{" "}
                  </p>
                  <h1 className="font-medium mt-4 pl-1 text-black dark:text-white">
                    Select Preset Themes:{" "}
                  </h1>
                </div>
                <div className="flex justify-center py-2">
                  <div className="grid grid-cols-9 gap-0.5">
                    <button
                      className="p-3 border mr-1 focus:ring-4 focus:ring-gray-800"
                      style={{
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #ffffff 50%, #1e1e1e 50%)",
                      }}
                      onClick={() => {
                        handleThemeColor("blacknwhite");
                      }}
                    >
                      <span className="hidden">blacknwhite</span>
                    </button>
                    <button
                      className="border border-green-300 p-3 mr-1 focus:ring-4 focus:ring-green-600"
                      style={{
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #ffffff 50%, #62cb31 50%)",
                      }}
                      onClick={() => {
                        handleThemeColor("defaultGreen");
                      }}
                    >
                      <span className="hidden">default Green</span>
                    </button>

                    <button
                      className="p-3 mr-1 focus:ring-4 focus:ring-blue-300"
                      style={{
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #2563eb 50%, #1e3a8a 50%)",
                      }}
                      onClick={() => {
                        handleThemeColor("blue");
                      }}
                    >
                      <span className="hidden">blue</span>
                    </button>

                    <button
                      className="p-3 mr-1 focus:ring-4 focus:ring-green-300"
                      style={{
                        background:
                          "linear-gradient(to right, #03543F 50%, #BCF0DA 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("green")}
                    >
                      <span className="hidden">green</span>
                    </button>

                    <button
                      className="p-3 bg-purple-600 mr-1 focus:ring-4 focus:ring-purple-300"
                      style={{
                        background:
                          "linear-gradient(to right, #5521B5 50%, #DCD7FE 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("purple")}
                    >
                      <span className="hidden">purple</span>
                    </button>
                    <button
                      className="p-3 bg-red-600 mr-1 focus:ring-4 focus:ring-red-300"
                      style={{
                        background:
                          "linear-gradient(to right, #B91C1C 50%, #FEE2E2 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("red")}
                    >
                      <span className="hidden">red</span>
                    </button>
                    <button
                      className="p-3 bg-pink-600 mr-1 focus:ring-4 focus:ring-pink-300"
                      style={{
                        background:
                          "linear-gradient(to right, #99154B 50%, #FAD1E8 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("pink")}
                    >
                      <span className="hidden">pink</span>
                    </button>
                    <button
                      className="p-3 bg-yellow-600 mr-1 focus:ring-4 focus:ring-yellow-300"
                      style={{
                        background:
                          "linear-gradient(to right, #92400E 50%, #FEF9C3 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("yellow")}
                    >
                      <span className="hidden">yellow</span>
                    </button>

                    <button
                      className="p-3 bg-orange-600 mr-1 focus:ring-4 focus:ring-gray-900"
                      style={{
                        background:
                          "linear-gradient(to right, #2F2F2F 50%, #FE6F27 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("blackorange")}
                    >
                      <span className="hidden">blackorange</span>
                    </button>
                    <button
                      className="p-3 bg-red-300 mr-1 focus:ring-4 focus:ring-orange-300"
                      style={{
                        background:
                          "linear-gradient(to right, #FFA67E 50%, #5A0F2E 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("maroonpeach")}
                    >
                      <span className="hidden">maroonpeach</span>
                    </button>
                    <button
                      className="p-3 bg-blue-300 mr-1 focus:ring-4 focus:ring-blue-300"
                      style={{
                        background:
                          "linear-gradient(to right, #00DCD6 50%, #5F0121 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("purpleblue")}
                    >
                      <span className="hidden">purpleblue</span>
                    </button>
                    <button
                      className="p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-blue-900"
                      style={{
                        background:
                          "linear-gradient(to right, #030D4E 50%, #F8A01C 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("navyorange")}
                    >
                      <span className="hidden">navyorange</span>
                    </button>
                    <button
                      className="p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-blue-700"
                      style={{
                        background:
                          "linear-gradient(to right, #10377A 50%, #9FAECE 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("darkblue")}
                    >
                      <span className="hidden">darkblue</span>
                    </button>
                    <button
                      className="p-3 mr-1 focus:ring-4 focus:ring-blue-200"
                      style={{
                        background:
                          "linear-gradient(to right, #ABDBE6 50%, #7888DE 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("powderblue")}
                    >
                      <span className="hidden">powderblue</span>
                    </button>

                    <button
                      className="p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-blue-600"
                      style={{
                        background:
                          "linear-gradient(to right, #FEC72D 50%, #367CF1 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("royalblue")}
                    >
                      <span className="hidden">royalblue</span>
                    </button>

                    <button
                      className="p-3 mr-1 focus:ring-4 focus:ring-gray-600"
                      style={{
                        background:
                          "linear-gradient(to right, #ECC7B2 50%, #504645 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("emperorgray")}
                    >
                      <span className="hidden">emperorgray</span>
                    </button>
                    <button
                      className="p-3 mr-1 focus:ring-4 focus:ring-purple-400"
                      style={{
                        background:
                          "linear-gradient(to right, #60067D 50%, #C499CF 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("indigolavender")}
                    >
                      <span className="hidden">indigolavender</span>
                    </button>
                    <button
                      className="p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-blue-700"
                      style={{
                        background:
                          "linear-gradient(to right, #591FBF 50%, #A4E8CC 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("purplewater")}
                    >
                      <span className="hidden">purplewater</span>
                    </button>
                    <button
                      className="p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-red-300"
                      style={{
                        background:
                          "linear-gradient(to right, #FCB6B0 50%, #993240 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("sundownpink")}
                    >
                      <span className="hidden">sundownpink</span>
                    </button>
                    <button
                      className="p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-blue-200"
                      style={{
                        background:
                          "linear-gradient(to right, #BCFDF7 50%, #E13D56 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("lightblue")}
                    >
                      <span className="hidden">lightblue</span>
                    </button>
                    <button
                      className="p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-green-200"
                      style={{
                        background:
                          "linear-gradient(to right, #390979 50%, #B9DF10 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("lemongreen")}
                    >
                      <span className="hidden">lemongreen</span>
                    </button>
                    <button
                      className="p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-gray-700"
                      style={{
                        background:
                          "linear-gradient(to right, #A3842B 50%, #585001 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("bistrebrown")}
                    >
                      <span className="hidden">bistrebrown</span>
                    </button>
                    <button
                      className="border p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-gray-700"
                      style={{
                        background:
                          "linear-gradient(to right, #A3842B 50%, #ffffff 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("naturalbrown")}
                    >
                      <span className="hidden">naturalbrown</span>
                    </button>
                    <button
                      className="border p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-blue-900"
                      style={{
                        background:
                          "linear-gradient(to right, #390979 50%, #ffffff 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("naturalblue")}
                    >
                      <span className="hidden">naturalblue</span>
                    </button>

                    <button
                      className="border p-3 bg-blue-700 mr-1 focus:ring-4 focus:ring-red-900"
                      style={{
                        background:
                          "linear-gradient(to right, #993240 50%, #ffffff 50%)",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleThemeColor("naturalred")}
                    >
                      <span className="hidden">naturalred</span>
                    </button>

                    <button
                      className="border p-3 mr-1 focus:ring-4 focus:ring-orange-200"
                      style={{
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #ffffff 50%, #FEC72D 50%)",
                      }}
                      onClick={() => {
                        handleThemeColor("naturalorange");
                      }}
                    >
                      <span className="hidden">naturalorange</span>
                    </button>
                    <button
                      className="border p-3 mr-1 focus:ring-4 focus:ring-blue-200"
                      style={{
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #ffffff 50%, #218AC1 50%)",
                      }}
                      onClick={() => {
                        handleThemeColor("naturallighblue");
                      }}
                    >
                      <span className="hidden">naturallighblue</span>
                    </button>
                  </div>
                </div>{" "}
                <div className="flex flex-col pl-1 mt-4 gap-3">
                  <h1 className="font-semibold text-black dark:text-white">
                    Color Picker:{" "}
                  </h1>
                  <div className="flex flex-wrap justify-around ">
                    <button
                      className={`p-2 bg-gray-300 mr-1 rounded-md focus:ring-4 focus:ring-${color}-300`}
                      onClick={toggleShowColorPicker}
                    >
                      <span className="hidden">Background</span>
                      <h1
                        className="text-xs items-center font-semibold font-roboto"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                      >
                        Background
                      </h1>
                    </button>
                    <button
                      className={`p-2 bg-gray-300 mr-1 rounded-md focus:ring-4 focus:ring-${color}-300`}
                      onClick={toggleShowTextColorPicker}
                    >
                      <span className="hidden">Text</span>
                      <h1 className="text-xs items-center font-semibold font-roboto">
                        Text
                      </h1>
                    </button>

                    <button
                      className={`p-2 bg-gray-300 mr-1 rounded-md focus:ring-4 focus:ring-${color}-300`}
                      onClick={toggleTableHeaderColorPicker}
                    >
                      <span className="hidden">TableHeader</span>
                      <h1
                        className="text-xs items-center font-semibold font-roboto"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                      >
                        Table Header
                      </h1>
                    </button>

                    <button
                      className={`p-2 bg-gray-300 mr-1 rounded-md focus:ring-4 focus:ring-${color}-300`}
                      onClick={toggleTableHeaderTextColorPicker}
                    >
                      <span className="hidden">Table Header Text</span>
                      <h1
                        className="text-xs items-center font-semibold font-roboto"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                      >
                        Table Header Text
                      </h1>
                    </button>
                  </div>
                </div>
                {showColorPicker && (
                  <div className="my-2 pb-14">
                    <SketchPicker
                      color={backgroundColor}
                      onChange={handleNewBackgroundColor}
                    />
                  </div>
                )}
                {showTextColorPicker && (
                  <div className="my-2 pb-14">
                    <SketchPicker
                      color={textColor}
                      onChangeComplete={handleTextColorChange}
                    />
                  </div>
                )}
                {showTableHeaderColorPicker && (
                  <div className="my-2 pb-14">
                    <SketchPicker
                      color={tableHeaderColor}
                      onChangeComplete={handleNewTableHeaderColor}
                    />
                  </div>
                )}
                {showTableHeaderTextColorPicker && (
                  <div className="my-2 pb-14">
                    <SketchPicker
                      color={tableHeaderTextColor}
                      onChangeComplete={handleNewTableHeaderTextColor}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
