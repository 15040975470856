import React, { useState } from "react";
import ApiLink from "../../api/ApiLink";
import { useSelector } from "react-redux";

export default function AddDevice({
  addDevice,
  setAddDevice,
  firmwarePackage,
  firmwareVersion,
  fetchGetDeviceData,
}) {
  const Swal = require("sweetalert2");

  const [serialNumber, setSerialNumber] = useState("");
  const [firmwarePackageIdAdd, setFirmwarePackageIdAdd] = useState("");
  const [friendlyDeviceName, setFriendlyDeviceName] = useState("");
  const [otaEnabled, setOtaEnabled] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [otaLastRequest, setOtaLastRequest] = useState("");
  const [model, setModel] = useState("");
  const [firmwareVersionOption, setFirmwareVersionOption] = useState("");
  const [status, setStatus] = useState("1");
  const [deviceSimNumber, setDeviceSimNumber] = useState("");
  const [modemNumber, setModemNumber] = useState("");
  const [simCarrier, setSimCarrier] = useState("");
  const [notes, setNotes] = useState("");

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const handleAddDeviceSubmit = (e) => {
    e.preventDefault();
    console.log("Add Submit Device Details");
    console.log("SerialNumber: ", serialNumber);
    console.log("FirmwarePackageId: ", firmwarePackageIdAdd);
    console.log("FriendlyDeviceName: ", friendlyDeviceName);
    console.log("OtaEnabled: ", otaEnabled);
    console.log("DeviceType: ", deviceType);
    console.log("OtaLastRequest: ", otaLastRequest || "0000-00-00 00:00:00");
    console.log("Model: ", model);
    console.log("FirmwareVersionOption: ", firmwareVersionOption);
    console.log("Status: ", status);
    console.log("DeviceSimNumber: ", deviceSimNumber || null);
    console.log("ModemNumber: ", modemNumber || null);
    console.log("SimCarrier: ", simCarrier || null);
    console.log("Notes: ", notes);

    Swal.fire({
      title: "Do you want to add this device?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/add-device", {
            email: email,
            password: password,
            serial_number: serialNumber,
            device_identifier: friendlyDeviceName,
            type: deviceType,
            model: model,
            status: status,
            modem_number: modemNumber,
            firmware_package_id: firmwarePackageIdAdd,
            ota_enabled: otaEnabled,
            ota_last_request: otaLastRequest,
            firmware: firmwareVersionOption,
            sim_phone_number: deviceSimNumber,
            sim_carrier: simCarrier,
            notes: notes,
          });
          if (response.status === 200) {
            Swal.fire({
              title: "Device Added Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            toggleCloseAddDevice();
            fetchGetDeviceData();
          }
        } catch (error) {
          Swal.fire({
            title: `There is something wrong, please try again.`,
            icon: "warning",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const toggleCloseAddDevice = () => {
    setAddDevice(false);
    setSerialNumber("");
    setFirmwarePackageIdAdd("");
    setFriendlyDeviceName("");
    setOtaEnabled(0);
    setDeviceType(0);
    setOtaLastRequest("");
    setModel("");
    setFirmwareVersionOption("");
    setStatus(null);
    setDeviceSimNumber("");
    setModemNumber("");
    setSimCarrier("");
    setNotes("");
  };

  const handleFirmwarePackageChange = (e) => {
    const selectedKey = e.target.value;
    setFirmwarePackageIdAdd(selectedKey);
  };
  return (
    <div
      id="defaultModal"
      tabindex="-1"
      aria-hidden="true"
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
      style={{
        // backdropFilter: "blur(5px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="relative p-4 w-full max-w-5xl h-full md:h-auto">
        {/* <!-- Modal content --> */}
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          {/* <!-- Modal header --> */}
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Add Device
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
              onClick={toggleCloseAddDevice}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <form onSubmit={handleAddDeviceSubmit}>
            <div className="grid gap-4 mb-4 sm:grid-cols-3">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Serial Number
                </label>
                <input
                  type="text"
                  name="name"
                  value={serialNumber}
                  onChange={(e) => setSerialNumber(e.target.value)}
                  required
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </div>
              <div>
                <label
                  htmlFor="firmwarePackage"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Firmware Package
                </label>

                <select
                  id="firmwarePackage"
                  value={firmwarePackageIdAdd}
                  onChange={handleFirmwarePackageChange}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="" disabled>Select a Firmware Package</option>

                  {Object.entries(firmwarePackage).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor="friendlyDeviceName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Friendly Device Name
                </label>
                <input
                  type="text"
                  name="friendlyDeviceName"
                  value={friendlyDeviceName}
                  required
                  onChange={(e) => setFriendlyDeviceName(e.target.value)}
                  id="friendlyDeviceName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </div>

              <div>
                <label
                  htmlFor="countries"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  OTA Enabled
                </label>
                <select
                  id="otaEnabled"
                  value={otaEnabled}
                  onChange={(e) => setOtaEnabled(Number(e.target.value))}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="deviceType"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Device Type
                </label>
                <select
                  id="countries"
                  value={deviceType}
                  required
                  onChange={(e) => setDeviceType(Number(e.target.value))}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="" disabled>Select Device Type</option>
                  <option value="0">Raspberry Pi</option>
                  <option value="1">S3 Renew Battery</option>
                  <option value="2">S3 Home</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="otaLastRequest"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  OTA Last Request
                </label>
                <input
                  type="text"
                  name="otaLastRequest"
                  value={otaLastRequest}
                  onChange={(e) => setOtaLastRequest(e.target.value)}
                  id="otaLastRequest"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </div>
              <div>
                <label
                  htmlFor="deviceModel"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Device Model
                </label>
                <input
                  type="text"
                  name="deviceModel"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                  id="deviceModel"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </div>
              <div>
                <label
                  htmlFor="oldFirmWare"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  OLD Firmware (deprecated)
                </label>
                <select
                  id="oldFirmWare"
                  value={firmwareVersionOption}
                  onChange={(e) => setFirmwareVersionOption(e.target.value)}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="" disabled>Select Version</option>
                  {Object.entries(firmwareVersion).map(
                    ([category, versions]) => (
                      <optgroup key={category} label={category}>
                        {Object.entries(versions).map(([version, label]) => (
                          <option
                            key={`${category}-${version}`}
                            value={version}
                          >
                            {label}
                          </option>
                        ))}
                      </optgroup>
                    )
                  )}
                </select>
              </div>
              <div>
                <label
                  htmlFor="status"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Status
                </label>
                <select
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(Number(e.target.value))}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="1">Enabled</option>
                  <option value="2">Pending</option>
                  <option value="0">Disabled</option>
                  <option value="3">Deleted</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="deviceSimNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Device Sim Number
                </label>
                <input
                  type="text"
                  name="deviceSimNumber"
                  value={deviceSimNumber}
                  onChange={(e) => setDeviceSimNumber(e.target.value)}
                  id="deviceSimNumber"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </div>
              <div>
                <label
                  htmlFor="modemNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Modem Number
                </label>
                <input
                  type="text"
                  name="modemNumber"
                  value={modemNumber}
                  onChange={(e) => setModemNumber(e.target.value)}
                  id="modemNumber"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </div>
              <div>
                <label
                  htmlFor="countries"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sim Carrier{" "}
                </label>
                <select
                  id="countries"
                  value={simCarrier}
                  onChange={(e) => setSimCarrier(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="">Select Carrier Type</option>
                  <option value="0">Verizon</option>
                  <option value="1">T-Mobile</option>
                  <option value="2">Sprint</option>
                  <option value="3">AT & T</option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your message
              </label>
              <textarea
                id="message"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Write your notes here..."
              ></textarea>
            </div>

            <div className="flex items-center justify-end mt-2">
              <button
                className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                style={{ backgroundColor: "red" }}
                onClick={toggleCloseAddDevice}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                style={{ backgroundColor: "#009D01" }}
              >
                Add Device
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
