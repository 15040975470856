import React, { useEffect, useState, useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "../calendar/calendar.css";
import ApiLink from "../api/ApiLink";
import { useDispatch, useSelector } from "react-redux";
import { useSelectedCompany } from "../context/apiContext/SelectedCompanyContext";
import { ThemeContext } from "../context/themeContext/ThemeContext";
import FlowVisual from "./FlowVisual";
import PressureVisual from "./PressureVisual";

export default function Calendar({ device_identifier }) {
  const { selectedLocation, selectedDeviceId } = useSelectedCompany();
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const [decisionCalendardata, setDecisionCalendarData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedDate, setClickedDate] = useState(null);
  const [clickedImage, setClickedImage] = useState(null);
  const [clickedDecisionDetails, setClickedDecisionDetails] = useState(null);
  const [clickedTitle, setClickedTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [getDeviceAlertsData, setGetDeviceAlertsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [zonehasFlowSensor, setZonehasFlowSensor] = useState(false);
  const [hasPressureSensor, setHasPressureSensor] = useState(false);

  const [flowData, setFlowData] = useState(null);
  const [pressureJsonString, setPressureJsonString] = useState(null);

  const [alertModal, setDeviceModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const { textColor, backgroundColor, tableHeaderColor, tableHeaderTextColor } =
    useContext(ThemeContext);
  const [manualRuns, setManualRuns] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [zoneListData, setZoneListData] = useState({});
  const [programs, setPrograms] = useState([]);
  const [zoneCount, setZoneCount] = useState(0);
  const [customWateringResponse, setCustomWateringResponse] = useState([]);

  useEffect(() => {
    const fetchDecisionCalendar = async () => {
      if (!selectedDeviceId || !selectedDeviceId.company_location_device_id) {
        console.log("deviceID: ", selectedDeviceId);
        setDecisionCalendarData([]); // Clear data immediately
        setIsLoading(true);
        setIsLoading(false);
        return; // Exit early
      }

      setIsLoading(true);

      try {
        const response = await ApiLink.post("/api/decision-calendar", {
          email: email,
          password: password,
          company_location_device_id:
            selectedDeviceId.company_location_device_id,
        });

        if (response.status === 200 && Array.isArray(response.data.Data)) {
          // Create current date in EST
          const estCurrentDate = new Date(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          );
          estCurrentDate.setHours(0, 0, 0, 0); // Set to start of day

          const formattedEvents = response.data.Data.filter((item) => {
            // Convert item date to EST
            const itemDate = new Date(
              new Date(item.date).toLocaleString("en-US", {
                timeZone: "America/New_York",
              })
            );
            return itemDate <= estCurrentDate;
          }).map((item) => ({
            title: item.title,
            date: item.date,
            image: `${ApiLink.defaults.baseURL}/images/c2/${item.imageurl}`,
            decision_details: item.decision_details,
            is_watering_override: item.is_watering_override,
            is_reason_rain: item.is_reason_rain,
            is_custom: item.is_custom,
            is_extended_skip: item.is_extended_skip,
            rain_level: item.rain_level,
          }));

          // Sort the events by date in descending order (most recent first)
          formattedEvents.sort((a, b) => new Date(b.date) - new Date(a.date));

          setDecisionCalendarData(formattedEvents);
        } else {
          setDecisionCalendarData([]);
        }
      } catch (error) {
        console.error("Error fetching decision calendar data:", error);
        setDecisionCalendarData([]);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      }
    };

    fetchDecisionCalendar();

    return () => {
      setDecisionCalendarData([]);
    };
  }, [selectedDeviceId, email, password]);

  const handleEventClick = async (eventInfo) => {
    const clickedDate = eventInfo.event.startStr;
    setSelectedDate(clickedDate);
    setClickedDate(clickedDate);
    setClickedImage(eventInfo.event.extendedProps.image);
    setClickedDecisionDetails(eventInfo.event.extendedProps.decision_details);
    setClickedTitle(eventInfo.event.title);
    setIsModalOpen(true);
    if (!selectedDeviceId || !selectedDeviceId.company_location_device_id) {
      console.error("No device selected");
      return; // Exit early
    }

    try {
      setLoading(true);

      // First API call for alerts
      const alertsResponse = await ApiLink.post("/api/get-device-alerts", {
        email: email,
        password: password,
        company_location_device_id: selectedDeviceId.company_location_device_id,
        date_added: clickedDate,
      });
      if (alertsResponse.status === 200) {
        if (Array.isArray(alertsResponse.data.Data)) {
          setGetDeviceAlertsData(alertsResponse.data.Data);
        }
      }

      const customWateringResponse = await ApiLink.post(
        "/api/custom-watering-schedule",
        {
          email: email,
          password: password,
          company_location_device_id:
            selectedDeviceId.company_location_device_id,
          company_location_id: selectedLocation.locationId,
        }
      );

      if (customWateringResponse.status === 200) {
        setCustomWateringResponse(
          Array.isArray(customWateringResponse.data.Data)
            ? customWateringResponse.data.Data
            : []
        );
      }

      const zoneVisualResponse = await ApiLink.post("/api/zone-visual", {
        email: email,
        password: password,
        company_location_device_id: selectedDeviceId.company_location_device_id,
        date: clickedDate,
      });

      if (zoneVisualResponse.status === 200) {
        const data = zoneVisualResponse.data.Data;
        setPrograms(Object.keys(data.zoneVisual[clickedDate]?.zones || {}));
        setZoneCount(data.zoneCount);

        // Set zone data
        const clickedDateData = data.zoneVisual[clickedDate];
        if (clickedDateData) {
          const processedData = Object.entries(clickedDateData.zones).flatMap(
            ([program, zones]) =>
              zones.map((zone, index) => ({
                program,
                zoneNumber: zone.Zone_number,
                startOffset: clickedDateData.start_offset[program][index],
                zoneNumber: zone.Zone_number,
                voltDuration: zone.volt_duration,
                actual: zone.actual,
                expected: zone.expected,
                voltage: zone.voltage,
                current: zone.current,
                flow: zone.flow,
                xfmrVoltage: zone.xfmr_voltage,
              }))
          );
          setZoneData(processedData);
        } else {
          setZoneData([]);
        }

        // Set manual runs data
        if (data.manual[clickedDate]) {
          setManualRuns(data.manual[clickedDate]);

          console.log("Manual runs:", manualRuns);
        } else {
          setManualRuns([]);
        }

        setFlowData(data.flow[clickedDate]);
        setPressureJsonString(data.pressureJson);

        setHasPressureSensor(data.hasPressureSensor);
        setZonehasFlowSensor(data.hasFlowSensor);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(isModalOpen || alertModal || detailsModal);
    return () => toggleBodyScroll(false);
  }, [isModalOpen, alertModal, detailsModal]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleAlertsModal = () => {
    setDeviceModal(!alertModal);
  };

  const toggleDetailsModal = () => {
    setDetailsModal(!detailsModal);
  };

  const getCellClass = (actual, expected) => {
    if (actual === 0) {
      return "bg-[#808080] text-white"; // Gray for actual 0
    } else if (actual < 0.25 * expected || actual > expected) {
      return "bg-[#f0ad4e] text-white"; // Orange for actual < 25% of expected and actual > 0
    } else if (actual >= 0.25 * expected && actual <= expected) {
      return "bg-[#62cb31] text-white"; // Green for actual >= 25% of expected and actual <= expected
    }
    return ""; // Default class if none of the conditions match
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div role="status" className="flex justify-center items-center h-full">
          <svg
            aria-hidden="true"
            className="w-14 h-14 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading... </span>
        </div>
      </div>
    );
  }

  const PRESET_WATER_SCHEDULE = {
    fertilizer: "Fertilizer / A + B (150%)",
    sod: "Sodding / A + B + C (200%)",
    extra: "More Water / A + B (150%)",
    daily: "Daily / A (100%)",
    flower: "Flower / B (50%)",
    no_water: "Do Not Water	/ All Programs Off	",
    less: "Less Water	/ B (50%)",
    water_now: "Default / A (100%)",
    flower_alt: "Flower (Every Alternate Day) / B (50%)",
    run_ab: "Run A",
    custom_bc: "Custom BC",
  };

  return (
    <div
      className={`container w-full ${
        isModalOpen || alertModal || detailsModal ? "overflow-hidden" : ""
      }`}
    >
      {!selectedDeviceId ? (
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={decisionCalendardata}
        />
      ) : (
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={decisionCalendardata}
          eventContent={renderEventContent(handleEventClick)}
        />
      )}
      {isModalOpen && (
        <div
          id="static-modal"
          data-modal-backdrop="static"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
          style={{
            // backdropFilter: "blur(2px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-[90%] max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <div className="text-lg font-semibold text-gray-900 dark:text-white">
                  <div>
                    {selectedDeviceId.device_identifier} {clickedDate}
                  </div>
                </div>

                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="static-modal"
                  onClick={closeModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <div className="p-4 flex justify-center items-center dark:bg-gray-800">
                <img
                  src={clickedImage}
                  alt="Clicked Event"
                  style={{ maxHeight: 100 }}
                />
                <h1 className="ml-2 font-bold text-lg"> {clickedTitle}</h1>

                {loading ? (
                  <div
                    role="status"
                    className="flex justify-center items-center h-16"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="ml-6 text-sm">
                    {Array.isArray(customWateringResponse) &&
                      customWateringResponse.length > 0 &&
                      customWateringResponse
                        .filter((schedule) => {
                          const clickedDateObj = new Date(clickedDate);
                          const startDate = new Date(schedule.date_start);
                          const endDate = new Date(schedule.date_end);

                          console.log("startDate", startDate);
                          return (
                            clickedDateObj >= startDate &&
                            clickedDateObj <= endDate
                          );
                        })
                        .map((schedule) => {
                          const filteredSchedules =
                            customWateringResponse.filter((s) => {
                              const clickedDateObj = new Date(clickedDate);
                              const startDate = new Date(s.date_start);
                              const endDate = new Date(s.date_end);
                              return (
                                clickedDateObj >= startDate &&
                                clickedDateObj <= endDate
                              );
                            });

                          return (
                            filteredSchedules.length > 0 && (
                              <>
                                <h1 className="font-bold">
                                  Custom Watering Setting
                                </h1>
                                <div
                                  key={
                                    schedule.company_location_custom_program_id
                                  }
                                >
                                  <p className="flex">
                                    <strong className="text-blue-500">
                                      {PRESET_WATER_SCHEDULE[schedule.preset]}
                                    </strong>{" "}
                                    &nbsp; - &nbsp;
                                    <strong>
                                      Zones:{" "}
                                      <span className="text-blue-500">
                                        {Object.keys(
                                          JSON.parse(schedule.zones_map)
                                        ).join(", ")}
                                      </span>
                                    </strong>
                                  </p>
                                </div>
                              </>
                            )
                          );
                        })}
                  </div>
                )}
              </div>

              <div className="relative shadow-md sm:rounded-lg p-4">
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-6">
                    <caption className="p-1 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
                      <h1 className="ml-5">Zone Visual</h1>
                    </caption>

                    <tbody>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                        <th
                          scope="col"
                          className="px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <h1>{clickedDate}</h1>
                          <h1
                            className="hover:underline cursor-pointer text-blue-600"
                            onClick={toggleAlertsModal}
                          >
                            Alerts
                          </h1>
                          <h1
                            className="hover:underline cursor-pointer text-blue-600"
                            onClick={toggleDetailsModal}
                          >
                            Details
                          </h1>
                        </th>

                        {loading ? (
                          <div
                            role="status"
                            className="flex justify-center items-center h-16"
                          >
                            <svg
                              aria-hidden="true"
                              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white mb-6">
                              <thead>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                  <th className="px-2 py-1 border border-black dark:border-white">
                                    Program
                                  </th>
                                  {[...Array(zoneCount)].map((_, index) => (
                                    <th
                                      key={index}
                                      className="px-6 py-4 border border-black dark:border-white"
                                    >
                                      <div className="flex justify-center w-full">
                                        {index + 1}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {programs.map((program) => (
                                  <tr
                                    key={program}
                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                  >
                                    <td className="px-2 py-1 border border-black dark:border-white">
                                      {program}
                                    </td>
                                    {[...Array(zoneCount)].map((_, index) => {
                                      const zone = zoneData.find(
                                        (zone) =>
                                          zone.program === program &&
                                          zone.zoneNumber === index
                                      );
                                      const actual = zone?.actual || 0;
                                      const expected = zone?.expected || 0;
                                      const flow = zone?.flow || 0;

                                      return (
                                        <td
                                          key={index}
                                          className="p-1 border border-black dark:border-white"
                                        >
                                          <div className="flex flex-col items-center">
                                            <span
                                              className={`mt-0.5 px-1 py-0.20 text-[9.75px] text-center rounded inline-block ${getCellClass(
                                                actual,
                                                expected
                                              )}`}
                                            >
                                              {actual} MIN
                                            </span>
                                            {zonehasFlowSensor && (
                                              <span
                                                className={`mt-0.5 px-1 py-0.25 text-[9.75px] text-center rounded inline-block ${
                                                  flow === 0
                                                    ? "bg-[#808080]"
                                                    : "bg-[#5bc0de]"
                                                } text-white`}
                                              >
                                                {flow} GPM
                                              </span>
                                            )}
                                          </div>
                                        </td>
                                      );
                                    })}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Manual runs
                        </th>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div className="flex gap-2">
                            {manualRuns.map((run, index) => {
                              // Convert timestamp to a Date object
                              const startDate = new Date(run.timestamp * 1000); // Start time
                              const durationMinutes = run.length; // Duration in minutes

                              // Calculate the end time
                              const endDate = new Date(
                                startDate.getTime() + durationMinutes * 60000
                              ); // Add duration in milliseconds

                              // Format start and end times
                              const formattedStartTime =
                                startDate.toLocaleString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                });

                              const formattedEndTime = endDate.toLocaleString(
                                "en-US",
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              );

                              return (
                                <h1
                                  key={index}
                                  className="text-xs p-1 bg-[#62cb31] text-white font-semibold rounded-md whitespace-nowrap"
                                >
                                  {formattedStartTime} - {formattedEndTime}
                                </h1>
                              );
                            })}
                          </div>
                        </th>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Legend
                        </th>
                        <th
                          scope="row"
                          className="px-6 py-4 text-sm font-medium text-white whitespace-nowrap dark:text-white"
                        >
                          <div className="flex flex-row gap-2">
                            <div className="flex flex-row gap-1 text-xs">
                              <div
                                className="p-1.5 rounded-md flex items-center gap-1"
                                style={{ backgroundColor: "gray" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-3.5 h-3.5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>
                                <span>Sprinkling OFF</span>
                              </div>
                              <div
                                className="p-1.5 rounded-md flex items-center gap-1"
                                style={{ backgroundColor: "#057a55" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-3.5 h-3.5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m4.5 12.75 6 6 9-13.5"
                                  />
                                </svg>
                                <span>Sprinkling ON</span>
                              </div>
                              <div
                                className="p-1.5 rounded-md flex items-center gap-1"
                                style={{ backgroundColor: "orange" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-3.5 h-3.5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                                  />
                                </svg>
                                <span>Warning</span>
                              </div>
                              <div className="p-1.5 bg-red-600 rounded-md flex items-center gap-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-3.5 h-3.5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                  />
                                </svg>
                                <span>Alert</span>
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Decision Details
                        </th>
                        <th
                          scope="row"
                          className="px-6 py-4 font-normal text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <h1 className="">{clickedDecisionDetails}</h1>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <table className="alertTable w-full text-sm text-left rtl:text-right text-black dark:text-white">
                  <caption className="p-1 text-lg font-semibold text-left rtl:text-right text-gray-900 dark:text-white bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                    <h1 className="ml-5">Alerts</h1>
                  </caption>
                  <thead
                    className="text-xs text-black uppercase bg-gray-200 dark:bg-gray-700 dark:text-white"
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${tableHeaderTextColor}`,
                    }}
                  >
                    <tr
                      style={{
                        backgroundColor: `${tableHeaderColor}`,
                        color: `${tableHeaderTextColor}`,
                      }}
                      className="odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                    >
                      <th scope="col" className="px-6 py-3 border border-black">
                        Timestamp
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black">
                        Code
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black">
                        Description
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black">
                        Details
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7" className="h-16">
                          <div
                            role="status"
                            className="flex justify-center items-center h-full"
                          >
                            <svg
                              aria-hidden="true"
                              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : getDeviceAlertsData.length > 0 ? (
                      getDeviceAlertsData.map((deviceAlerts) => (
                        <tr
                          key={deviceAlerts.id}
                          className="odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white border-b dark:bg-gray-800 dark:border-gray-800 "
                        >
                          <td className="px-6 py-4 border border-black dark:border-white">
                            {deviceAlerts.alert_timestampt}
                          </td>
                          <td className="px-6 py-4 border border-black dark:border-white">
                            {deviceAlerts.alert_type}
                          </td>
                          <td className="px-6 py-4 border border-black dark:border-white">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="red"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                />
                              </svg>
                            </div>
                          </td>
                          <td className="px-6 py-4 border border-black dark:border-white">
                            {deviceAlerts.message}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>
                          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                            No Alerts Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="flex flex-col w-100">
                  {loading ? (
                    <div
                      role="status"
                      className="flex justify-center items-center h-16"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : hasPressureSensor ? (
                    <div>
                      <h1 className="text-lg font-semibold ml-2">
                        Pressure Visual
                      </h1>
                      <PressureVisual pressureJsonString={pressureJsonString} />
                    </div>
                  ) : (
                    zonehasFlowSensor && (
                      <div className="mt-8 pb-10">
                        <FlowVisual flowData={flowData} />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          {alertModal && (
            <div
              id="defaultModal"
              tabIndex="-1"
              aria-hidden="true"
              className="fixed inset-0 z-50 flex items-center justify-center p-4 font-inter"
              style={{
                // backdropFilter: "blur(2px)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="relative w-full max-w-8xl max-h-full overflow-auto">
                {/* <!-- Modal content --> */}
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                  {/* <!-- Modal header --> */}
                  <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      Feedback Alerts for {clickedDate}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                      onClick={toggleAlertsModal}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* <!-- Modal body --> */}

                  <div class="relative overflow-x-auto">
                    <table class="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                      <thead
                        class="text-xs uppercase bg-gray-50 dark:bg-gray-700 text-black dark:text-white"
                        style={{
                          backgroundColor: `${tableHeaderColor}`,
                          color: `${tableHeaderTextColor}`,
                        }}
                      >
                        <tr className="">
                          <th scope="col" class="px-6 py-3 border border-black">
                            Timestamp
                          </th>
                          <th scope="col" class="px-6 py-3 border border-black">
                            Code
                          </th>
                          <th scope="col" class="px-6 py-3 border border-black">
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getDeviceAlertsData.length > 0 ? (
                          getDeviceAlertsData.map((device) => (
                            <tr
                              key={device.id}
                              className="bg-white border-b odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 dark:bg-gray-800 dark:border-gray-700"
                            >
                              <td className="px-6 py-4 border border-black">
                                {new Date(
                                  device.alert_timestampt
                                ).toLocaleTimeString([], {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })}
                              </td>
                              <td className="px-6 py-4 border border-black">
                                {device.alert_type}
                              </td>
                              <td className="px-6 py-4 border border-black">
                                {device.message}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center py-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                No Device Alerts Available
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {detailsModal && (
            <div
              id="defaultModal"
              tabIndex="-1"
              aria-hidden="true"
              className="fixed inset-0 z-50 flex items-center justify-center p-4 font-inter"
              style={{
                // backdropFilter: "blur(2px)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="relative max-h-full overflow-auto">
                {/* <!-- Modal content --> */}
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                  {/* <!-- Modal header --> */}
                  <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      {device_identifier} {clickedDate}
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                      onClick={toggleDetailsModal}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* <!-- Modal body --> */}

                  <div className="overflow-x-auto">
                    <div>
                      <h1 className="text-lg font-semibold mb-2">Zone Runs</h1>
                      <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                          <thead
                            className="text-xs uppercase bg-gray-50 dark:bg-gray-700 text-black dark:text-white sticky top-0 z-10"
                            style={{
                              backgroundColor: `${tableHeaderColor}`,
                              color: `${tableHeaderTextColor}`,
                            }}
                          >
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Program
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Start Offset
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Zone #
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Volt Duration
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Actual
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Expected
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Volt
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Curr
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Flow
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Xfrm Volt
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {zoneData.length > 0 ? (
                              zoneData.map((zone, index) => (
                                <tr
                                  key={index}
                                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                                >
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.program}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.startOffset}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.zoneNumber + 1}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.voltDuration}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.actual}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.expected}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.voltage}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.current}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.flow}
                                  </td>
                                  <td className="px-6 py-4 border border-black dark:border-white">
                                    {zone.xfmrVoltage}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="10" className="text-center py-4">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="12"></line>
                                    <line
                                      x1="12"
                                      y1="16"
                                      x2="12.01"
                                      y2="16"
                                    ></line>
                                  </svg>
                                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                    No Zone Runs Data Available
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="pt-4 border-t-2 border-gray-200 mt-2">
                      <h1 className="text-lg font-semibold mb-2">
                        Manual Runs
                      </h1>
                      <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                          <thead
                            className="text-xs uppercase bg-gray-50 dark:bg-gray-700 text-black dark:text-white"
                            style={{
                              backgroundColor: `${tableHeaderColor}`,
                              color: `${tableHeaderTextColor}`,
                            }}
                          >
                            <tr className="">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Timestamp
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Formatted Time
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Duration (mins)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {manualRuns.length > 0 ? (
                              manualRuns.map((run, index) => {
                                // Convert timestamp to a Date object
                                const date = new Date(run.timestamp * 1000); // Convert to milliseconds

                                // Format the first and second date outputs
                                const formattedDate1 = date.toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  }
                                );

                                const formattedDate2 = date.toLocaleString(
                                  "en-US",
                                  {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  }
                                );

                                return (
                                  <tr
                                    key={index}
                                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                  >
                                    <td className="px-6 py-4 border border-black dark:border-white">
                                      {formattedDate1}
                                    </td>
                                    <td className="px-6 py-4 border border-black dark:border-white">
                                      {formattedDate2}
                                    </td>
                                    <td className="px-6 py-4 border border-black dark:border-white">
                                      {run.length}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center py-4">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="12"></line>
                                    <line
                                      x1="12"
                                      y1="16"
                                      x2="12.01"
                                      y2="16"
                                    ></line>
                                  </svg>
                                  <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                    No Manual Runs Data Available
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function renderEventContent(handleEventClick) {
  return function (eventInfo) {
    const {
      is_watering_override,
      is_reason_rain,
      is_custom,
      is_extended_skip,
    } = eventInfo.event.extendedProps;

    return (
      <div className="w-full h-full flex flex-col items-center">
        <div className="w-full text-right mb-1">{eventInfo.dayNumberText}</div>
        <div className="flex justify-end w-full mb-2 items-center">
          {is_watering_override === 1 && (
            <div title="Watering Override">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 text-red-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>
          )}
          {is_reason_rain === 1 && (
            <div title="Reason Rain">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 text-red-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z"
                />
              </svg>
            </div>
          )}
          {is_custom === 1 && (
            <div title="Custom">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 text-red-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </div>
          )}
          {is_extended_skip === 1 && (
            <div title="Extended Skip">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 text-red-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
                />
              </svg>
            </div>
          )}
        </div>
        <button
          className="clickableDate flex-grow flex items-center justify-center"
          onClick={() => handleEventClick(eventInfo)}
        >
          <img
            src={eventInfo.event.extendedProps.image}
            alt={eventInfo.event.title}
            className="max-w-full max-h-[100px] object-contain"
          />
        </button>
      </div>
    );
  };
}
