import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "./AuthContext";

const PrivateRoute = ({ component: Component, allowedUserTypes, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route {...rest}>
      {auth.isAuthenticated && allowedUserTypes.includes(auth.userType) ? (
        <Component {...rest} />
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
};

export default PrivateRoute;
