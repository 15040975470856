import React, { useState, useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { ThemeContext } from "../../components/context/themeContext/ThemeContext";
import AsideLayout from "../ADMINMENU/AsideLayout/AsideLayout";
import SeverityCharts from "../../components/charts/DashboardAlertCharts/SeverityCharts";
import BarGraph from "../../components/charts/DashboardAlertCharts/BarGraph";
import "react-datepicker/dist/react-datepicker.css";
import ApiLink from "../../components/api/ApiLink";
import { useSelector } from "react-redux";
import { useSelectedCompany } from "../../components/context/apiContext/SelectedCompanyContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function BinAlerts() {
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
    sideBarOpen,
  } = useContext(ThemeContext);
  const [selectStartDate, setSelectStartDate] = useState(false);

  const { updateSerialNumber } = useSelectedCompany();

  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  });

  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const [getBinAlertsData, setGetBinAlertsData] = useState([]);

  const [selectedSevLevel, setSelectedSevLevel] = useState("");
  const [selectedAlertType, setSelectedAlertType] = useState("");
  const [alertCounts, setAlertCounts] = useState({});
  const [severityCounts, setSeverityCounts] = useState({
    high: 0,
    info: 0,
    low: 0,
    unknown: 0,
  });

  const alertTypeMap = {
    V: "Very High Flow [high]",
    P: "Pump Disabled Irrigation [high]",
    D: "Disabled Irrigation [high]",
    H: "High Flow [low]",
    L: "Low Flow [low]",
    F: "Zone Overcurrent",
    M: "Manual Run Detected",
    E: "Manual Run Ended",
    U: "Unscheduled Flow Detected",
    CA: "Server Custom Program Added",
    CD: "Server Custom Program Deleted",
    SD: "Server Clock Skew or Progmod",
    NF: "Server No Feedback Reported",
    NFZ: "Server No Feedback on Some Zones",
    IF: "Server Inconsistent Feedback",
    MM: "Server Multiple Manual Events",
    UW: "Server Unauthorized Watering",
    OFF: "Server Offline Devices",
    NVZ: "No voltage on some zone",
  };
  const handleStartDateChange = (date) => {
    if (date) {
      const startDate = new Date(date);
      setSelectedDate((prev) => ({
        ...prev,
        startDate,
      }));
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endDate = new Date(date);
      setSelectedDate((prev) => ({
        ...prev,
        endDate,
      }));
    }
  };

  const toggleDropdown = (dropdownName) => {
    if (dropdownName === "selectStartDate") {
      setSelectStartDate(!selectStartDate);
    }
  };

  const formatDate = (date) => {
    return date ? date.toISOString().split("T")[0] : "";
  };

  const fetchGetBinAlerts = async () => {
    setLoading(true);
    try {
      const requestBody = {
        email: email,
        password: password,
        from_date: formatDate(selectedDate.startDate),
        to_date: formatDate(selectedDate.endDate),
      };

      if (selectedAlertType) {
        requestBody.alert_type = selectedAlertType;
      }

      if (selectedSevLevel) {
        requestBody.severity = selectedSevLevel;
      }

      const response = await ApiLink.post("/api/get-bin-alerts", requestBody);

      if (response.status === 200) {
        if (
          Array.isArray(response.data.Data) &&
          response.data.Data.length > 0
        ) {
          setGetBinAlertsData(response.data.Data);

          // Initialize counts for all alert types
          const initialCounts = Object.values(alertTypeMap).reduce(
            (acc, alertType) => {
              acc[alertType] = 0;
              return acc;
            },
            {}
          );

          // Calculate counts for each alert type
          const counts = response.data.Data.reduce((acc, alert) => {
            const alertTypeFull =
              alertTypeMap[alert.alert_type] || alert.alert_type;
            acc[alertTypeFull] = (acc[alertTypeFull] || 0) + 1;
            return acc;
          }, initialCounts);

          setAlertCounts(counts);

          // Calculate severity counts
          const severityCounts = response.data.Data.reduce(
            (acc, alert) => {
              const severity = alert.severity.toLowerCase();
              acc[severity] = (acc[severity] || 0) + 1;
              return acc;
            },
            { high: 0, info: 0, low: 0, unknown: 0 }
          );

          setSeverityCounts(severityCounts);

          console.log("Bin Alerts: ", response.data);
          console.log("Alert Counts: ", counts);
          console.log("Severity Counts: ", severityCounts);
        } else {
          setGetBinAlertsData([]);
          setAlertCounts(
            Object.values(alertTypeMap).reduce((acc, alertType) => {
              acc[alertType] = 0;
              return acc;
            }, {})
          );
          setSeverityCounts({ high: 0, info: 0, low: 0, unknown: 0 });
          console.log("No Bin Alerts Found");
        }
      }
    } catch (error) {
      console.log("Error:", error);
      setGetBinAlertsData([]);
      setAlertCounts(
        Object.values(alertTypeMap).reduce((acc, alertType) => {
          acc[alertType] = 0;
          return acc;
        }, {})
      );
      setSeverityCounts({ high: 0, info: 0, low: 0, unknown: 0 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetBinAlerts();
  }, []);

  const handleSearchClick = () => {
    fetchGetBinAlerts();
  };

  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };
  function groupByCompanyLocationDeviceId(data) {
    return data.reduce((acc, curr) => {
      (acc[curr.company_location_device_id] =
        acc[curr.company_location_device_id] || []).push(curr);
      return acc;
    }, {});
  }

  const handleSerialNumberClick = (deviceId) => {
    updateSerialNumber({ deviceId });
  };

  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div>
            <div className="p-4 shadow-md bg-white rounded-md dark:bg-gray-800">
              <div className="flex mb-4 text-lg rounded-xl font-semibold text-left rtl:text-right bg-white dark:text-white dark:bg-gray-800">
                <span className="text-gray-900 font-bold text-xl dark:text-white">
                  Bin Alerts
                </span>
              </div>
              <div className="graphContainer flex flex-col md:flex-row">
                <div className="bargraph w-full md:w-3/4">
                  {loading ? (
                    <div colSpan="7" className="h-64">
                      <div
                        role="status"
                        className="flex justify-center items-center h-full"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <BarGraph alertCounts={alertCounts} />
                  )}
                </div>
                <div className=" piechart w-full md:w-72">
                  {loading ? (
                    <div colSpan="7" className="h-64">
                      <div
                        role="status"
                        className="flex justify-center items-center h-full"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <SeverityCharts severityCounts={severityCounts} />
                  )}
                </div>
              </div>

              <div className="flex gap-3">
                <div>
                  <label
                    htmlFor="alertType"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Include Alert Type
                  </label>
                  <select
                    id="alertType"
                    value={selectedAlertType}
                    onChange={(e) => setSelectedAlertType(e.target.value)}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-${color}-500 focus:border-${color}-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                  >
                    <option defaultValue value="">
                      Select Alert Type
                    </option>
                    <option value="V">Very High Flow [high]</option>
                    <option value="P">Pump Disabled Irrigation [high]</option>
                    <option value="D">Disabled Irrigation [high]</option>
                    <option value="H">High Flow [low]</option>
                    <option value="L">Low Flow [low]</option>
                    <option value="F">Zone Overcurrent</option>
                    <option value="M">Manual Run Detected</option>
                    <option value="E">Manual Run Ended</option>
                    <option value="U">Unscheduled Flow Detected</option>
                    <option value="CA">Server Custom Program Added</option>
                    <option value="CD">Server Custom Program Deleted</option>
                    <option value="SD">Server Clock Skew or Progmod</option>
                    <option value="NF">Server No Feedback Reported</option>
                    <option value="NFZ">
                      Server No Feedback on Some Zones
                    </option>

                    <option value="IF">Server Inconsistent Feedback</option>
                    <option value="MM">Server Multiple Manual Events</option>
                    <option value="UW">Server Unauthorized Watering</option>
                    <option value="OFF">Server Offline Devices</option>
                    <option value="NVZ">No voltage on some zone</option>
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="sevLevel"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Sev Level
                  </label>
                  <select
                    id="sevLevel"
                    value={selectedSevLevel}
                    onChange={(e) => setSelectedSevLevel(e.target.value)}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-${color}-500 focus:border-${color}-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                  >
                    <option defaultValue value="">
                      Choose Alert Sev Level
                    </option>
                    <option value="high">High</option>
                    <option value="low">Low</option>
                    <option value="info">Info</option>
                    <option value="unknown">Unknown</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="start-date-input"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Date
                  </label>
                  <button
                    id="dropdownStartDateButton"
                    className={`flex items-center border-2 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-${color}-500 focus:border-${color}-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                    type="button"
                    onClick={() => toggleDropdown("selectStartDate")}
                  >
                    <h1 onChange={handleStartDateChange}>
                      {formatDate(selectedDate.startDate) ||
                        "Select Start Date"}{" "}
                      - {formatDate(selectedDate.endDate) || ""}
                    </h1>
                  </button>

                  <div
                    id="dropdownStartDate"
                    className={`z-10 ${
                      selectStartDate
                        ? "block absolute animate__animated "
                        : "hidden"
                    } flex items-center bg-white divide-y divide-gray-100 rounded-md shadow dark:bg-gray-700 mt-1`}
                  >
                    <div className="relative">
                      <DatePicker
                        selected={selectedDate.startDate}
                        onChange={handleStartDateChange}
                        dateFormat="yyyy-MM-dd"
                        className="cursor-pointer items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderText="Select start date"
                      />
                    </div>
                    <div className="px-5 text-black dark:text-white">
                      <h1> to </h1>
                    </div>
                    <div className="relative">
                      <DatePicker
                        selected={selectedDate.endDate}
                        onChange={handleEndDateChange}
                        dateFormat="yyyy-MM-dd"
                        className="cursor-pointer items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderText="Select end date"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    className={`text-white mt-7 font-medium rounded-md text-sm px-5 py-2.5 text-center me-2 mb-2 bg-${color}-600 hover:bg-${color}-800`}
                    style={{
                      backgroundColor: `${backgroundColor}`,
                      color: `${textColor}`,
                    }}
                    onClick={handleSearchClick}
                    disabled={loading && true}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div>
                <div className="relative overflow-x-auto sm:rounded-md mb-4 mt-4">
                  <div className="py-2 text-lg text-black dark:text-white">
                    <h1 className="flex">
                      Total Alerts:{" "}
                      {loading ? <h1>Loading...</h1> : getBinAlertsData.length}
                    </h1>
                  </div>
                  {loading ? (
                    <table className="w-full">
                      <tbody>
                        <tr>
                          <td colSpan="7" className="h-64">
                            <div
                              role="status"
                              className="flex justify-center items-center h-full"
                            >
                              <svg
                                aria-hidden="true"
                                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : getBinAlertsData.length > 0 ? (
                    Object.entries(
                      groupByCompanyLocationDeviceId(getBinAlertsData)
                    ).map(([id, alerts]) => (
                      <table
                        key={id}
                        className="border animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 cursor-pointer mb-8"
                      >
                        <caption
                          className={`border border-black p-2 text-lg font-semibold text-left rtl:text-right text-${color}-800 bg-${color}-200 dark:bg-${color}-200`}
                          style={{
                            backgroundColor: `${tableHeaderColor}`,
                            color: `${tableHeaderTextColor}`,
                          }}
                        >
                          <h1 className="ml-4">
                            {
                              new Date(alerts[0].alert_timestampt)
                                .toISOString()
                                .split("T")[0]
                            }{" "}
                            - {alerts[0].company_name}
                          </h1>
                        </caption>
                        <tbody className="text-sm text-black dark:text-white font-inter leading-4">
                          {alerts.map((binAlert) => (
                            <tr
                              key={binAlert.id}
                              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                              <td className="border border-black dark:border-white px-6 py-4">
                                {binAlert.location_name}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {/* <Link to={`/devices/${binAlert.device_id}`}> */}
                                <h1>{binAlert.serial_number}</h1>
                                {/* </Link> */}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {binAlert.device_identifier}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {binAlert.alert_timestampt}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {binAlert.severity}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {binAlert.source}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {alertTypeMap[binAlert.alert_type]}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {binAlert.message}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ))
                  ) : (
                    <table className="w-full">
                      <tbody>
                        <tr>
                          <td colSpan="7" className="text-center py-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="12"></line>
                              <line x1="12" y1="16" x2="12.01" y2="16"></line>
                            </svg>
                            <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                              No bin alerts found
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsideLayout>
  );
}
