import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { enUS } from "date-fns/locale";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const PressureVisual = ({ pressureJsonString }) => {
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  useEffect(() => {
    if (pressureJsonString) {
      try {
        const pressureData = JSON.parse(pressureJsonString);

        const datasets = [
          {
            label: "Zone0",
            data:
              pressureData.feedback[0]?.map((item) => ({
                x: new Date(item[0]),
                y: item[1],
              })) || [],
            borderColor: "rgba(0, 128, 0, 1)",
            backgroundColor: "rgba(0, 128, 0, 0.3)",
            borderWidth: 2,
            fill: false,
            pointRadius: 2,
          },
          {
            label: "High Threshold",
            data:
              pressureData.high?.map((item) => ({
                x: new Date(item[0]),
                y: item[1],
              })) || [],
            borderColor: "rgba(255, 0, 0, 1)",
            backgroundColor: "rgba(255, 0, 0, 0.3)",
            borderWidth: 2,
            fill: false,
            pointRadius: 2,
          },
          {
            label: "Low Threshold",
            data:
              pressureData.low?.map((item) => ({
                x: new Date(item[0]),
                y: item[1],
              })) || [],
            borderColor: "rgba(255, 165, 0, 1)",
            backgroundColor: "rgba(255, 165, 0, 0.3)",
            borderWidth: 2,
            fill: false,
            pointRadius: 2,
          },
        ];

        setChartData({ datasets });
      } catch (error) {
        console.error("Invalid JSON string:", error);
      }
    }
  }, [pressureJsonString]);

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          displayFormats: {
            hour: "HH:mm",
          },
        },
        title: {
          display: true,
          text: "Time",
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: "Values",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Pressure Visual",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(2);
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <>
      <Line options={options} data={chartData} />
    </>
  );
};

export default PressureVisual;
