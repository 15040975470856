import React, { createContext, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  setSelectedLocation,
  setSelectedCompanyName,
  setLocationsViewUsers,
  setSelectedSerialNumber,
  setSelectedDeviceId,
  setCompanyData,
} from "../../../store/selectedCompanySlice";

const SelectedCompanyContext = createContext();

export const SelectedCompanyProvider = ({ children }) => {
  const dispatch = useDispatch();

  const selectedCompanyName = useSelector(
    (state) => state.selectedCompany.selectedCompanyName
  );
  const selectedLocation = useSelector(
    (state) => state.selectedCompany.selectedLocation
  );
  const selectedSerialNumber = useSelector(
    (state) => state.selectedCompany.selectedSerialNumber
  );
  const selectedDeviceId = useSelector(
    (state) => state.selectedCompany.selectedDeviceId
  );
  const companyData = useSelector((state) => state.selectedCompany.companyData);

  const updateCompanyData = (companyData) => {
    dispatch(setCompanyData(companyData));
  };

  const updateSelectedDeviceId = (locationdeviceId) => {
    dispatch(setSelectedDeviceId(locationdeviceId));
  };

  const updateSelectedCompanyName = (companyName) => {
    dispatch(setSelectedCompanyName(companyName));
  };

  const updateSelectedLocation = (location) => {
    dispatch(setSelectedLocation(location));
  };

  const updateSerialNumber = (deviceId) => {
    dispatch(setSelectedSerialNumber(deviceId));
  };

  const [locationsViewUsers, setLocationsViewUsers] = useState([]);

  return (
    <SelectedCompanyContext.Provider
      value={{
        selectedLocation,
        setSelectedLocation,
        selectedCompanyName,
        locationsViewUsers,
        setLocationsViewUsers,
        updateSelectedCompanyName,
        updateSelectedLocation,
        selectedSerialNumber,
        updateSerialNumber,
        selectedDeviceId,
        updateSelectedDeviceId,
        companyData,
        updateCompanyData,
      }}
    >
      {children}
    </SelectedCompanyContext.Provider>
  );
};

export const useSelectedCompany = () => useContext(SelectedCompanyContext);
