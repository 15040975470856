import React, { useContext, useEffect, useState } from "react";
import Aside from "../../components/Aside";
import AsideLayout from "./AsideLayout/AsideLayout";
import { ThemeContext } from "../../components/context/themeContext/ThemeContext";
import ApiLink from "../../components/api/ApiLink";

import { useSelector } from "react-redux";
export default function Protocol() {
  const { sideBarOpen } = useContext(ThemeContext);

  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const [editProtocol, setEditProtocol] = useState(false);

  const [protocolData, setProtocolData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  // const is_read_only = useSelector((state) => state.auth.is_read_only);
  const is_read_only = useState(0);

  const itemsPerPage = 20;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = protocolData.slice(indexOfFirstItem, indexOfLastItem);

  const [loading, setLoading] = useState(true);

  const [protocolDetails, setProtocolDetails] = useState({
    name: null,
    notes: null,
    dataAdded: null,
    is_default: null,
    status: null,
  });

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const totalPages = Math.ceil(protocolData.length / itemsPerPage);

  useEffect(() => {
    const fetchProtocolData = async () => {
      try {
        const response = await ApiLink.post("/api/firmware-protocol", {
          email: email,
          password: password,
        });

        if (response.status === 200) {
          if (Array.isArray(response.data.Data)) {
            setProtocolData(response.data.Data);
          }
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchProtocolData();
  }, []);

  const toggleEditProtocol = (names, notes, dataAdded, is_default, status) => {
    setEditProtocol(!editProtocol);
    setProtocolDetails({
      name: names,
      notes: notes,
      dataAdded: dataAdded,
      is_default: is_default,
      status: status,
    });
  };

  const handleEditProtocolSubmit = () => {};

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensures two-digit month
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensures two-digit minutes
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert to 12-hour format

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}${ampm}`;
    return formattedDate;
  }
  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="">
            <div className="p-4 shadow-md bg-white rounded-lg dark:bg-gray-800">
              <div className="flex flex-col my-2">
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id="file_input"
                  type="file"
                />
              </div>{" "}
              <h1 className="p-3 text-xl font-bold text-left rtl:text-right text-black dark:text-white">
                Our products
              </h1>
              <div className="relative overflow-x-auto">
                <table className="border animate__animated animate__fadeIn w-full text-sm cursor-pointer text-left rtl:text-right text-gray-500 dark:text-gray-400 font-inter leading-4">
                  <thead
                    className="border border-black text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400 font-inter leading-4"
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${tableHeaderTextColor}`,
                    }}
                  >
                    <tr className="">
                      <th scope="col" className="px-6 py-3 border border-black">
                        <div>
                          <p>Name</p>
                          <p>MD5</p>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black">
                        Notes
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black">
                        Date Added
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black">
                        Default
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm font-inter leading-4">
                    {loading ? (
                      <tr>
                        <td colSpan="7" className="h-64">
                          <div
                            role="status"
                            className="flex justify-center items-center h-full"
                          >
                            <svg
                              aria-hidden="true"
                              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      currentItems.map((protocol, index) => (
                        <tr
                          key={index}
                          className={`${
                            protocol.is_default === 1
                              ? "bg-green-300 text-black hover:bg-green-300 dark:bg-green-500 dark:text-black dark:hover:bg-green-300"
                              : "bg-white"
                          }odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700`}
                        >
                          <th
                            scope="row"
                            className="border border-black dark:border-gray-300 px-6 py-4 font-medium whitespace-nowrap"
                          >
                            <p>{protocol.name}</p>
                            <p>{protocol.payload_md5}</p>
                          </th>
                          <td className="border border-black dark:border-gray-300 px-6 py-4">
                            {protocol.notes || "No notes available"}
                          </td>
                          <td className="border border-black dark:border-gray-300 px-6 py-4">
                            {formatDate(protocol.date_added)}
                          </td>
                          <td className="border border-black dark:border-gray-300 px-6 py-4">
                            {protocol.is_default === 1 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                                />
                              </svg>
                            )}
                          </td>
                          <td className="border border-black dark:border-gray-300 px-6 py-4">
                            <div className="flex items-center">
                              {is_read_only !== 1 && (
                                <button
                                  className="edit p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                                  onClick={() =>
                                    toggleEditProtocol(
                                      protocol.name,
                                      protocol.notes,
                                      protocol.date_added,
                                      protocol.is_default,
                                      protocol.status
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="blue"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                </button>
                              )}
                              {is_read_only !== 1 && (
                                <button className="delete p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="red"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination flex flex-row mt-2 mb-2 justify-end">
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className="flex items-center justify-center px-2 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Previous
                </button>

                <div className="flex">
                  {[...Array(totalPages).keys()].map((number) => (
                    <button
                      key={number + 1}
                      onClick={() => goToPage(number + 1)}
                      className={`flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white 
        `}
                      style={
                        currentPage === number + 1
                          ? {
                              backgroundColor: backgroundColor,
                              color: textColor,
                            }
                          : {}
                      }
                    >
                      {number + 1}
                    </button>
                  ))}
                </div>

                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Next
                </button>
                <div className="spacer p-5"></div>
              </div>
            </div>
          </div>
        </div>
        {editProtocol && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="flex animate__animated animate__fadeIn overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
            style={{
              // backdropFilter: "blur(5px)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Edit Protocol Firmware
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={toggleEditProtocol}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <form action={handleEditProtocolSubmit}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-1">
                    <div>
                      <label
                        for="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={protocolDetails.name}
                        onChange={(e) => setProtocolDetails(e.target.value)}
                        id="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        for="isDefault"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Is Default
                      </label>
                      <select
                        id="isDefault"
                        value={protocolDetails.is_default}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option
                          value="1"
                          selected={protocolDetails.is_default === 1}
                        >
                          Yes
                        </option>
                        <option
                          value="0"
                          selected={protocolDetails.is_default === 0}
                        >
                          No
                        </option>
                      </select>
                    </div>
                    <div>
                      <label
                        for="status"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Status
                      </label>
                      <select
                        id="isDefault"
                        value={protocolDetails.status}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option
                          value="1"
                          selected={protocolDetails.status === 1}
                        >
                          Enabled
                        </option>
                        <option
                          value="0"
                          selected={protocolDetails.status === 0}
                        >
                          Disabled
                        </option>
                        <option
                          value="2"
                          selected={protocolDetails.status === 2}
                        >
                          Pending
                        </option>
                        <option
                          value="3"
                          selected={protocolDetails.status === 3}
                        >
                          Deleted
                        </option>
                      </select>
                    </div>
                    <div>
                      <label
                        for="message"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Note
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        value={protocolDetails.notes}
                        onChange={(e) => setProtocolDetails(e.target.value)}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Your message here"
                      ></textarea>
                    </div>
                  </div>

                  <div className="flex items-center justify-end">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={toggleEditProtocol}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                      style={{ backgroundColor: "#009D01" }}
                    >
                      Save Flow Sensor
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </AsideLayout>
  );
}
