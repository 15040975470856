import React, { useState, useContext } from "react";
import { Datepicker } from "flowbite-react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { Link } from "react-router-dom";
import AsideLayout from "../../ADMINMENU/AsideLayout/AsideLayout";

const SFWMD_Rainfall_Data = () => {
  const [selected1stDate, setSelected1stDate] = useState(null);
  const [selected2ndDate, setSelected2ndDate] = useState(null);
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
    sideBarOpen,
  } = useContext(ThemeContext);

  const handleFirstDateChange = (date) => {
    setSelected1stDate(date);
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setSelected1stDate(formattedDate);
  };
  const handleEndDateChange = (date) => {
    setSelected2ndDate(date);
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setSelected2ndDate(formattedDate);
  };

  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="animate__animated animate__fadeIn p-4 rounded-lg shadow-md bg-white dark:bg-gray-800 ">
            <nav
              className="flex px-5 py-3 text-gray-700 border mb-4 justify-center border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
              aria-label="Breadcrumb"
            >
              <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li className="inline-flex items-center">
                  <h1 className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                    <svg
                      className="w-3 h-3 me-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                    </svg>
                    <Link to="/accounts">Home</Link>
                  </h1>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <h1 className="ms-1 text-sm cursor-pointer font-medium text-black hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
                      <Link to="/reportIndex">Report Index</Link>
                    </h1>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg
                      className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <span className="ms-1 text-sm font-medium text-black md:ms-2 dark:text-white">
                      SFWMD Rainfall Data
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <div className="text-black dark:text-white">
              <div date-rangepicker className="flex items-center gap-5">
                <div className="relative text-sm font-medium">
                  <label>Start Date:</label>
                  <Datepicker
                    selected={selected1stDate}
                    onChange={(date) => handleFirstDateChange(date)}
                    dateFormat="yyyy-MM-dd"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderText="Select date start"
                  />
                </div>
                <div className="">
                  <label
                    for="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>

                    <div className="relative">
                      <label
                        htmlFor="small-input"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        <span className="text-black dark:text-white">
                          Serial Number
                        </span>
                      </label>
                      <div className="relative w-full">
                        <input
                          type="text"
                          id="table-search"
                          className={`pt-2 text-sm mb-4 mr-3 text-gray-900 border border-gray-300 rounded-md min-w-0 bg-gray-50 focus:ring-${color}-500 focus:border-${color}-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                          placeholder="Search"
                          required
                        />
                        <button
                          type="submit"
                          className={`absolute top-0 end-0 p-2.5 text-sm font-medium text-${color}-800 bg-${color}-200 rounded-e-lg border border-${color}-700 focus:ring-4 focus:outline-none focus:ring-${color}-300 dark:focus:ring-${color}-800`}
                          style={{
                            backgroundColor: `${backgroundColor}`,
                            color: `${textColor}`,
                          }}
                        >
                          <svg
                            className="w-4 h-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                          </svg>
                          <span className="sr-only">Search</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative overflow-x-auto shadow-md mt-5">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead
                    className={`text-sm text-${color}-800 uppercase bg-${color}-200 font-inter leading-4`}
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${tableHeaderTextColor}`,
                    }}
                  >
                    <tr>
                      <th scope="col" className="border border-black px-6 py-3">
                        ID
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Date
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Data
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm text-black dark:text-white font-inter leading-4">
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        25161
                      </td>

                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        06/29/2024 9:00 pm{" "}
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        Laptop
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsideLayout>
  );
};

export default SFWMD_Rainfall_Data;
