import React, { useState, useEffect } from "react";
import ApiLink from "../../../components/api/ApiLink";
import { useSelector } from "react-redux";

const LocationRenameZones = ({
  setIsModeOpen,
  isModalOpen,
  zoneLoading,
  closeModal,
  companyLocationDeviceData,
  renameLDID,
  setRenameLDID,
  setCompanyLocationDeviceData,
  zoneListData,
  setZoneListData,
  fetchCompanyLocationDevice,
}) => {
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const [uploadedImages, setUploadedImages] = useState(null);
  const [viewImage, setViewImage] = useState(null);
  const [zoneLabels, setZoneLabels] = useState({});
  const [newImages, setNewImages] = useState({});
  const Swal = require("sweetalert2");

  useEffect(() => {
    const fetchUploadImage = async () => {
      try {
        const response = await ApiLink.post("/api/get-upload-image", {
          email: email,
          password: password,
          company_location_device_id: renameLDID,
        });

        if (response.status === 200 && response.data.Data) {
          console.log("Upload image:", response.data.Data);
          setUploadedImages(response.data.Data.image_data);
        }
      } catch (error) {
        console.error("Error fetching upload image:", error);
      }
    };
    fetchUploadImage();
  }, [email, password, renameLDID]);

  const handleFileUpload = (zoneNumber, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setNewImages((prevImages) => ({
          ...prevImages,
          [zoneNumber]: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const getZoneLabel = (zoneNumber) => {
    if (
      !companyLocationDeviceData ||
      !Array.isArray(companyLocationDeviceData)
    ) {
      return "";
    }
    const device = companyLocationDeviceData.find(
      (device) => device.company_location_device_id === renameLDID
    );
    if (!device || !device.zone_labels) {
      return "";
    }
    try {
      const labels = JSON.parse(device.zone_labels);
      return labels[`zone${zoneNumber}`] || "";
    } catch (error) {
      console.error("Error parsing zone labels:", error);
      return "";
    }
  };

  const handleZoneLabelChange = (zoneNumber, newLabel) => {
    setZoneLabels((prevLabels) => ({
      ...prevLabels,
      [`zone${zoneNumber}`]: newLabel,
    }));
  };

  const updateAllZoneLabels = () => {
    const device = companyLocationDeviceData.find(
      (device) => device.company_location_device_id === renameLDID
    );
    if (!device) return;

    let currentZoneLabels;
    try {
      currentZoneLabels = JSON.parse(device.zone_labels || "{}");
    } catch (error) {
      console.error("Error parsing zone labels:", error);
      currentZoneLabels = {};
    }

    const updatedZoneLabels = { ...currentZoneLabels, ...zoneLabels };
    const updatedZoneLabelsString = JSON.stringify(updatedZoneLabels);

    console.log("Updated zone_labels:", updatedZoneLabelsString);

    // Update the companyLocationDeviceData with the new zone labels
    const updatedDevices = companyLocationDeviceData.map((device) =>
      device.company_location_device_id === renameLDID
        ? { ...device, zone_labels: updatedZoneLabelsString }
        : device
    );

    setCompanyLocationDeviceData(updatedDevices);
  };

  const handleConfirmAll = () => {
    const updatedZoneLabels = {};
    for (let i = 1; i <= 42; i++) {
      updatedZoneLabels[`zone${i}`] =
        i <= numberOfZones
          ? zoneLabels[`zone${i}`] || getZoneLabel(i) || null
          : null;
    }

    const updatedZoneLabelsString = JSON.stringify(updatedZoneLabels);
    console.log("Updated zone_labels:", updatedZoneLabelsString);

    console.log("Submitting zone data:");
    Swal.fire({
      title: "Do you want to update this zone labels?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/update-zone-labels", {
            company_location_device_id: renameLDID,
            email: email,
            password: password,
            zone_labels: updatedZoneLabelsString,
          });

          if (response.status === 200) {
            Swal.fire({
              title: `Zone labels updated successfully`,
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            closeModal();
            fetchCompanyLocationDevice();
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          Swal.fire({
            title: `There was an error uploading the image`,
            text: error.response?.data?.message || "Unknown error occurred",
            icon: "error",
          });
        }
      }
    });

    // Update the companyLocationDeviceData with the new zone labels
    // const updatedDevices = companyLocationDeviceData.map((device) =>
    //   device.company_location_device_id === renameLDID
    //     ? { ...device, zone_labels: updatedZoneLabelsString }
    //     : device
    // );

    // setCompanyLocationDeviceData(updatedDevices);
  };

  const handleUploadImage = (zoneNumber, file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      Swal.fire({
        title: "Invalid file type",
        text: "Please select a JPEG, PNG, JPG, or GIF image.",
        icon: "error",
      });
      return;
    }

    Swal.fire({
      title: "Do you want to add this image?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData();
          formData.append("company_location_device_id", renameLDID);
          formData.append("zone_number", zoneNumber);
          formData.append("image", file);

          const response = await ApiLink.post("/api/upload-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log("Image upload successful:", response.data);
          Swal.fire({
            title: `Image upload successful`,
            icon: "success",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          closeModal();
          fetchCompanyLocationDevice();
        } catch (error) {
          console.error("Error uploading image:", error);
          Swal.fire({
            title: `There was an error uploading the image`,
            text: error.response?.data?.message || "Unknown error occurred",
            icon: "error",
          });
        }
      }
    });
  };

  const numberOfZones = zoneListData.Zones.A ? zoneListData.Zones.A.length : 0;

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      {zoneLoading ? (
        <div colSpan="7" className="h-64 w-64">
          <div
            role="status"
            className="flex justify-center items-center h-full"
          >
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="relative p-4 max-w-7xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Rename Zones
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="static-modal"
                onClick={closeModal}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <form action="#">
              <div className="p-4 grid gap-4 mb-4 sm:grid-cols-3 md:grid-cols-5">
                {[...Array(numberOfZones)].map((_, index) => {
                  const zoneNumber = index + 1;
                  const zoneLabel = getZoneLabel(zoneNumber);
                  const uploadedImage = uploadedImages?.find(
                    (img) => img.zone_number === zoneNumber
                  );

                  return (
                    <div
                      key={zoneNumber}
                      className="flex flex-col items-center justify-center w-full"
                    >
                      <div className="zoneContainer">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Zone {zoneNumber}
                        </label>
                        <input
                          type="text"
                          id={`zone${zoneNumber}`}
                          value={
                            zoneLabels[`zone${zoneNumber}`] ||
                            getZoneLabel(zoneNumber)
                          }
                          onChange={(e) =>
                            handleZoneLabelChange(zoneNumber, e.target.value)
                          }
                          className="bg-gray-50 border mb-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          placeholder={`Enter zone ${zoneNumber} label`}
                        />
                      </div>
                      <div className="relative group">
                        {newImages[zoneNumber] ||
                        (uploadedImage && uploadedImage.image_url) ? (
                          <>
                            <img
                              src={
                                newImages[zoneNumber] || uploadedImage.image_url
                              }
                              alt={`Zone ${zoneNumber}`}
                              className="w-full h-64 object-cover rounded-lg"
                            />
                            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setViewImage(
                                    newImages[zoneNumber] ||
                                      uploadedImage.image_url
                                  );
                                }}
                                className="bg-blue-500 text-white px-3 py-1 rounded mr-2 hover:bg-blue-600"
                              >
                                View Image
                              </button>
                              <label className="bg-purple-500 text-white px-3 py-1 rounded cursor-pointer hover:bg-purple-600">
                                Update Image
                                <input
                                  type="file"
                                  className="hidden"
                                  onChange={(e) =>
                                    handleUploadImage(
                                      zoneNumber,
                                      e.target.files[0]
                                    )
                                  }
                                  accept="image/jpeg,image/png,image/jpg,image/gif"
                                />
                              </label>
                            </div>
                          </>
                        ) : (
                          <label
                            htmlFor={`dropzone-file-${zoneNumber}`}
                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                          >
                            <div className="flex p-8 flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                SVG, PNG, JPG or GIF
                              </p>
                            </div>
                            <input
                              id={`dropzone-file-${zoneNumber}`}
                              type="file"
                              className="hidden"
                              onChange={(e) => handleFileUpload(zoneNumber, e)}
                              accept="image/*"
                            />
                          </label>
                        )}
                      </div>
                    </div>
                  );
                })}
                {viewImage && (
                  <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                    onClick={() => setViewImage(null)}
                  >
                    <img
                      src={viewImage}
                      alt="Enlarged view"
                      className="max-h-screen max-w-screen object-contain"
                    />
                  </div>
                )}
              </div>

              <div className="flex items-center justify-end pb-4">
                <button
                  className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                  style={{ backgroundColor: "red" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                  style={{ backgroundColor: "#62CB31" }}
                  onClick={handleConfirmAll}
                >
                  Confirm Zone Labels
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationRenameZones;
