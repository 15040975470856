import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth } from "./AuthContext";
import ApiLink from "../../api/ApiLink";

const AuthRedirect = () => {
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const history = useHistory();
  const { authUser, userType } = useAuth();
  const auth = useSelector((state) => state.auth);

  const fetchCompanyInfo = async (email, password) => {
    try {
      const response = await ApiLink.post("/api/company-info", {
        email,
        password,
      });
      return response;
    } catch (error) {
      console.error("Error fetching company info:", error);
      throw error;
    }
  };

  useEffect(() => {
    const redirectUser = async () => {
      if (auth.isAuthenticated) {
        switch (auth.userType) {
          case 1:
            history.push("/accounts");
            break;
          case 2:
            try {
              const companyNameResponse = await fetchCompanyInfo(
                email,
                password
              );
              const companyname = companyNameResponse.data.Data.Company.company_id;
              history.push(`/accounts/${companyname}`);
            } catch (error) {
              console.error("Error fetching company info:", error);
            }
            break;
          case 4:
            const companyNameResponse = await fetchCompanyInfo(email, password);
            const companyname = companyNameResponse.data.Data.Company.company_id;
            history.push(`/location/${companyname}`);
            break;
          default:
            history.push("/login");
        }
      } else {
        history.push("/login");
      }
    };

    redirectUser();
  }, [
    auth.isAuthenticated,
    auth.userType,
    auth.companyname,
    history,
    email,
    password,
  ]);

  return null;
};

export default AuthRedirect;
