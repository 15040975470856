import React, { useState } from "react";
import ApiLink from "../components/api/ApiLink";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2"; // Make sure to import Swal correctly

function ResetPassword() {
  const history = useHistory();
  const { userid, verification_code } = useParams(); // Get userid and verification_code from URL
  const email = useSelector((state) => state.auth.email);
  const passworduser = useSelector((state) => state.auth.password);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();
    console.log("Userid:", userid);
    console.log("Verification code:", verification_code);

    if (password !== confirmPassword) {
      Swal.fire({
        title: "Passwords do not match!",
        text: "Please ensure both passwords are the same.",
        icon: "error",
        toast: true,
        timer: 4000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await ApiLink.post("/api/reset-pwd", {
        email: email,
        password: passworduser,
        usersid: userid,
        verification_code: verification_code,
        password1: password,
        password2: confirmPassword,
        new_password1: password,
        new_password2: confirmPassword,
      });

      if (response.status === 200) {
        Swal.fire({
          title: "Your password has been reset successfully.",
          icon: "success",
          toast: true,
          timer: 4000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        history.push("/");
      }
    } catch (error) {
      Swal.fire({
        title: "Something went wrong. Please try again later.",
        icon: "warning",
        toast: true,
        timer: 4000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center px-6 py-12 sm:px-6 lg:px-8 bg-gray-100">
      <div className="h-fit max-w-3xl w-full bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-center text-2xl font-semibold text-gray-700">
          Reset Password
        </h2>
        <p className="text-center text-sm text-gray-500">
          Please enter your new password.
        </p>
        <form onSubmit={handleResetPassword} className="mt-6">
          <div>
            <label htmlFor="password" className="font-medium text-[#6a6c6f]">
              Enter Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter Password"
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-green-200 focus:border-[#62CB31] focus:z-10 sm:text-sm"
            />
          </div>
          <div className="mt-4">
            <label
              htmlFor="confirmPassword"
              className="font-medium text-[#6a6c6f]"
            >
              Reenter the new Password
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Reenter the new Password"
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-green-200 focus:border-[#62CB31] focus:z-10 sm:text-sm"
            />
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-[#62CB31] hover:bg-[#74D348] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#62CB31]"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
