import React from "react";

import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function EstimatedQuarterlyWaterSavings() {
  const data = {
    labels: ["July-Sept-Q3-23", "Oct-Dec-Q4-23", "Jan-Mar-Q1-24"],
    datasets: [
      {
        label: "Saved Water (In Gallons)",
        data: [15000, -12000, -17000],
        backgroundColor: "rgb(98, 203, 48)",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Estimated Quarterly Water Savings",
        font: {
          size: 24,
          weight: "bold",
        },
        color: "gray",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <div className="chart flex w-full justify-center items-center" style={{ width: "100%" }}>
      <Bar data={data} options={options} />
    </div>
  );
}
