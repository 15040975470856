import React, {useContext} from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";

export default function LeakDetection() {
  const { sideBarOpen } = useContext(ThemeContext);
  return (
    <div
      className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 p-4 mt-20`}
      style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
    >
      <div className="p-4 bg-white shadow-md rounded-md dark:bg-gray-800">
        <caption className="flex animate__animated animate__fadeIn p-5 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
          No leak detected at this location!!{" "}
        </caption>
      </div>
    </div>
  );
}
