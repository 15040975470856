import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../context/themeContext/ThemeContext";
import states from "states-us";
import { useSelector } from "react-redux";
import ApiLink from "../api/ApiLink";

const AddAccounts = ({ fetchCompanyList }) => {
  const Swal = require("sweetalert2");

  const { color, textColor, backgroundColor } = useContext(ThemeContext);

  const [addAccount, setAddAccount] = useState(false);

  const [name, setName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [companyType, setCompanyType] = useState(1);
  const [phone, setPhone] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [isTestAccount, setIsTestAccount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const closeAddAccount = () => {
    setAddAccount(false);
    setName("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("");
    setZip("");
    setPhone("");
    setWebsiteURL("");
    setCompanyType(0);
    setIsTestAccount(0);
    setName("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("");
    setZip("");
    setPhone("");
    setWebsiteURL("");
    setCompanyType(0);
    setIsTestAccount(0);
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");

    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const handleZipChange = (e) => {
    const value = e.target.value;
    // Allow only digits and limit to 5 characters
    if (/^\d{0,5}$/.test(value)) {
      setZip(value);
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsTestAccount(checked ? 1 : 0); // Log 1 if checked, 0 if unchecked
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Name: ", name);
    console.log("Address Line 1: ", addressLine1);
    console.log("Address Line 2: ", addressLine2);
    console.log("City: ", city);
    console.log("State: ", state);
    console.log("Zip: ", zip);
    console.log("Company Type: ", companyType);
    console.log("Phone: ", phone);
    console.log("Website URL: ", websiteURL);
    console.log("Is Test Account: ", isTestAccount);

    Swal.fire({
      title: "Do you want to add this company account?",
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/add-company", {
            email: email,
            password: password,
            name: name,
            address_line1: addressLine1,
            address_line2: addressLine2 || "",
            city: city,
            state: state,
            zip: zip,
            phone: phone,
            website: websiteURL || "",
            type: companyType,
            is_test_account: isTestAccount,
          });

          if (response.status === 200) {
            if (response.data.Data.is_valid === true) {
              // Get the primary key from the response
              const companyId = response.data.Data.primary_key;

              // Make the second API call to /api/zoho-crm-add-company
              try {
                const zohoResponse = await ApiLink.post(
                  "/api/zoho-crm-add-company",
                  {
                    name: name,
                    address_line1: addressLine1,
                    address_line2: addressLine2 || "",
                    city: city,
                    state: state,
                    zip: zip,
                    phone: phone,
                    website: websiteURL || "",
                    type: companyType,
                    is_test_account: isTestAccount,
                    company_id: companyId,
                  }
                );

                if (zohoResponse.data[0].code == "SUCCESS") {
                  Swal.fire({
                    title: "Company Account Added Successfully",
                    icon: "success",
                    toast: true,
                    timer: 4000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                  });

                  closeAddAccount();
                  setName("");
                  setAddressLine1("");
                  setAddressLine2("");
                  setCity("");
                  setState("");
                  setZip("");
                  setPhone("");
                  setWebsiteURL("");
                  setCompanyType(0);
                  setIsTestAccount(0);
                  fetchCompanyList();
                }

                // Handle the response from the Zoho CRM API call if needed
                console.log("Zoho CRM API response:", zohoResponse);
              } catch (zohoError) {
                console.error("Error adding company to Zoho CRM:", zohoError);
                // You might want to show an error message to the user here
              }
            }
          } else if (response.data.Data.is_valid === false) {
            // Extract error messages from the object
            const errorMessages = Object.entries(response.data.Data.errors)
              .map(([field, messages]) => `${messages.join(", ")}`)
              .join("\n");

            Swal.fire({
              title: errorMessages,
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          console.error("Error adding company:", error);
          // You might want to show an error message to the user here
        }
      }
    });
  };
  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(addAccount);
    return () => toggleBodyScroll(false);
  }, [addAccount]);

  return (
    <>
      <button
        className={`px-3 py-1.5 text-sm rounded-md bg-${color}-200 hover:bg-${color}-400 text-${color}-800`}
        style={{ backgroundColor: `${backgroundColor}`, color: `${textColor}` }}
        onClick={() => setAddAccount(true)}
      >
        + Add Accounts
      </button>

      {addAccount && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
          style={{
            // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Add Sustain Sprinkler Account
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={closeAddAccount}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div>
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required
                      id="name"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setAddressLine1(e.target.value)}
                      value={addressLine1}
                      required
                      name="status"
                      id="status"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="countries"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Company Type
                    </label>
                    <select
                      id="companyType"
                      onChange={(e) => setCompanyType(Number(e.target.value))} // Convert to number
                      value={companyType}
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="" disabled>
                        Choose a type
                      </option>{" "}
                      {/* Default value should be handled in state */}
                      <option value={1}>City</option>
                      <option value={2}>Commercial Building</option>
                      <option value={3}>Community</option>
                      <option value={4}>Construction</option>
                      <option value={5}>County</option>
                      <option value={6}>Golf Course</option>
                      <option value={7}>Park</option>
                      <option value={8}>Portfolio Admin</option>
                      <option value={9}>Homeowner</option>
                      <option value={0}>Info not available</option>
                    </select>
                  </div>

                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address 2
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setAddressLine2(e.target.value)}
                      value={addressLine2}
                      name="status"
                      id="status"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      name="brand"
                      onChange={(e) =>
                        setPhone(formatPhoneNumber(e.target.value))
                      }
                      required
                      value={phone}
                      id="brand"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                      name="status"
                      id="status"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Website Url
                    </label>
                    <input
                      type="text"
                      name="status"
                      onChange={(e) => setWebsiteURL(e.target.value)}
                      value={websiteURL}
                      id="status"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      State
                    </label>
                    <select
                      id="companyType"
                      onChange={(e) => setState(e.target.value)}
                      value={state}
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="" disabled>
                        Select a State
                      </option>

                      {states.map((state) => (
                        <option value={state.abbreviation}>{state.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      checked={isTestAccount}
                      onChange={handleCheckboxChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Is Test Account
                    </label>
                  </div>
                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Zip Code
                    </label>
                    <input
                      type="number"
                      onChange={handleZipChange}
                      value={zip}
                      required
                      minLength={5}
                      maxLength={5}
                      name="zip"
                      id="zip"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={closeAddAccount}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAccounts;
