import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AsideLayout from "../ADMINMENU/AsideLayout/AsideLayout";
import { ThemeContext } from "../../components/context/themeContext/ThemeContext";

export default function ReportIndex() {
  const { sideBarOpen } = useContext(ThemeContext);

  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="p-4 shadow-md h-screen bg-white rounded-lg dark:bg-gray-800">
            <div
              style={{ padding: 10 }}
              className="p-3 flex text-lg rounded-xl font-semibold text-left rtl:text-right"
            >
              <span className="text-black font-bold text-xl dark:text-white border-b-2 border:text-black dark:border:bg-gray-800">
                Reports Index Page
              </span>
            </div>
            <div className="flex relative overflow-x-auto gap-4">
              <div>
                <table className="animate__animated w-full animate__fadeIn text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead>
                    <tr>
                      <th
                        className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800"
                        colSpan="2"
                      >
                        Reports
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-black dark:text-white">
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td>
                        <Link
                          to="/accounts/account-summary"
                          className="block px-6 py-4"
                        >
                          Accounts Summary
                        </Link>
                      </td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td>
                        <Link
                          to="/accounts/sfwmd-rainfall-data"
                          className="block px-6 py-4"
                        >
                          SFWMD Rainfall Data
                        </Link>
                      </td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td className="px-6 py-4">SQL Slow Logs</td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td>
                        <Link
                          to="/accounts/quarterly-reports"
                          className="block px-6 py-4"
                        >
                          Quarterly Report
                        </Link>
                      </td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td>
                        <Link
                          to="/accounts/offline-device-list"
                          className="block px-6 py-4"
                        >
                          Offline Device List
                        </Link>
                      </td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td>
                        <Link
                          to="/accounts/customer-login-report"
                          className="block px-6 py-4"
                        >
                          Customer Login Report
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table
                  className="animate__animated animate__fadeIn text-black dark:text-white text-sm text-left rtl:text-right"
                  style={{ width: 300 }}
                >
                  <thead>
                    <tr>
                      <th
                        className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800"
                        colSpan="2"
                      >
                        Generic Reports
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td className="px-6 py-4">
                        Possible Errors: Fixed Watering Days
                      </td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td className="px-6 py-4">
                        Possible Errors: Day Overlap
                      </td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td className="px-6 py-4">
                        Possible Errors: Firmware Lock-ups in last 24 Hours
                      </td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td className="px-6 py-4">
                        Systems with Day Overlap Programs
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="animate__animated animate__fadeIn text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead>
                    <tr>
                      <th
                        className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-800 bg-white dark:text-white dark:bg-gray-800"
                        colSpan="2"
                      >
                        OLD System Reports
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-black dark:text-white">
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td>
                        <Link
                          to="/accounts/jitter-report"
                          className="block px-6 py-4"
                        >
                          Jitter Report
                        </Link>
                      </td>
                    </tr>
                    <tr className="cursor-pointer hover:bg-gray-100 bg-white border border-black dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600">
                      <td>
                        <Link
                          to="/accounts/visual-feedback"
                          className="block px-6 py-4"
                        >
                          Visual Feedback
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsideLayout>
  );
}
