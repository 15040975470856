import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { useSelector } from "react-redux";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import ApiLink from "../../../components/api/ApiLink";
import { set } from "date-fns";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import Swal from "sweetalert2";

export default function CompanyType8() {
  const {
    sideBarOpen,
    tableHeaderColor,
    tableHeaderTextColor,
    backgroundColor,
    textColor,
  } = useContext(ThemeContext);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const [userListData, setUserListData] = useState([]);
  const [userCompanyLocationList, setUserCompanyLocationList] = useState([]);

  const { selectedCompanyName } = useSelectedCompany();

  const [companyData, setCompanyData] = useState([]);
  const [companyDataforAdding, setCompanyDataforAdding] = useState([]);

  const { companyName } = useParams();
  const [selectOptions, setSelectOptions] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    userType: "",
    selectedLocations: [],
  });
  // Add this handler for input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // Add this handler for checkbox changes
  const handleLocationCheckbox = (companyId, locationId, checked) => {
    setFormData((prev) => ({
      ...prev,
      selectedLocations: checked
        ? [...prev.selectedLocations, { companyId, locationId }]
        : prev.selectedLocations.filter(
            (loc) =>
              !(loc.companyId === companyId && loc.locationId === locationId)
          ),
    }));
  };

  console.log("CompanyName from company8: ", companyName);

  useEffect(() => {
    fetchUserList();
  }, [selectedCompanyName]);

  const fetchUserList = async () => {
    try {
      const response = await ApiLink.post("/api/user-list", {
        email: email,
        password: password,
        company_id: selectedCompanyName.company_id,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setUserListData(response.data.Data);
        }
      } else {
        setUserListData([]);
      }
    } catch (error) {
      console.error("Error:", error);
      setUserListData([]);
    }
  };

  const fetchUserCompanyLocationList = async () => {
    try {
      const response = await ApiLink.post(
        "/api/get-land-scape-accounts-location",
        {
          email: email,
          password: password,
          company_id: selectedCompanyName.company_id,
        }
      );
      console.log("User Company Location List: ", response.data.Data);
      if (response.data) {
        setUserCompanyLocationList(response.data.Data); // Set the response data to state
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchCompanyList = async () => {
    try {
      const response = await ApiLink.post("/api/company-list", {
        email: email,
        password: password,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setCompanyData(response.data.Data);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCompanyList();
    fetchUserCompanyLocationList();
    fetchCompanyListforAdding();
  }, []);

  const fetchCompanyListforAdding = async () => {
    try {
      const response = await ApiLink.post("/api/company-list", {
        email: email,
        password: password,
        status: 1,
      });

      if (response.status == 200) {
        if (Array.isArray(response.data.Data)) {
          setCompanyDataforAdding(response.data.Data);
        }
      }
    } catch (error) {}
  };

  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const handleResetPassword = async (userId) => {
    try {
      const confirmResult = await Swal.fire({
        title: "Do you want to send a password reset link?",
        text: "This will send a password reset email to the user",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, send it",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
      });

      if (confirmResult.isConfirmed) {
        setResetPasswordLoading(true);
        const response = await ApiLink.post("/api/send-password-reset-link", {
          email: email,
          password: password,
          usersid: userId,
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Password reset link has been sent successfully",
            icon: "success",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: "Failed to send password reset link",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    } catch (error) {
      console.error("Error sending reset link:", error);
      Swal.fire({
        title: "Error sending password reset link",
        icon: "error",
        toast: true,
        timer: 4000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setResetPasswordLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    Swal.fire({
      title: "Do you want to delete this user?",
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/delete-user", {
            email: email,
            password: password,
            usersid: userId,
          });
          if (response.status === 200) {
            Swal.fire("Successfully Deleted", "", "info");
          }
        } catch (error) {}
      } else if (result.isDenied) {
        Swal.fire("Deletion has been cancelled", "", "info");
      }
    });
  };
  const handleDeleteLocation = (companyId) => {
    Swal.fire({
      title: "Do you want to remove this company location?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post(
            "/api/remove-land-scape-accounts-location",
            {
              email: email,
              password: password,
              user_company_id: companyId,
            }
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Successfully Removed",
              icon: "success",
              toast: true,
              timer: 3000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            // Refresh data after successful removal
            fetchUserCompanyLocationList();
          }
        } catch (error) {
          console.error("Error removing company location:", error);
          Swal.fire({
            title: "Error removing company location",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const [addCompany, setAddCompany] = useState(false);
  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");

    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };
  const handleAddCompany = async (e) => {
    e.preventDefault();
    console.log("Form Data: ", formData);
    if (formData.selectedLocations.length === 0) {
      Swal.fire({
        title: "Error",
        text: "Please select at least one location",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }
    try {
      let data = {
        email: email,
        password: password,
        company_id: selectedCompanyName.company_id,
        user_email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone: formData.phone,
        usertype: formData.userType,
        alerts_access: 1,
        system_notifications: 1,
        is_read_only: 0,
        alerts: null,
        company_location_id: null,
      };
      const response = await ApiLink.post("/api/add-user", {
        ...data,
      });
      console.log("Response: ", response);
      if (response.status === 200) {
        let crm_data = {
          email: formData.email,
          phone: formData.phone,
          system_notifications: "1",
          usertype: "2",
          first_name: formData.firstName,
          last_name: formData.lastName,
          alerts_access: "1",
          is_read_only: "1",
          userid: response.data.Data.usersid,
          company_id: response.data.Data.company_id,
        };
        console.log("CRM Data: ", crm_data);
        Swal.fire("Successfully Added", "", "info");
        // const crm_response = await ApiLink.post("/api/zoho-crm-add-contact", {
        //   ...crm_data,
        // });
      }
    } catch (error) {
      //console.log("Error: ", error.response);
      Swal.fire(error.response.data.Message, "", "error");
      console.error("Error adding user:", error);
    }
  };

  const findCompanyLocation = (locationId, companies) => {
    for (const company of companies) {
      if (company.company_location_id) {
        try {
          const locations = JSON.parse(company.company_location_id);
          const foundLocation = locations.find(
            (loc) => loc.id === parseInt(locationId)
          );
          if (foundLocation) {
            return { company, location: foundLocation };
          }
        } catch (error) {
          console.error("Error parsing company_location_id:", error);
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (companyData.length > 0) {
      const options = companyData.flatMap((company) => {
        if (company.company_location_id) {
          try {
            const locations = JSON.parse(company.company_location_id);
            return locations.map((location) => ({
              location_id: location.id,
              company_id: company.company_id,
              value: `${company.company_id}-${location.id}`,
              label: `[${company.company_id}] - ${company.name}\nID: ${location.id}\nLocation Name: ${location.name}`,
            }));
          } catch (error) {
            console.error("Error parsing company_location_id:", error);
            return [];
          }
        }
        return [];
      });
      setSelectOptions(options);
    }
  }, [companyData]);

  const handleLocationChange = (selectedOption, user) => {
    // Handle the selection change here
    Swal.fire({
      title: "Do you want to add this company location?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/add-land-scape-location", {
            email: email,
            password: password,
            user_id: user.user_id,
            company_location_id: selectedOption.location_id,
            user_type: 2,
            company_id: selectedOption.company_id,
          });
          if (response.status === 200) {
            Swal.fire({
              title: "Successfully Add",
              icon: "success",
              toast: true,
              timer: 3000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            // Refresh data after successful removal
            fetchUserCompanyLocationList();
          }
        } catch (error) {
          console.error("Error adding company location:", error);
          if (error.response && error.response.status === 409) {
            Swal.fire({
              title: "User is already assigned to this location",
              icon: "error",
              toast: true,
              timer: 3000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          } else {
            Swal.fire({
              title: "Error adding company location",
              icon: "error",
              toast: true,
              timer: 3000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      }
    });

    // You might want to update the user's location or perform some other action here
  };

  const parseLocations = (locationString) => {
    try {
      return JSON.parse(locationString);
    } catch {
      return [];
    }
  };
  return (
    <div
      className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 p-4 mt-20`}
      style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
    >
      <div className="p-4 bg-white min-h-screen shadow-md rounded-md dark:bg-gray-800">
        <div className="flex justify-between">
          <div>
            <div className="p-1 mt-1 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              Sustainable Sprinklers Users
            </div>
          </div>
          <button
            className={`px-3 py-1.5 text-sm rounded-md font-semibold`}
            style={{
              backgroundColor: `${backgroundColor}`,
              color: `${textColor}`,
            }}
            onClick={() => setAddCompany(true)}
          >
            + Add User
          </button>
        </div>
        <div className="relative overflow-x-auto mt-4">
          <table className="border border-black dark:border-white animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right font-inter leading-4">
            <thead
              className={`border border-black dark:border-white text-sm uppercase font-inter leading-4`}
              style={{
                backgroundColor: `${tableHeaderColor}`,
                color: `${tableHeaderTextColor}`,
              }}
            >
              <tr>
                <th scope="col" className="px-6 py-3 border border-black">
                  Name/LoginID
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Accounts & Locations
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Date Added
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-sm font-inter leading-4 text-black dark:text-white">
              {userCompanyLocationList
                .reduce((acc, user) => {
                  // Check if we already have an entry for this user
                  const existingUser = acc.find(
                    (u) => u.user_id === user.user_id
                  );

                  if (existingUser) {
                    // If user exists, add the location info to their locations array
                    existingUser.locations.push({
                      company_location_id: user.company_location_id,
                      company_name: user.name,
                      address_line1: user.address_line1,
                      city: user.city,
                      state: user.state,
                      zip: user.zip,
                      user_company_id: user.user_company_id,
                    });
                    return acc;
                  } else {
                    // If this is a new user, create a new entry
                    return [
                      ...acc,
                      {
                        user_id: user.user_id,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                        status: user.status,
                        date_added: user.date_added,
                        locations: [
                          {
                            company_location_id: user.company_location_id,
                            company_name: user.name,
                            address_line1: user.address_line1,
                            city: user.city,
                            state: user.state,
                            zip: user.zip,
                            user_company_id: user.user_company_id,
                          },
                        ],
                      },
                    ];
                  }
                }, [])
                .map((user) => (
                  <tr
                    key={user.user_id}
                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="border border-black dark:border-white px-6 py-4">
                      <div>
                        <h1 className="mb-1 text-black dark:text-white">
                          <h1 className="flex items-center text-black dark:text-white">
                            <p className="text-black dark:text-white">
                              {user.first_name} {user.last_name}{" "}
                            </p>
                          </h1>
                        </h1>
                        <p className="text-black dark:text-white">
                          {user.email}
                        </p>
                      </div>
                    </td>
                    <td className="border border-black dark:border-white px-6 py-4 cursor-pointer">
                      {user.locations.length > 0 ? (
                        <div>
                          {user.locations.map((loc, index) => (
                            <h1 key={index} className="hover:underline mb-2">
                              <div className="flex items-center justify-between">
                                <Link
                                  to={`/location/${loc.company_location_id}`}
                                >
                                  {`${loc.company_name} - ${loc.address_line1}, ${loc.city}, ${loc.state} ${loc.zip} (ID: ${loc.user_company_id})`}
                                </Link>
                                <button
                                  className="ml-2 text-red-600 hover:text-red-800"
                                  onClick={() =>
                                    handleDeleteLocation(loc.user_company_id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </h1>
                          ))}
                        </div>
                      ) : (
                        <span>-</span>
                      )}

                      <div className="mt-4">
                        <hr />
                        <br />
                        <Select
                          id={`companyLists-${user.user_id}`}
                          classNamePrefix="react-select"
                          className="basic-select"
                          placeholder={`Add Location for ${user.first_name} ${user.last_name}`}
                          isSearchable
                          options={selectOptions}
                          onChange={(selectedOption) =>
                            handleLocationChange(selectedOption, user)
                          }
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: "gray-50",
                              borderColor: "gray-300",
                              borderRadius: "0.375rem",
                              padding: "0.5rem",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 100,
                            }),
                          }}
                          menuPosition={"fixed"}
                          menuPlacement={"auto"}
                        />
                      </div>
                    </td>
                    <td className="border border-black dark:border-white px-6 py-4">
                      {user.date_added
                        ? new Date(user.date_added).toLocaleString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        : "N/A"}
                    </td>
                    <td
                      className={`border border-black dark:border-gray-300 px-6 py-4 text-xs flex-col font-semibold text-white leading-4 text-center`}
                    >
                      <div
                        className={`status p-2 rounded-md `}
                        style={{
                          backgroundColor:
                            user.status === 1
                              ? "#74D348"
                              : user.status === 3
                              ? "red"
                              : user.status === 2
                              ? "#A4CAFE"
                              : "",
                        }}
                      >
                        {user.status === 1
                          ? "Active"
                          : user.status === 2
                          ? "Pending"
                          : user.status === 3
                          ? "Deleted"
                          : ""}
                      </div>
                    </td>
                    <td className="border border-black dark:border-white px-6 py-4">
                      <div className="flex justify-start items-center">
                        <button
                          className="delete p-1 hover:bg-gray-200 rounded-full"
                          onClick={() => handleDelete(user.user_id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="red"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                        <button
                          className="edit p-1 hover:bg-gray-200 rounded-full"
                          onClick={() => handleResetPassword(user.user_id)}
                        >
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="blue"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                              />
                            </svg>
                            <h1 className="ml-1 dark:text-white dark:hover:text-black">
                              Reset Pwd
                            </h1>
                          </div>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {addCompany && (
          <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 font-inter"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="relative p-4 w-full h-full md:h-auto my-8">
              {/* <!-- Modal content --> */}
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 overflow-y-auto max-h-[90vh]">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Add Sustain Sprinkler User
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={() => setAddCompany(false)}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <form onSubmit={handleAddCompany}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2">
                    <div>
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        required
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="friendlyDeviceName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                        required
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="phone"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        value={formData.phone}
                        required
                        onChange={(e) => {
                          const formattedPhone = formatPhoneNumber(
                            e.target.value
                          );
                          setFormData((prev) => ({
                            ...prev,
                            phone: formattedPhone,
                          }));
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="lastName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={formData.lastName}
                        required
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="userType"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        User Type
                      </label>
                      <select
                        name="userType"
                        id="userType"
                        value={formData.userType}
                        required
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="">Select User Type</option>
                        <option value="2">Account Admin</option>
                        <option value="4">Account User</option>
                      </select>
                    </div>
                  </div>
                  <h1 className="text-2xl font-bold mb-8">
                    <strong>Selected Locations: </strong>
                    <p className="text-sm text-gray-500 mb-4">
                      Please select at least one location
                    </p>
                  </h1>

                  <div className="mb-4"></div>
                  <div className="grid gap-4 mb-4 sm:grid-cols-4">
                    {companyDataforAdding.map((company) => (
                      <div key={company.company_id}>
                        <h1>
                          <strong>
                            [{company.company_id}] {company.name}
                          </strong>
                        </h1>
                        {parseLocations(company.company_location_id).map(
                          (location) => (
                            <div
                              key={location.id}
                              className="flex items-center gap-3"
                            >
                              <input
                                id={`select-location-checkbox-${location.id}`}
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(e) =>
                                  handleLocationCheckbox(
                                    company.company_id,
                                    location.id,
                                    e.target.checked
                                  )
                                }
                              />
                              <h1>{location.name}</h1>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="flex items-center justify-end mt-2">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={() => setAddCompany(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                      style={{ backgroundColor: "#009D01" }}
                    >
                      Add User
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
