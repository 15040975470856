import React, { useContext } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
export default function LocationLeakDetection() {
  const { color, textColor, backgroundColor, tableHeaderColor } =
    useContext(ThemeContext);
  return (
    <>
      <div
        className={`p-4 border-gray-200 border-dashed rounded-md`}
        style={{
          backgroundColor: `${backgroundColor}`,
          color: `${textColor}`,
        }}
      >
        <div className="relative overflow-x-auto sm:rounded-lg animate__animated animate__fadeIn">
          <h1 style={{ color: `${textColor}` }}>
            There is currently no leak detection for any locations! Please
            Contact customer support 954-688-9110 Ext(2) for questions regarding
            leak detection for you locations.
          </h1>
        </div>
      </div>
    </>
  );
}
