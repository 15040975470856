import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import FloatingSettings from "./FloatingSettings";
import { ThemeContext } from "./context/themeContext/ThemeContext";
import "animate.css";
import { useAuth } from "./context/authContext/AuthContext";
import { useDispatch } from "react-redux";
import { logoutAndClearState } from "../store/logoutAndClearState";
import NavigationBar from "./nav/NavigationBar";

export default function Aside() {
  const dispatch = useDispatch(); // Initialize dispatch

  const { logoutUser, username, lastname } = useAuth();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const sidebarRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const { color, textColor, backgroundColor, sideBarOpen } =
    useContext(ThemeContext);
  const [openAsideBar, setOpenAsideBar] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarVisible(false);
      }
    };

    if (sidebarVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarVisible]);

  const toggleDarkMode = () => {
    if (isDarkMode) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    }
    setIsDarkMode(!isDarkMode);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  const handleLogout = () => {
    dispatch(logoutAndClearState());
    sessionStorage.removeItem("activeItemLocation");
    sessionStorage.removeItem("activeItem");
    localStorage.removeItem("authTokens");
  };

  const toggleAsideBar = () => {
    setOpenAsideBar(!openAsideBar);
  };

  return (
    <div>
      <NavigationBar
        setSidebarVisible={setSidebarVisible}
        sidebarVisible={sidebarVisible}
        sidebarRef={sidebarRef}
        toggleAsideBar={toggleAsideBar}
        openAsideBar={openAsideBar}
      />
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 min-h-screen mt-4 pt-20 transition-transform overflow-y-auto ${
          sideBarOpen ? "-translate-x-0 sm:translate-x-0" : "-translate-x-full"
        } bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 overflow-y-auto`}
        aria-label="Sidebar"
      >
        <div className="flex flex-col justify-between min-h-screen px-3 pb-3 overflow-y-auto bg-white dark:bg-gray-800 text-base font-inter leading-5 font-normal">
          <ul className="flex flex-col justify-between space-y-2 font-medium">
            <li>
              <ul id="dropdown-admin" className="py-2 space-y-2">
                <Link to="/accounts">
                  <div className="pb-3">
                    <li
                      style={
                        activeItem === "/accounts"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "/accounts"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => handleItemClick("/accounts")}
                    >
                      <div className="flex gap-2 justify-center items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            />
                          </svg>
                        </div>
                        <h1 className="text-base">Accounts</h1>
                      </div>
                    </li>
                  </div>
                </Link>
                <Link to="/devices">
                  <div className="pb-3">
                    <li
                      style={
                        activeItem === "/devices"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "/devices"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => handleItemClick("/devices")}
                    >
                      <div className="flex gap-2 justify-center items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z"
                            />
                          </svg>
                        </div>
                        <div>Devices</div>
                      </div>
                    </li>
                  </div>
                </Link>
                <Link to="/flower-sensors">
                  <div className="pb-3">
                    <li
                      style={
                        activeItem === "/flower-sensors"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "/flower-sensors"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => handleItemClick("/flower-sensors")}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                          </svg>
                        </div>
                        <div>Flow sensor</div>
                      </div>
                    </li>
                  </div>
                </Link>
                <Link to="/protocol">
                  <div className="pb-3">
                    <li
                      style={
                        activeItem === "/protocol"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "/protocol"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => handleItemClick("/protocol")}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z"
                            />
                          </svg>
                        </div>
                        <div>Protocol</div>
                      </div>
                    </li>
                  </div>
                </Link>
                <Link to="/firmware">
                  <div className="pb-3">
                    <li
                      style={
                        activeItem === "/firmware"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "/firmware"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => handleItemClick("/firmware")}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
                            />
                          </svg>
                        </div>
                        <div>Firmware</div>
                      </div>
                    </li>
                  </div>
                </Link>
                <Link to="/audit-activity">
                  <div className="pb-3">
                    <li
                      style={
                        activeItem === "/audit-activity"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "/audit-activity"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => handleItemClick("/audit-activity")}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </div>
                        <div>Activity Logs</div>
                      </div>
                    </li>
                  </div>
                </Link>
                <Link to="/reportIndex">
                  <div className="pb-3">
                    <li
                      style={
                        activeItem === "/reportIndex"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "/reportIndex"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => handleItemClick("/reportIndex")}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                            />
                          </svg>
                        </div>
                        <div>Report Index</div>
                      </div>
                    </li>
                  </div>
                </Link>
                <Link to="/binAlerts">
                  <li
                    style={
                      activeItem === "/binAlerts"
                        ? { backgroundColor, color: textColor }
                        : {}
                    }
                    className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                      activeItem === "/binAlerts"
                        ? ""
                        : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    }`}
                    onClick={() => handleItemClick("/binAlerts")}
                  >
                    <div className="flex justify-center gap-2 items-center text-center font-normal">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                          />
                        </svg>
                      </div>
                      <div>Alerts Dashboard </div>
                    </div>
                  </li>
                </Link>
              </ul>
            </li>
          </ul>
          <ul className={`mb-20 `} style={{ color: textColor }}>
            <li
              className="flex items-center cursor-pointer w-full p-2 transition duration-75 border-t border-gray-800 dark:border-gray-400 pl-11 group"
              onClick={handleLogout}
            >
              <div
                className={`flex justify-center items-center gap-2 text-center font-semibold ${
                  textColor === "#ffffff" ? "text-black" : `${textColor}`
                } dark:text-white`}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    className="size-6"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                    />
                  </svg>
                </div>

                <div>Logout</div>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <FloatingSettings toggleDarkMode={toggleDarkMode} />
    </div>
  );
}
