import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setColor,
  setIsDarkMode,
  setTextColor,
  setBackgroundColor,
  setTableHeaderColor,
  setTableHeaderTextColor,
} from "../../../store/themeSlice";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    color,
    isDarkMode,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useSelector((state) => state.theme);

  const [sideBarOpen, setSideBarOpen] = useState(true);

  useEffect(() => {
    // Check local storage for saved theme
    const storedTheme = localStorage.getItem("color-theme");
    if (storedTheme === "dark") {
      dispatch(setIsDarkMode(true));
    } else {
      // Default to Light mode if no preference is stored
      dispatch(setIsDarkMode(false));
    }

    // Check for stored color
    const storedColor = localStorage.getItem("buttonColor");
    if (storedColor) {
      dispatch(setColor(storedColor));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    }
  }, [isDarkMode]);

  const handleThemeColor = (newColor) => {
    dispatch(setColor(newColor));
    let newTextColor,
      newBackgroundColor,
      newTableHeaderColor,
      newTableHeaderTextColor;

    switch (newColor) {
      case "pink":
        newBackgroundColor = "#FAD1E8";
        newTextColor = "#99154B";
        newTableHeaderTextColor = "#99154B";
        newTableHeaderColor = "#FAD1E8";

        break;
      case "blue":
        newBackgroundColor = "#C3DDFD";
        newTextColor = "#1E429F";
        newTableHeaderTextColor = "#1E429F";
        newTableHeaderColor = "#C3DDFD";
        break;
      case "green":
        newBackgroundColor = "#BCF0DA";
        newTextColor = "#03543F";
        newTableHeaderTextColor = "#03543F";
        newTableHeaderColor = "#BCF0DA";
        break;
      case "purple":
        newBackgroundColor = "#DCD7FE";
        newTextColor = "#5521B5";
        newTableHeaderTextColor = "#5521B5";
        newTableHeaderColor = "#DCD7FE";
        break;
      case "red":
        newBackgroundColor = "#FEE2E2";
        newTextColor = "#B91C1C";
        newTableHeaderTextColor = "#B91C1C";
        newTableHeaderColor = "#FEE2E2";
        break;
      case "yellow":
        newBackgroundColor = "#FEF9C3";
        newTextColor = "#92400E";
        newTableHeaderTextColor = "#92400E";
        newTableHeaderColor = "#FEF9C3";
        break;
      case "defaultGreen":
        newBackgroundColor = "#62CB31";
        newTextColor = "#ffffff";
        newTableHeaderTextColor = "#ffffff";
        newTableHeaderColor = "#62CB31";
        break;
      case "blackorange":
        newBackgroundColor = "#FE6F27";
        newTextColor = "#2F2F2F";
        newTableHeaderTextColor = "#2F2F2F";
        newTableHeaderColor = "#FE6F27";
        break;

      case "maroonpeach":
        newBackgroundColor = "#FFA67E";
        newTextColor = "#5A0F2E";
        newTableHeaderTextColor = "#5A0F2E";
        newTableHeaderColor = "#FFA67E";
        break;

      case "purpleblue":
        newBackgroundColor = "#00DCD6";
        newTextColor = "#5F0121";
        newTableHeaderTextColor = "#5F0121";
        newTableHeaderColor = "#00DCD6";
        break;

      case "navyorange":
        newBackgroundColor = "#F8A01C";
        newTextColor = "#030D4E";
        newTableHeaderTextColor = "#030D4E";
        newTableHeaderColor = "#F8A01C";
        break;

      case "darkblue":
        newBackgroundColor = "#10377A";
        newTextColor = "#9FAECE";
        newTableHeaderTextColor = "#9FAECE";
        newTableHeaderColor = "#10377A";
        break;

      case "powderblue":
        newBackgroundColor = "#7888DE";
        newTextColor = "#ABDBE6";
        newTableHeaderTextColor = "#ABDBE6";
        newTableHeaderColor = "#7888DE";
        break;
      case "royalblue":
        newBackgroundColor = "#FEC72D";
        newTextColor = "#367CF1";
        newTableHeaderTextColor = "#367CF1";
        newTableHeaderColor = "#FEC72D";
        break;

      case "emperorgray":
        newBackgroundColor = "#ECC7B2";
        newTextColor = "#504645";
        newTableHeaderTextColor = "#504645";
        newTableHeaderColor = "#ECC7B2";
        break;

      case "indigolavender":
        newBackgroundColor = "#60067D";
        newTextColor = "#C499CF";
        newTableHeaderTextColor = "#C499CF";
        newTableHeaderColor = "#60067D";
        break;

      case "purplewater":
        newBackgroundColor = "#591FBF";
        newTextColor = "#A4E8CC";
        newTableHeaderTextColor = "#A4E8CC";
        newTableHeaderColor = "#591FBF";
        break;

      case "sundownpink":
        newBackgroundColor = "#FCB6B0";
        newTextColor = "#993240";
        newTableHeaderTextColor = "#993240";
        newTableHeaderColor = "#FCB6B0";
        break;

      case "lightblue":
        newBackgroundColor = "#E13D56";
        newTextColor = "#BCFDF7";
        newTableHeaderTextColor = "#BCFDF7";
        newTableHeaderColor = "#E13D56";
        break;

      case "lemongreen":
        newBackgroundColor = "#B9DF10";
        newTextColor = "#390979";
        newTableHeaderTextColor = "#390979";
        newTableHeaderColor = "#B9DF10";
        break;

      case "bistrebrown":
        newBackgroundColor = "#A3842B";
        newTextColor = "#585001";
        newTableHeaderTextColor = "#585001";
        newTableHeaderColor = "#A3842B";
        break;

      case "naturalbrown":
        newBackgroundColor = "#A3842B";
        newTextColor = "#ffffff";
        newTableHeaderTextColor = "#ffffff";
        newTableHeaderColor = "#A3842B";
        break;

      case "naturalblue":
        newBackgroundColor = "#390979";
        newTextColor = "#ffffff";
        newTableHeaderTextColor = "#ffffff";
        newTableHeaderColor = "#390979";
        break;

      case "naturalred":
        newBackgroundColor = "#993240";
        newTextColor = "#ffffff";
        newTableHeaderTextColor = "#ffffff";
        newTableHeaderColor = "#993240";
        break;

      case "blacknwhite":
        newBackgroundColor = "#62CB31";
        newTextColor = "#ffffff";
        newTableHeaderTextColor = "#000000";
        newTableHeaderColor = "#ffffff";
        break;

      case "naturalorange":
        newBackgroundColor = "#FEC72D";
        newTextColor = "#ffffff";
        newTableHeaderTextColor = "#ffffff";
        newTableHeaderColor = "#FEC72D";
        break;

      case "naturallighblue":
        newBackgroundColor = "#218AC1";
        newTextColor = "#ffffff";
        newTableHeaderTextColor = "#ffffff";
        newTableHeaderColor = "#218AC1";
        break;

      default:
        newBackgroundColor = "#C3DDFD";
        newTextColor = "#1E429F";
        newTableHeaderTextColor = "#1E429F";
        newTableHeaderColor = "#C3DDFD";
    }

    dispatch(setTextColor(newTextColor));
    dispatch(setBackgroundColor(newBackgroundColor));
    dispatch(setTableHeaderColor(newTableHeaderColor));
    dispatch(setTableHeaderTextColor(newTableHeaderTextColor));

    localStorage.setItem("buttonColor", newColor);
  };

  const handleTextColor = (newColor) => {
    dispatch(setTextColor(newColor));
    document.documentElement.style.setProperty("--theme-color", newColor);
  };

  const handleBackgroundColor = (newColor) => {
    dispatch(setBackgroundColor(newColor));
    document.documentElement.style.setProperty(
      "--theme-background-color",
      newColor
    );
  };

  const handleTableHeaderColor = (newColor) => {
    dispatch(setTableHeaderColor(newColor));
    document.documentElement.style.setProperty(
      "--theme-table-header-color",
      newColor
    );
  };

  const handleTableHeaderTextColor = (newColor) => {
    dispatch(setTableHeaderTextColor(newColor));
    document.documentElement.style.setProperty(
      "--theme-table-header-text-color",
      newColor
    );
  };

  const toggleDarkMode = () => {
    const newIsDarkMode = !isDarkMode;
    dispatch(setIsDarkMode(newIsDarkMode));
    if (newIsDarkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        color,
        handleThemeColor,
        isDarkMode,
        toggleDarkMode,
        handleTextColor,
        textColor,
        handleBackgroundColor,
        backgroundColor,
        tableHeaderColor,
        handleTableHeaderColor,
        tableHeaderTextColor,
        handleTableHeaderTextColor,
        sideBarOpen,
        setSideBarOpen,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
