// sections.js

// Define all section constants
export const SECTION_ACCOUNTS = "sprinkler-account";
export const SECTION_USERS = "sprinkler-user";
export const SECTION_ACCOUNT_LOCATIONS = "sprinkler-account-location";
export const SECTION_ACCOUNT_LOCATION_PROGRAMS =
  "sprinkler-account-location-programs";
export const SECTION_ACCOUNT_LOCATION_DEVICES = "sprinkler-location-devices";
export const SECTION_ACCOUNT_LOCATION_LEAK_DETECTION =
  "sprinkler-account-location-leak-detection";
export const SECTION_DEVICES = "sprinkler-devices";
export const SECTION_DEVICES_DOWNLOAD = "sprinkler-devices-download";
export const SECTION_FLOW_SENSORS = "sprinkler-flow-sensors";
export const SECTION_FIRMWARE_PROTOCOLS = "sprinkler-firmware-protocols";
export const SECTION_FIRMWARE_PACKAGES = "sprinkler-firmware-packages";
export const SECTION_BILLING_RECORD = "sprinkler-billing-info";
export const SECTION_WATER_USAGE = "sprinkler-water-usage";
export const SECTION_SCHEDULE = "sprinkler-schedule";
export const SECTION_LOCATION_SCHEDULE = "sprinkler-location-schedule";
export const SECTION_LOCATION_WATER_RESTRICTIONS =
  "sprinkler-location-water-restrictions";
export const SECTION_LOCATION_ALERTS = "sprinkler-location-alerts";
export const SECTION_ACCOUNT_CONTACTS = "sprinkler-location-contacts";
export const SECTION_AUDIT_LOGS = "sprinkler-al";
export const SECTION_AUDIT_LOGS_DOWNLOAD = "sprinkler-al-download";
export const SECTION_ACTIVITY_LOGS = "sprinkler-ac";
export const SECTION_ACTIVITY_LOGS_DOWNLOAD = "sprinkler-ac-download";
export const SECTION_EMAIL_ALERTS_LOGS = "sprinkler-email-alert-logs";
export const SECTION_SFWMD = "sprinkler-swfmd";
export const SECTION_LOGIN_OLD = "sprinkler-login-old";
export const SECTION_LOGIN_NEW = "sprinkler-login-new";
export const SECTION_CHANGE_PASSWORD = "change-pwd";

// Export the getSectionByLabel function
export function getSectionByLabel(label) {
  const sections = {
    Accounts: SECTION_ACCOUNTS,
    Users: SECTION_USERS,
    "Account Locations": SECTION_ACCOUNT_LOCATIONS,
    "Account Location Programs": SECTION_ACCOUNT_LOCATION_PROGRAMS,
    "Account Location Devices": SECTION_ACCOUNT_LOCATION_DEVICES,
    "Account Location Leak Detection": SECTION_ACCOUNT_LOCATION_LEAK_DETECTION,
    Devices: SECTION_DEVICES,
    "Devices Download": SECTION_DEVICES_DOWNLOAD,
    "Flow Sensors": SECTION_FLOW_SENSORS,
    "Firmware Protocols": SECTION_FIRMWARE_PROTOCOLS,
    "Firmware Packages": SECTION_FIRMWARE_PACKAGES,
    "Billing Record": SECTION_BILLING_RECORD,
    "Water Usage": SECTION_WATER_USAGE,
    Schedule: SECTION_SCHEDULE,
    "Location Schedule": SECTION_LOCATION_SCHEDULE,
    "Location Water Restrictions": SECTION_LOCATION_WATER_RESTRICTIONS,
    "Location Alerts": SECTION_LOCATION_ALERTS,
    "Account Contacts": SECTION_ACCOUNT_CONTACTS,
    "Audit Logs": SECTION_AUDIT_LOGS,
    "Audit Logs Download": SECTION_AUDIT_LOGS_DOWNLOAD,
    "Activity Logs": SECTION_ACTIVITY_LOGS,
    "Activity Logs Download": SECTION_ACTIVITY_LOGS_DOWNLOAD,
    "Email Alerts Logs": SECTION_EMAIL_ALERTS_LOGS,
    SFWMD: SECTION_SFWMD,
    "Login Old Portal": SECTION_LOGIN_OLD,
    "Login New Portal": SECTION_LOGIN_NEW,
    "Change Password": SECTION_CHANGE_PASSWORD,
  };

  return sections[label] || null;
}

// Export an array of section labels
export const SECTION_LABELS = Object.keys({
  Accounts: SECTION_ACCOUNTS,
  Users: SECTION_USERS,
  "Account Locations": SECTION_ACCOUNT_LOCATIONS,
  "Account Location Programs": SECTION_ACCOUNT_LOCATION_PROGRAMS,
  "Account Location Devices": SECTION_ACCOUNT_LOCATION_DEVICES,
  "Account Location Leak Detection": SECTION_ACCOUNT_LOCATION_LEAK_DETECTION,
  Devices: SECTION_DEVICES,
  "Devices Download": SECTION_DEVICES_DOWNLOAD,
  "Flow Sensors": SECTION_FLOW_SENSORS,
  "Firmware Protocols": SECTION_FIRMWARE_PROTOCOLS,
  "Firmware Packages": SECTION_FIRMWARE_PACKAGES,
  "Billing Record": SECTION_BILLING_RECORD,
  "Water Usage": SECTION_WATER_USAGE,
  Schedule: SECTION_SCHEDULE,
  "Location Schedule": SECTION_LOCATION_SCHEDULE,
  "Location Water Restrictions": SECTION_LOCATION_WATER_RESTRICTIONS,
  "Location Alerts": SECTION_LOCATION_ALERTS,
  "Account Contacts": SECTION_ACCOUNT_CONTACTS,
  "Audit Logs": SECTION_AUDIT_LOGS,
  "Audit Logs Download": SECTION_AUDIT_LOGS_DOWNLOAD,
  "Activity Logs": SECTION_ACTIVITY_LOGS,
  "Activity Logs Download": SECTION_ACTIVITY_LOGS_DOWNLOAD,
  "Email Alerts Logs": SECTION_EMAIL_ALERTS_LOGS,
  SFWMD: SECTION_SFWMD,
  "Login Old Portal": SECTION_LOGIN_OLD,
  "Login New Portal": SECTION_LOGIN_NEW,
  "Change Password": SECTION_CHANGE_PASSWORD,
});
