import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../context/authContext/AuthContext";
import Swal from "sweetalert2";

const Avatar = ({ avatarDropdown }) => {
  const { logoutUser, email, username, lastname } = useAuth();
  const history = useHistory();

  const handleClickGenerateCode = () => {
    // Call the global showSessionCode function
    if (window.showSessionCode) {
      window.showSessionCode();
    } else {
      console.error("showSessionCode function not found");
    }
  };

  const handleGetSupportClick = () => {
    Swal.fire({
      html: `
        <h3 style="font-size: 24px; font-weight: bold; margin-bottom: 10px;">
          Choose an option to proceed
        </h3>
        <p style="font-size: 18px; margin-bottom: 20px;">
          Generate Code for immediate processing, or Create a Ticket for support.
        </p>
      `,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: "Generate Code",
      denyButtonText: "Create a Ticket",
      confirmButtonColor: "#62CB31",
      denyButtonColor: "#337AB7",
      customClass: {
        popup: "swal-custom-popup",
        confirmButton: "swal-custom-confirm-button",
        denyButton: "swal-custom-deny-button",
        closeButton: "swal-custom-close-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickGenerateCode();
      } else if (result.isDenied) {
        history.push("/get-support");
      }
    });
  };
  return (
    <div>
      <button
        type="button"
        className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 "
        aria-expanded="false"
        data-dropdown-toggle="dropdown-user"
      >
        <span className="sr-only">Open user menu</span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="size-6 w-8 h-8 rounded-full "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      </button>
      {avatarDropdown && (
        <div
          className="animate__animated animate__fadeIn absolute top-10 right-0 z-50 mt-2 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
          id="dropdown-user"
        >
          <div className="px-4 py-3">
            <p className="text-sm text-gray-900 dark:text-white">
              Name: {username} {lastname}
            </p>
            <p className="text-sm text-gray-900 truncate dark:text-white">
              Email: {email}
            </p>
          </div>
          <ul className="py-1 cursor-pointer" role="none">
            <Link to="/change-password">
              <li>
                <h1
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                  role="menuitem"
                >
                  Change Password
                </h1>
              </li>
            </Link>

            <li onClick={handleGetSupportClick}>
              <div
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem"
              >
                Get Support
              </div>
            </li>

            <li onClick={logoutUser}>
              <div
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                role="menuitem"
              >
                Logout
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Avatar;
