import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const FlowVisual = ({ flowData }) => {
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  useEffect(() => {
    if (flowData && flowData.length >= 2) {
      const expectedFlowData = flowData[0].data;
      const actualFlowData = flowData[1].data;

      const expectedFlow = expectedFlowData.map((item) => ({
        x: new Date(item[0]),
        y: item[1],
      }));
      const actualFlow = actualFlowData.map((item) => ({
        x: new Date(item[0]),
        y: item[1],
      }));

      setChartData({
        datasets: [
          {
            label: "Actual Flow",
            data: actualFlow,
            borderColor: "rgba(255, 204, 153, 1)",
            backgroundColor: "rgba(255, 204, 153, 0.5)",
            fill: true,
            order: 1,
            borderWidth: 1,
            pointRadius: 0,
            pointHoverRadius: 5,
          },
          {
            label: "Expected Flow",
            data: expectedFlow,
            borderColor: "rgba(173, 216, 230, 1)",
            backgroundColor: "rgba(173, 216, 230, 0.5)",
            fill: true,
            order: 2,
            borderWidth: 1,
            pointRadius: 0,
            pointHoverRadius: 5,
          },
        ],
      });
    }
  }, [flowData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
          stepSize: 30,
          displayFormats: {
            minute: "h:mm a",
          },
        },
        ticks: {
          source: "auto",
          autoSkip: true,
          maxRotation: 0,
          major: {
            enabled: true,
          },
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: true,
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        beginAtZero: true,
        max: 125,
        title: {
          display: true,
          text: "Flow Rate",
        },
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Flow Visual",
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].raw.x.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "2-digit",
              hour12: true,
            });
          },
        },
      },
    },
  };

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <Line options={options} data={chartData} />
    </div>
  );
};

export default FlowVisual;
