import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  authTokens: null,
  isAuthenticated: false,
  email: "",
  username: "",
  lastname: "",
  userType: 0,
  is_read_only: 0,
  company_location_id: null,
  usersid: 0,
  verification_code: "",
  company_id: 0,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, authTokens } = action.payload;
      state.user = user;
      state.authTokens = authTokens;
      state.isAuthenticated = true;
    },
    setUserDetails: (state, action) => {
      const {
        email,
        password,
        username,
        lastname,
        userType,
        is_read_only,
        company_location_id,
        usersid,
        verification_code,
        company_id,
      } = action.payload;
      state.email = email;
      state.password = password;
      state.username = username;
      state.lastname = lastname;
      state.userType = userType;
      state.is_read_only = is_read_only;
      state.company_location_id = company_location_id;
      state.usersid = usersid;
      state.verification_code = verification_code;
      state.company_id = company_id;
    },
    logout: (state) => {
      state.user = null;
      state.authTokens = null;
      state.isAuthenticated = false;
      state.email = "";
      state.username = "";
      state.lastname = "";
      state.userType = 0;
      state.is_read_only = 0;
      state.company_location_id = null;
      state.usersid = 0;
    },
  },
});

export const { setCredentials, setUserDetails, logout } = authSlice.actions;

export default authSlice.reducer;
