import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ApiLink from "../api/ApiLink";
import { useSelector } from "react-redux";
import { useSelectedCompany } from "../context/apiContext/SelectedCompanyContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function MonthlyWatering() {
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const { selectedCompanyName } = useSelectedCompany();
  const [chartsData, setChartsData] = useState([]);

  useEffect(() => {
    const fetchChartsData = async () => {
      try {
        const response = await ApiLink.post("/api/get-charts-data", {
          email: email,
          password: password,
          company_id: selectedCompanyName.company_id,
        });

        if (response.status === 200) {
          setChartsData(response.data.Data);
        }
      } catch (error) {
        console.error("Error:", "There is currently no charts data available");
      }
    };
    fetchChartsData();
  }, [email, password, selectedCompanyName]);
  const mapChartData = (data) => {
    const allDates = new Set();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    Object.values(data).forEach((location) => {
      Object.keys(location.data).forEach((date) => {
        const [year, month] = date.split("-");
        allDates.add(date);
      });
    });

    const sortedDates = Array.from(allDates).sort();

    const excludeMonthCount = 1;
    const labels = sortedDates.slice(excludeMonthCount);

    const solidColors = [
      "rgba(75, 192, 192, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 99, 132, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(153, 102, 255, 0.6)",
      "rgba(255, 159, 64, 0.6)",
      "rgba(199, 199, 199, 0.6)",
      "rgba(83, 102, 255, 0.6)",
      "rgba(204, 255, 144, 0.6)",
      "rgba(255, 127, 80, 0.6)",
      "rgba(130, 177, 255, 0.6)",
      "rgba(255, 99, 164, 0.6)",
      "rgba(173, 216, 230, 0.6)",
    ];

    const borderColors = solidColors.map((color) => color.replace("0.6", "1"));

    const datasets = Object.keys(data).map((key, index) => {
      const location = data[key];
      const locationData = location.data || {};
      const dataset = labels.map((date) => {
        const savings = locationData[date]?.savings || 0;
        return {
          x: date,
          y: 100 - savings,
          actualSavings: savings,
          name: locationData[date]?.name,
          xSprinkled: locationData[date]?.x,
          ySprinkled: locationData[date]?.y,
          companyName: location.company_location_name,
        };
      });

      return {
        label: location.company_location_name,
        backgroundColor: solidColors[index % solidColors.length],
        borderColor: borderColors[index % borderColors.length],
        borderWidth: 1,
        hoverBackgroundColor: solidColors[index % solidColors.length].replace(
          "0.6",
          "0.8"
        ),
        hoverBorderColor: borderColors[index % borderColors.length],
        data: dataset,
      };
    });

    return { labels, datasets };
  };

  const data = mapChartData(chartsData);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Monthly Waterings Per Locations",
        font: {
          size: 22,
          weight: "bold",
        },
        color: "gray",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const { dataset, dataIndex } = context;
            const dataPoint = dataset.data[dataIndex];
            return [
              `${dataPoint.companyName}`,
              `Sprinkled: ${dataPoint.xSprinkled} out of ${dataPoint.ySprinkled} times`,
              `Savings: ${dataPoint.actualSavings}%`,
            ];
          },
        },
        usePointStyle: true,
        boxWidth: 10,
        boxHeight: 10,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          display: true,
          text: "Watering Per Location",
        },
      },
    },
  };

  return (
    <div className="chart flex w-full justify-center items-center" style={{ width: "100%" }}>
      <Bar data={data} options={options} />
    </div>
  );
}
