import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./forms/Login";
import "./index.css";
import Accounts from "./pages/ADMINMENU/Accounts";
import Devices from "./pages/ADMINMENU/Devices";
import FlowSensor from "./pages/ADMINMENU/FlowSensor";
import Protocol from "./pages/ADMINMENU/Protocol";
import Firmware from "./pages/ADMINMENU/Firmware";
import AuditActivity from "./pages/REPORTS/AuditActivity";
import ReportIndex from "./pages/REPORTS/ReportIndex";
import BinAlerts from "./pages/REPORTS/BinAlerts";

import DeviceDetails from "./pages/ADMINMENU/AcountInfo/DeviceDetails";
import LocationPrograms from "./pages/ADMINMENU/LocationInfo/LocationPrograms";
import { ThemeProvider } from "./components/context/themeContext/ThemeContext";
import AsideChangePassword from "./pages/ADMINMENU/AvatarDropdownContent/ChangePassword";
import AccountSummary from "./pages/REPORTS/reportIndexContents/AccountSummary";
import SFWMD_Rainfall_Data from "./pages/REPORTS/reportIndexContents/SFWMD_Rainfall_Data";
import QuarterlyReport from "./pages/REPORTS/reportIndexContents/QuarterlyReports";
import Offline_Device_List from "./pages/REPORTS/reportIndexContents/Offline_Device_List";
import CustomerLoginReport from "./pages/REPORTS/reportIndexContents/CustomerLoginReport";
import JitterReport from "./pages/REPORTS/reportIndexContents/JitterReport";
import VisualFeedback from "./pages/REPORTS/reportIndexContents/VisualFeedback";
import { AuthProvider } from "./components/context/authContext/AuthContext";
import PrivateRoute from "./components/context/authContext/PrivateRoute";
import { SelectedCompanyProvider } from "./components/context/apiContext/SelectedCompanyContext";
import { CompanyLocationProvider } from "./components/context/apiContext/CompanyLocationContext";
import LocationAside from "./components/LocationAside";
import AccountAside from "./components/AccountAside";
import AccountChangePassword from "./pages/ADMINMENU/AcountInfo/AvatarDropdownContent/AccountChangePassword";
import LocationChangePassword from "./pages/ADMINMENU/LocationInfo/AvatarDropdownContent/LocationChangePassword";
import ForgotPassword from "./pages/ForgotPassword";
import GetSupport from "./pages/GetSupport";
import ResetPassword from "./pages/ResetPassword";
import AuthRedirect from "./components/context/authContext/AuthRedirect";
import Company8 from "./pages/ADMINMENU/Company8";
import LocationRoute from "./components/context/authContext/LocationRouteWrapper";
import NotFound from "./pages/NotFound";
function App() {

  
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <SelectedCompanyProvider>
            <CompanyLocationProvider>
              {" "}
              <Switch>
                <Route exact path="/" component={AuthRedirect} />{" "}
                <Route exact path="/login" component={Login} />{" "}
                <PrivateRoute
                  exact
                  component={AccountAside}
                  path="/accounts/:companyName"
                  allowedUserTypes={[1, 2]}
                />
                <PrivateRoute
                  exact
                  component={Accounts}
                  path="/accounts"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  exact
                  component={Devices}
                  path="/devices"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={FlowSensor}
                  path="/flower-sensors"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={Protocol}
                  path="/protocol"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={Firmware}
                  path="/firmware"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={AuditActivity}
                  path="/audit-activity"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={ReportIndex}
                  path="/reportIndex"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={BinAlerts}
                  path="/binAlerts"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={AsideChangePassword}
                  path="/change-password"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  exact
                  component={DeviceDetails}
                  path="/devices/:deviceID"
                  allowedUserTypes={[1]}
                />
                {/* REPORT INDEX CONTENTS */}
                <PrivateRoute
                  component={AccountSummary}
                  path="/accounts/account-summary"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={SFWMD_Rainfall_Data}
                  path="/accounts/sfwmd-rainfall-data"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={QuarterlyReport}
                  path="/accounts/quarterly-reports"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={Offline_Device_List}
                  path="/accounts/offline-device-list"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={CustomerLoginReport}
                  path="/accounts/customer-login-report"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={JitterReport}
                  path="/accounts/jitter-report"
                  allowedUserTypes={[1]}
                />
                <PrivateRoute
                  component={VisualFeedback}
                  path="/accounts/visual-feedback"
                  allowedUserTypes={[1]}
                />
                {/* ACCOUNT COMPANY INFO */}
                <PrivateRoute
                  component={AccountChangePassword}
                  path="/accounts/change-password"
                  allowedUserTypes={[1, 2]}
                />
                {/* <---------------------------------------------------> */}
                {/* <-------------------------Location Info ------------------------> */}
                <PrivateRoute
                  component={Company8}
                  path="/portfolio-account-home"
                  allowedUserTypes={[1, 2, 4]}
                />
                <LocationRoute
                  component={LocationAside}
                  path="/location/:companyName"
                  allowedUserTypes={[1, 2, 4]}
                />
                <PrivateRoute
                  component={LocationPrograms}
                  path="/location/programs"
                  allowedUserTypes={[1, 2, 4]}
                />
                <PrivateRoute
                  component={LocationChangePassword}
                  path="/location/change-password"
                  allowedUserTypes={[1, 2, 4]}
                />
                <PrivateRoute
                  component={GetSupport}
                  path="/get-support"
                  exact
                  allowedUserTypes={[1, 2, 4]}
                />
                <Route
                  component={ForgotPassword}
                  path="/forgot-password"
                  exact
                />
                <Route
                  component={ResetPassword}
                  path="/reset-pwd/u/:userid/verification_code/:verification_code"
                  exact
                />
                <Route component={NotFound} path="/notfound" />
              </Switch>
            </CompanyLocationProvider>
          </SelectedCompanyProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
