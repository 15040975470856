import React, { useContext } from "react";
import Aside from "../../../components/Aside";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { Link } from "react-router-dom";
import AsideLayout from "../../ADMINMENU/AsideLayout/AsideLayout";

const QuarterlyReport = () => {
  const { color, textColor, backgroundColor, tableHeaderColor, sideBarOpen} =
    useContext(ThemeContext);

  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };
  return (
    <AsideLayout>
     <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="animate__animated animate__fadeIn p-4 rounded-lg bg-white dark:bg-gray-800 ">
            <nav
              className="flex px-5 py-3 text-gray-700 border mb-4 justify-center border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
              aria-label="Breadcrumb"
            >
              <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li className="inline-flex items-center">
                  <h1 className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                    <svg
                      className="w-3 h-3 me-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                    </svg>
                    <Link to="/accounts">Home</Link>
                  </h1>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <h1 className="ms-1 text-sm cursor-pointer font-medium text-black hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
                      <Link to="/reportIndex">Report Index</Link>
                    </h1>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg
                      className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <span className="ms-1 text-sm font-medium text-black md:ms-2 dark:text-white">
                      Quarterly Report
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <div className="text-black dark:text-white">
              <div className="flex justify-between items-center">
                <h1 className="text-xl font-bold">Quarterly Report</h1>
                <div
                  className={`flex p-2 rounded-md text-${color}-800 bg-${color}-200 hover:bg-${color}-400 font-medium items-center gap-1`}
                  style={{
                    backgroundColor: `${backgroundColor}`,
                    color: `${textColor}`,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>

                  <button>Download Report</button>
                </div>
              </div>

              <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
                <div className="pb-4 bg-white dark:bg-gray-800">
                  <label for="table-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search for items"
                    />
                  </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white font-inter leading-4">
                  <thead
                    className={`text-sm uppercase bg-${color}-200 text-${color}-800`}
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${textColor}`,
                    }}
                  >
                    <tr>
                      <th scope="col" className="p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-500 dark:border-gray-600"
                          />
                          <label for="checkbox-all-search" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Company name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm font-inter leading-4">
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                      <td className="w-4 p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-table-3"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label for="checkbox-table-3" className="sr-only">
                            checkbox
                          </label>
                        </div>
                      </td>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        iPad
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsideLayout>
  );
};

export default QuarterlyReport;
