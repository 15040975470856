import { Bar } from "react-chartjs-2";
import React from "react";

const BarGraph = ({ alertCounts }) => {
  const labels = Object.keys(alertCounts);
  const data = Object.values(alertCounts);
  const maxCount = data.length > 0 ? Math.max(...data) : 0;

  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#C9CBCF",
    "#00712D",
    "#800000",
    "#522258",
    "#987D9A",
    "#36BA98",
    "#FFC7ED",
    "#F4A261",
    "#3C3D37",
    "#C5705D",
    "#B692C2",
    "#77E4C8",
    "#666666",
  ];

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Alert Counts",
        data: data,
        backgroundColor: colors,
        borderColor: colors.map((color) => color.replace("0.8", "1")),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: "#eeeeee", // Set color for legend labels
          font: {
            size: 14,
          },
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return chart.data.labels.map((label, i) => ({
              text: label,
              fillStyle: datasets[0].backgroundColor[i],
              hidden: false,
              lineCap: "butt",
              lineDash: [],
              lineDashOffset: 0,
              lineJoin: "miter",
              lineWidth: 1,
              strokeStyle: datasets[0].borderColor[i],
              pointStyle: "circle",
              datasetIndex: 0,
              index: i,
              fontColor: "gray",
            }));
          },
        },
        onClick: (e, legendItem, legend) => {
          const index = legendItem.index;
          const ci = legend.chart;
          const meta = ci.getDatasetMeta(0);
          const alreadyHidden = meta.data[index].hidden;

          ci.toggleDataVisibility(index);
          legendItem.hidden = !alreadyHidden;
          ci.update();
        },
      },
      title: {
        display: true,
        text: "Alert Event Frequency",
        color: "gray",
        font: {
          size: 18,
        },
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis labels
      },
      y: {
        beginAtZero: true,
        max: maxCount > 0 ? maxCount : 10,
        ticks: {
          stepSize: Math.max(1, Math.ceil(maxCount / 5)),
        },
      },
    },
  };

  return (
    <div
      className="flex flex-col justify-center"
      style={{ height: "600px", width: "100%" }}
    >
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarGraph;
