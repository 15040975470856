import axios from "axios";

const ApiLink = axios.create({
  baseURL: "https://apis.sustainablesprinkler.com/",
  // 34.74.60.16

  // 34.122.23.175
  headers: {
    "Content-Type": "application/json",
  },
});

export default ApiLink;
