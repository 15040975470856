import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLocation: {
    locationId: null,
    locationName: null,
    companyId: null,
    city: null,
    state: null,
    zip: null,
    address: null,
    type: null,
  },
  selectedCompanyName: {
    companyName: null,
    company_id: null,
    city: null,
    state: null,
    zip: null,
    type: null,
  },
  selectedSerialNumber: {
    deviceId: null,
  },
  locationsViewUsers: [],
  selectedDeviceId: {
    company_location_device_id: null,
    device_identifier: null,
  },
  companyData: [],
};

const selectedCompanySlice = createSlice({
  name: "selectedCompany",
  initialState,
  reducers: {
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setSelectedCompanyName: (state, action) => {
      state.selectedCompanyName = action.payload;
    },
    setSelectedSerialNumber: (state, action) => {
      state.selectedSerialNumber = action.payload;
    },
    setLocationsViewUsers: (state, action) => {
      state.locationsViewUsers = action.payload;
    },
    setSelectedDeviceId: (state, action) => {
      state.selectedDeviceId = action.payload;
    },

    setCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
    resetSelectedCompanyState: (state) => {
      return initialState;
    },
  },
});

export const {
  setSelectedLocation,
  setSelectedCompanyName,
  setLocationsViewUsers,
  setSelectedSerialNumber,
  setSelectedDeviceId,
  setCompanyData,
  resetSelectedCompanyState,
} = selectedCompanySlice.actions;

export default selectedCompanySlice.reducer;
