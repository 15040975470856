import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  color: "green",
  isDarkMode: false,
  textColor: "#ffffff",
  backgroundColor: "#62cb31",
  tableHeaderColor: "#ffffff",
  tableHeaderTextColor: "#000000",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setIsDarkMode: (state, action) => {
      state.isDarkMode = action.payload;
    },
    setTextColor: (state, action) => {
      state.textColor = action.payload;
    },
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    setTableHeaderColor: (state, action) => {
      state.tableHeaderColor = action.payload;
    },
    setTableHeaderTextColor: (state, action) => {
      state.tableHeaderTextColor = action.payload;
    },
  },
});

export const {
  setColor,
  setIsDarkMode,
  setTextColor,
  setBackgroundColor,
  setTableHeaderColor,
  setTableHeaderTextColor,
} = themeSlice.actions;
export default themeSlice.reducer;
