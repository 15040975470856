import React from "react";
import Aside from "../../../components/Aside";

export default function AsideLayout({ children }) {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-700">
      <Aside />
      <div className="">{children}</div>
    </div>
  );
}
