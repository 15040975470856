import React, { useEffect, useState } from "react";
import ApiLink from "../components/api/ApiLink";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function GetSupport() {
  const history = useHistory();
  const swal = require("sweetalert2");

  const [subject, setSubject] = useState("");
  const [location, setLocation] = useState("");
  const [device, setDevice] = useState("");
  const [message, setMessage] = useState("");

  const [emailAdd, setEmailAdd] = useState("");
  const company_id = useSelector((state) => state.auth.company_id);
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const [companyLocation, setCompanyLocation] = useState("");
  const company_location_id = useSelector(
    (state) => state.auth.company_location_id
  );
  const [companyLocationData, setCompanyLocationData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);

  useEffect(() => {
    fetchCompanyLocation();
    console.log("company_location_id:", company_location_id);
  }, [company_id]);

  const fetchCompanyLocation = async () => {
    try {
      const response = await ApiLink.post("/api/company-locations", {
        email: email,
        password: password,
        company_id: company_id,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data.Company_location)) {
          const allLocations = response.data.Data.Company_location;
          const filteredLocations = allLocations.filter((location) =>
            company_location_id.includes(
              location.company_location_id.toString()
            )
          );
          setCompanyLocationData(filteredLocations);
          console.log("Filtered company location data: ", filteredLocations);
        }
      } else {
        setCompanyLocationData([]);
      }
    } catch (error) {
      console.error("Error fetching company locations:", error);
      setCompanyLocationData([]);
    }
  };

  useEffect(() => {
    fetchCompanyLocationDevice();
  }, [companyLocation]);

  const fetchCompanyLocationDevice = async () => {
    try {
      const response = await ApiLink.post("/api/company-location-device", {
        email: email,
        password: password,
        company_location_id: companyLocation,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setDeviceData(response.data.Data);
        } else {
          setDeviceData([]);
        }
      } else {
        setDeviceData([]);
      }
    } catch (error) {
      setDeviceData([]);
    }
  };

  const handleSubmitSupport = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiLink.post("/api/submit-support", {
        email,
        subject,
        location,
        device,
        message,
      });

      if (response.status === 200) {
        swal.fire({
          title: "Your support request has been submitted successfully.",
          icon: "success",
          toast: true,
          timer: 4000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        history.push("/");
      }
    } catch (error) {
      swal.fire({
        title: "Something went wrong. Please try again later.",
        icon: "warning",
        toast: true,
        timer: 4000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center px-6 py-12 sm:px-6 lg:px-8 bg-gray-100">
      <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-center text-2xl font-semibold text-gray-700">
          Get Support
        </h2>
        <p className="text-center text-sm text-gray-500">
          Please fill out the form below to submit a support request.
        </p>
        <form onSubmit={handleSubmitSupport} className="mt-6 space-y-4">
          <div>
            <label htmlFor="email" className="font-medium text-[#6a6c6f]">
              Email Address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmailAdd(e.target.value)}
              required
              placeholder="user@domain.com"
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-green-200 focus:border-[#62CB31] focus:z-10 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="subject" className="font-medium text-[#6a6c6f]">
              Subject
            </label>
            <input
              id="subject"
              name="subject"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              placeholder=""
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-green-200 focus:border-[#62CB31] focus:z-10 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="location" className="font-medium text-[#6a6c6f]">
              Select Location to Report
            </label>
            <select
              id="location"
              name="location"
              value={companyLocation}
              onChange={(e) => setCompanyLocation(e.target.value)}
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-green-200 focus:border-[#62CB31] focus:z-10 sm:text-sm"
            >
              <option >Select a location</option>
              {companyLocationData.map((location) => (
                <option
                  key={location.company_location_id}
                  value={location.company_location_id}
                >
                  {location.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="device" className="font-medium text-[#6a6c6f]">
              Select Device
            </label>
            <select
              id="device"
              name="device"
              value={device}
              onChange={(e) => setDevice(e.target.value)}
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-green-200 focus:border-[#62CB31] focus:z-10 sm:text-sm"
            >
              <option disabled>Select a device</option>
              {deviceData.map((device) => (
                <option key={device.device_id} value={device.device_id}>
                  {device.device_identifier}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="message" className="font-medium text-[#6a6c6f]">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Describe your issue in detail"
              rows="4"
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-green-200 focus:border-[#62CB31] focus:z-10 sm:text-sm"
            ></textarea>
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-[#62CB31] hover:bg-[#74D348] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#62CB31]"
            >
              Submit Support Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GetSupport;
