import React, { useContext } from "react";
import UniversalChangePass from "../../../components/AvatarDropdownContent/UniversalChangePass";
import Aside from "../../../components/Aside";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";

export default function AsideChangePassword() {
  const { sideBarOpen } = useContext(ThemeContext);
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-700">
      <Aside />
      <div
        className={`min-h-screen mt-20 bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 bg-white dark:bg-gray-800 rounded-lg">
          <div>
            <UniversalChangePass
              parentPath="/accounts"
              pageParent="Admin Menu"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
