import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ApiLink from "../api/ApiLink";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const UniversalChangePass = ({ pageParent, parentPath }) => {
  const usersid = useSelector((state) => state.auth.usersid);
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  console.log("password:", password);
  const verification_code = useSelector(
    (state) => state.auth.verification_code
  );
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const resetPassword = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Do you want to change your password?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (oldpassword !== password) {
          Swal.fire({
            title: "Old Password isn't correct!",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          console.log("oldpassword:", oldpassword);
          console.log("password:", password);
          return;
        } else if (password1 !== password2) {
          Swal.fire({
            title: "Passwords do not match!",
            text: "Please ensure both passwords are the same.",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          return;
        }

        try {
          const response = await ApiLink.post("/api/reset-pwd", {
            email: email,
            password: password,
            usersid: usersid,
            verification_code: verification_code,
            password1: password1,
            password2: password2,
            new_password1: password1,
            new_password2: password2,
          });

          if (response.status === 200) {
            Swal.fire({
              title: "Password Change Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          console.error(error); // Log the error for debugging
          Swal.fire({
            title: "Password Change Unsuccessfully",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  return (
    <div className="text-white">
      <nav
        className="flex justify-center px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-100 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li>
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <h1 className="ms-1 text-sm font-medium text-black md:ms-2 dark:text-white dark:hover:text-white">
                Change Password
              </h1>
            </div>
          </li>
        </ol>
      </nav>

      <div className="">
        <h1 className="py-2 text-lg text-gray-900 font-bold dark:text-white">
          Change Password
        </h1>
        <form
          onSubmit={resetPassword}
          className="grid gap-6 mb-6 md:grid-cols-1"
        >
          <div>
            <label
              for="first_name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Enter Old Password
            </label>
            <input
              type="password"
              id="oldpassword"
              value={oldpassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label
              for="newpassword"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Enter New Password
            </label>
            <input
              type="password"
              id="first_name"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label
              for="confirmnewpassword"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              id="last_name"
              className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div className="flex justify-end gap-2">
            <button
              className="p-2 rounded-lg text-md w-15"
              style={{ backgroundColor: "#62cb31" }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UniversalChangePass;
