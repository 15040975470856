import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../../../components/context/themeContext/ThemeContext";
import Select from "react-select";
import { useSelectedCompany } from "../../../../components/context/apiContext/SelectedCompanyContext";
import { useSelector } from "react-redux";
import ApiLink from "../../../../components/api/ApiLink";
import { parse, differenceInMinutes, addDays } from "date-fns";

export default function AddProgramLocationDevices({
  addProgramModal,
  setAddProgramModal,
  companyLDID,
  loading,
  setLoading,
  fetchZoneList,
  fetchCompanyLocationDevice,
}) {
  const { textColor, backgroundColor, tableHeaderColor, tableHeaderTextColor } =
    useContext(ThemeContext);

  const Swal = require("sweetalert2");

  const options = Array.from({ length: 121 }, (_, i) => ({
    value: i,
    label: i.toString(),
  }));

  const [zoneValues, setZoneValues] = useState(
    Array.from({ length: 42 }, (_, i) => i + 1).reduce((acc, zone) => {
      acc[zone] = 0;
      return acc;
    }, {})
  );

  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const [searchInputs, setSearchInputs] = useState({});
  const { selectedLocation } = useSelectedCompany();
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const [selectedProgram, setSelectedProgram] = useState("A");
  const [isRegular, setIsRegular] = useState(false);
  const [halfRegular, setHalfRegular] = useState(false);
  const [overlap, setOverlap] = useState(false);
  const [selectedDays, setSelectedDays] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = false; // Set default value to false for each day
      return acc;
    }, {})
  );
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const formatTo12Hour = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  const convertTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    hours = parseInt(hours, 10);

    if (hours === 12) {
      hours = 0;
    }

    if (modifier === "PM") {
      hours = hours + 12;
    }

    // Convert hours to string and pad with zero if necessary
    const hoursString = hours.toString().padStart(2, "0");

    return `${hoursString}:${minutes}:00`;
  };
  const handleStartTimeChange = (event) => {
    const time = event.target.value;
    const formattedTime = formatTo12Hour(time);
    setStartTime(convertTo24Hour(formattedTime));
  };

  const handleEndTimeChange = (event) => {
    const time = event.target.value;
    const formattedTime = formatTo12Hour(time);
    setEndTime(convertTo24Hour(formattedTime));
  };
  const handleCheckboxChange = (day) => {
    setSelectedDays((prev) => ({
      ...prev,
      [day]: !prev[day], // Toggle the value
    }));
  };

  const handleZoneChange = (zone, selectedOption) => {
    setZoneValues((prevValues) => ({
      ...prevValues,
      [zone]: selectedOption.value,
    }));
  };
  const getZoneValue = (zone) => {
    return zoneValues[zone] !== undefined ? zoneValues[zone] : "";
  };

  const customFilter = (option, inputValue) => {
    if (inputValue === "") return true;
    const numericInput = inputValue.replace(/\D/g, "");
    return option.label.startsWith(numericInput);
  };

  const getDaysAsNumbers = () => {
    return Object.fromEntries(
      Object.entries(selectedDays).map(([day, isChecked]) => [
        day,
        isChecked ? 1 : 0, // Convert boolean to 0 or 1
      ])
    );
  };

  useEffect(() => {
    console.log("Email: ", email);
    console.log("Password: ", password);
    console.log("Company_id: ", selectedLocation.companyId);
    console.log("company_location_id: ", selectedLocation.locationId);
    console.log("company_location_device_id: ", companyLDID);
    console.log("SelectedProgram: ", selectedProgram);
    console.log("isRegular: ", isRegular === true ? 1 : 0);
    console.log("halfRegular: ", halfRegular === true ? 1 : 0);
    console.log("overlap: ", overlap === true ? 1 : 0);
    console.log(getDaysAsNumbers());

    console.log("startTime: ", startTime);
    console.log("endTime: ", endTime);

    console.log("zoneValues: ", zoneValues);
  }, [
    email,
    password,
    companyLDID,
    isRegular,
    halfRegular,
    overlap,
    selectedDays,
    startTime,
    endTime,
    zoneValues,
    selectedProgram,
  ]);

  const calculateDuration = (startTime, endTime, isNextDay) => {
    // Parse the time strings into Date objects
    const baseDate = new Date(2000, 0, 1); // Use an arbitrary date
    let [startHours, startMinutes] = startTime.split(":").map(Number);
    let [endHours, endMinutes] = endTime.split(":").map(Number);

    let start = new Date(baseDate);
    start.setHours(startHours, startMinutes, 0);

    let end = new Date(baseDate);
    end.setHours(endHours, endMinutes, 0);

    // If it's next day, add a day to the end time
    if (isNextDay) {
      end = addDays(end, 1);
    }

    // Calculate the difference in minutes
    const durationMinutes = differenceInMinutes(end, start);

    // Ensure the duration is always positive
    return Math.abs(durationMinutes);
  };
  const formatDaysArray = () => {
    const daysOrder = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    return daysOrder.map((day) => (selectedDays[day] ? 1 : 0));
  };

  const formatZonesArray = () => {
    return Array.from({ length: 42 }, (_, i) => {
      const zoneNumber = i + 1;
      return zoneValues[zoneNumber] || 0;
    });
  };

  const handleAddProgramSubmit = (e) => {
    e.preventDefault();
    const duration = calculateDuration(startTime, endTime);

    const formattedDays = formatDaysArray();

    const formattedZones = formatZonesArray();
    console.log("FormattedDays after click: ", formattedDays);
    console.log("FormattedZones after click: ", formattedZones);

    const requestBody = {
      company_location_device_id: companyLDID,
      company_location_id: selectedLocation.locationId,
      company_id: selectedLocation.companyId,

      email: email,
      password: password,
      start_time: startTime,
      end_time: endTime,
      program: selectedProgram,
      duration_minutes: duration,
      is_next_day: overlap ? "1" : "0",
      is_regular: isRegular ? "1" : "0",
      is_half_sprinkle: halfRegular ? "1" : "0",
      zones: formattedZones,
      days: formattedDays,
    };
    console.log("Duration: ", duration);

    Swal.fire({
      title: "Do you want to add this Program?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post(
            "/api/add-company-location-watering-program",
            requestBody
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Location Device Program Added Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            setAddProgramModal(false);
            fetchZoneList();
            fetchCompanyLocationDevice();
          }
        } catch (error) {
          Swal.fire({
            title: "There is an error. Please try again.",
            icon: "warning",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  return (
    <div
      id="defaultModal"
      tabindex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 font-inter"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="relative p-4 w-full h-full md:h-auto my-8">
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 overflow-y-auto max-h-[90vh]">
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Location Device Programs
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
              onClick={() => setAddProgramModal(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="ml-1">
            <div className="flex justify-between">
              <h1 className="font-semibold text-xl my-3 text-gray-900 dark:text-white">
                Days of Week
              </h1>
              <button
                className={`rounded-md h-fit p-2`}
                style={{ backgroundColor: backgroundColor, color: textColor }}
              >
                <h1 className="text-sm"></h1>
              </button>
            </div>
            <div className="grid gap-4 mb-4 sm:grid-cols-6 border-b border-gray-300 pb-5">
              <div>
                <label
                  htmlFor="program"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Program Name
                </label>
                <select
                  id="program"
                  value={selectedProgram}
                  onChange={(e) => setSelectedProgram(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="startTime"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Start Time
                </label>
                <input
                  type="time"
                  id="startTime"
                  required
                  onChange={handleStartTimeChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="endTime"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  End Time
                </label>
                <input
                  type="time"
                  id="endTime"
                  required
                  onChange={handleEndTimeChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <div className="flex items-center">
                <input
                  id="isRegular"
                  type="checkbox"
                  checked={isRegular}
                  onChange={(e) => setIsRegular(e.target.checked)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="isRegular"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Is Regular
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="isHalfSprinkle"
                  type="checkbox"
                  checked={halfRegular}
                  onChange={(e) => setHalfRegular(e.target.checked)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="isHalfSprinkle"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  1/2 Sprinkle
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="isDayOverlap"
                  type="checkbox"
                  checked={overlap}
                  onChange={(e) => setOverlap(e.target.checked)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="isDayOverlap"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  is Day Overlap
                </label>
              </div>
            </div>
            <div className="grid gap-4 mb-4 sm:grid-cols-7 border-b border-gray-300 pb-5 my-3">
              {daysOfWeek.map((day) => (
                <div key={day} className="flex items-center">
                  <input
                    id={day}
                    type="checkbox"
                    checked={!!selectedDays[day]} // Convert undefined to false
                    onChange={() => handleCheckboxChange(day)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor={day}
                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </label>
                </div>
              ))}
            </div>

            <div className="grid gap-4 mb-4 sm:grid-cols-6 border-b border-gray-300 pb-5 my-3">
              {Array.from({ length: 42 }, (_, i) => i + 1).map((zone) => (
                <div key={zone} className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Zone {zone}
                  </label>
                  <Select
                    options={options}
                    value={options.find(
                      (option) => option.value === getZoneValue(zone)
                    )}
                    onChange={(selectedOption) =>
                      handleZoneChange(zone, selectedOption)
                    }
                    placeholder="Select..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    filterOption={customFilter}
                    isSearchable={true}
                    noOptionsMessage={() => "No valid number"}
                  />
                </div>
              ))}
            </div>
            <div className="flex items-center justify-end">
              <button
                className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                style={{ backgroundColor: "red" }}
                onClick={() => setAddProgramModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                style={{ backgroundColor: "#009D01" }}
                onClick={handleAddProgramSubmit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
