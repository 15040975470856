import React, { useContext } from "react";
import Aside from "../../../components/Aside";
import { Link } from "react-router-dom";
import AsideLayout from "../../ADMINMENU/AsideLayout/AsideLayout";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";

const JitterReport = () => {
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    sideBarOpen,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="animate__animated animate__fadeIn p-4 rounded-lg shadow-md bg-white dark:bg-gray-800 ">
            <div className="text-black dark:text-white">
              <nav
                className="flex px-5 py-3 text-gray-700 border mb-4 justify-center border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
                aria-label="Breadcrumb"
              >
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                  <li className="inline-flex items-center">
                    <h1 className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                      <svg
                        className="w-3 h-3 me-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                      </svg>
                      <Link to="/accounts">Home</Link>
                    </h1>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <h1 className="ms-1 text-sm cursor-pointer font-medium text-black hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
                        <Link to="/reportIndex">Report Index</Link>
                      </h1>
                    </div>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg
                        className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <span className="ms-1 text-sm font-medium text-black md:ms-2 dark:text-white">
                        Jitter Report
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
              <div>
                <h1 className="font-bold text-2xl py-4">
                  Jitter Report for Past 30 Days
                </h1>
              </div>

              <div className="relative overflow-x-auto shadow-md">
                <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                  <thead
                    className={`text-sm uppercase bg-${color}-200 text-${color}-800 font-inter leading-4`}
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${tableHeaderTextColor}`,
                    }}
                  >
                    <tr>
                      <th scope="col" className="border border-black px-6 py-3">
                        Serial
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Device Identifier
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Date Added
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm font-inter leading-4">
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        Silver
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        Laptop
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        $2999
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsideLayout>
  );
};

export default JitterReport;
