import React, { useContext } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { Link } from "react-router-dom";
import "animate.css";
import AsideLayout from "../../ADMINMENU/AsideLayout/AsideLayout";

const AccountSummary = () => {
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
    sideBarOpen,
  } = useContext(ThemeContext);

  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };
  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="p-4 shadow-md rounded-lg bg-white dark:bg-gray-800">
            <div className="animate__animated animate__fadeIn text-black dark:text-white ">
              <nav
                className="flex px-5 py-3 text-gray-700 border justify-center border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
                aria-label="Breadcrumb"
              >
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                  <li className="inline-flex items-center">
                    <h1 className="inline-flex items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                      <svg
                        className="w-3 h-3 me-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                      </svg>
                      <Link to="/accounts">Home</Link>
                    </h1>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <h1 className="ms-1 text-sm cursor-pointer font-medium text-black hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
                        <Link to="/reportIndex">Report Index</Link>
                      </h1>
                    </div>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg
                        className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <span className="ms-1 text-sm font-medium text-black md:ms-2 dark:text-white">
                        Account Summary
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>

              <div className="flex py-3 justify-between items-center text-center">
                <h1 className="font-bold text-xl mb-4">Account Summary</h1>
                <button
                  className={`px-2 py-2 rounded-md `}
                  style={{
                    backgroundColor: backgroundColor,
                    color: textColor,
                  }}
                >
                  <div className="flex flex-row">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>

                    <h1 className="font-medium" style={{color: textColor}}>Download Report</h1>
                  </div>
                </button>
              </div>
              <div className="relative overflow-x-auto shadow-md">
                <table className="border w-full text-sm text-left rtl:text-right">
                  <thead
                    className={`text-sm uppercase font-inter leading-4`}
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${tableHeaderTextColor}`,
                    }}
                  >
                    <tr>
                      <th scope="col" className="border border-black px-6 py-3">
                        Account Name
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Date Added
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Location Name
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Date Added
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Timer Type
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Serial Number
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Water Type
                      </th>
                      <th scope="col" className="border border-black px-6 py-3">
                        Skip Interval
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm text-black dark:text-white font-inter leading-4">
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800">
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        <div className="flex flex-col">
                          <h1>Bent Creek @ Brokensound</h1> <p>Community</p>
                        </div>
                      </td>

                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        02/14/2018{" "}
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        <div className="flex flex-col">
                          <h1>Left Entrance Wall</h1> <p>Community</p>
                        </div>
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        02/14/2018
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        <div className="flex flex-col">
                          <h1>Digital Timer</h1> <p>02/14/2018</p>
                        </div>
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        00000000bdc307dd
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        City
                      </td>
                      <td className="border border-black dark:border-gray-300 px-6 py-4">
                        {" "}
                        3
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AsideLayout>
  );
};

export default AccountSummary;
