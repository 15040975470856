import React from "react";
import LocationAside from "../../../../components/LocationAside";
import UniversalChangePass from "../../../../components/AvatarDropdownContent/UniversalChangePass";

export default function LocationChangePassword() {
  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg">
      <div>
        <UniversalChangePass
          parentPath="/location"
          pageParent="Location Menu"
        />
      </div>
    </div>
  );
}
