import React, { createContext, useState, useContext } from "react";

const CompanyLocationContext = createContext();

export const CompanyLocationProvider = ({ children }) => {
  const [companyLocationData, setCompanyLocationData] = useState([]);

  const CompanyLocationDetails =
    companyLocationData?.Company_location?.map((location) => ({
      contact1_name: location.contact1_name,
      address_line1: location.address_line1,
      city: location.city,
      name: location.name,
      id: location.company_id,
      location_id: location.company_location_id,
    })) || [];

  return (
    <CompanyLocationContext.Provider
      value={{
        companyLocationData,
        setCompanyLocationData,
        CompanyLocationDetails,
      }}
    >
      {children}
    </CompanyLocationContext.Provider>
  );
};

export const useCompanyLocation = () => useContext(CompanyLocationContext);
