import React, { useState, useContext, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import ApiLink from "../../../components/api/ApiLink";
import { useSelector } from "react-redux";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import { Link, useParams } from "react-router-dom";

export default function LocationControlPanel() {
  const { companyName } = useParams();

  useEffect(() => {
    console.log("Current URL: ", companyName);
  }, [companyName]);
  const swal = require("sweetalert2");
  const [selectedLocation, setSelectedLocation] = useState({
    locationId: null,
    locationName: null,
    companyId: null,
    city: null,
    state: null,
    zip: null,
    address: null,
    type: null,
  });

  const [dropdown, setDropdown] = useState(false);
  const [selected1stDate, setSelected1stDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState("Select");
  const [isNumber2Selected, setIsNumber2Selected] = useState(false);
  const [isNumber3selected, setIsNumber3Selected] = useState(false);
  const [isNumber4selected, setIsNumber4Selected] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [zoneLabels, setZoneLabels] = useState({});
  const [zoneLabelNames, setZoneLabelNames] = useState({});
  const [zoneInputs, setZoneInputs] = useState({});

  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const [customWateringScheduleData, setCustomWateringScheduleData] = useState(
    []
  );
  const [companyLocationDeviceData, setCompanyLocationDeviceData] = useState(
    []
  );
  const [deviceId, setDeviceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const {
    selectedDeviceId,
    setSelectedDeviceId,
    updateSelectedDeviceId,
    updateSerialNumber,
  } = useSelectedCompany();

  const [showPrograms, setShowPrograms] = useState(false);
  const [zoneListData, setZoneListData] = useState({ Programs: {}, Zones: {} });
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState({});
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  // const is_read_only = useState(0);
  const userType = useSelector((state) => state.auth.userType);
  const [selectedProgramDetails, setSelectedProgramDetails] = useState({
    program: null,
    start: null,
    end: null,
    zone: null,
    type: null,
    days: null,
  });
  const [currentDisplay, setCurrentDisplay] = useState("first");
  const [editProgram, setEditProgram] = useState(false);
  const [zoneValues, setZoneValues] = useState({});
  const [openDropdown, setOpenDropdown] = useState(null);
  const [searchInputs, setSearchInputs] = useState({});
  const [zoneListModal, setZoneListModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  const dropdownRef = useRef(null);

  const fileInputRef = useRef(null);

  // addCustomWateringSchedule
  const [selectedProgram, setSelectedProgram] = useState(null);
  const currentDate = new Date();

  // Set the default date to today's date
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(new Date(currentDate.getTime()));
  const [quantity, setQuantity] = useState(0);
  const [notes, setNotes] = useState("");
  const [customWateringAddLoading, setCustomWateringAddLoading] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiLink.post("/api/company-locations-id", {
          email: email,
          password: password,
          company_location_id: companyName,
        });

        if (response.status === 200) {
          setSelectedLocation({
            locationId: response.data.Data.Company_location.company_location_id,
            locationName: response.data.Data.Company_location.name,
            companyId: response.data.Data.Company_location.company_id,
            city: response.data.Data.Company_location.city,
            state: response.data.Data.Company_location.state,
            zip: response.data.Data.Company_location.zip,
            address: response.data.Data.Company_location.address_line1,
            type: response.data.Data.Company_location.type,
          });
          console.log(
            "Selected Location from location info: ",
            selectedLocation
          );
        }
      } catch (error) {
        console.error(error); // Handle the error appropriately
      }
    };

    fetchData();
  }, [companyName]);

  useEffect(() => {
    setIsLoading(true);
    setLoading(true);

    if (selectedLocation.locationId) {
      fetchCompanyLocationDevice();
    }
  }, [selectedLocation.locationId, email, password]);
  const fetchCompanyLocationDevice = async () => {
    try {
      const response = await ApiLink.post("/api/company-location-device", {
        email: email,
        password: password,
        company_location_id: selectedLocation.locationId,
      });

      if (response.status === 200) {
        if (
          Array.isArray(response.data.Data) &&
          response.data.Data.length > 0
        ) {
          setCompanyLocationDeviceData(response.data.Data);
          setSelectedDevice(response.data.Data[0]);
          setDeviceId(response.data.Data[0].company_location_device_id);

          // Parse the zone_labels JSON string
          const parsedZoneLabels = JSON.parse(
            response.data.Data[0].zone_labels
          );
          setZoneLabelNames(parsedZoneLabels);
        } else {
          // Clear the states when no devices are found
          setCompanyLocationDeviceData([]);
          setSelectedDevice(null);
          setDeviceId(null);
          setZoneLabelNames({});
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // Clear the states in case of an error
      setCompanyLocationDeviceData([]);
      setSelectedDevice(null);
      setDeviceId(null);
      setZoneLabelNames({});
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCustomWateringScheduleData([]);
    setCustomWateringAddLoading(true);

    fetchCustomWateringSchedule();
  }, [email, password, selectedLocation.locationId, selectedDevice]);
  const fetchCustomWateringSchedule = async () => {
    try {
      const response = await ApiLink.post("/api/custom-watering-schedule", {
        email: email,
        password: password,
        company_location_device_id: selectedDevice.company_location_device_id,
        company_location_id: selectedLocation.locationId,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setCustomWateringScheduleData(response.data.Data);
        } else if (response.data.Data === "No records found!") {
          setCustomWateringScheduleData([]);
        }
      }
    } catch (error) {
      setCustomWateringScheduleData([]);
    } finally {
      setLoading(false);
      setCustomWateringAddLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchZoneList();
  }, [email, password, deviceId]);
  const fetchZoneList = async () => {
    try {
      const response = await ApiLink.post("/api/zone-list", {
        email: email,
        password: password,
        company_location_device_id: selectedDevice.company_location_device_id,
      });

      if (response.status === 200 && response.data.Data) {
        setZoneListData(response.data.Data);
      }
    } catch (error) {
      console.error("Error fetching zone list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchUploadImage = async () => {
      try {
        const response = await ApiLink.post("/api/get-upload-image", {
          email: email,
          password: password,
          company_location_device_id: selectedDevice.company_location_device_id,
        });

        if (response.status === 200 && response.data.Data) {
          setUploadedImages(response.data.Data.image_data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchUploadImage();
  }, [email, password, deviceId]);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleFirstDateChange = (date) => {
    setSelected1stDate(date);
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setSelected1stDate(formattedDate);
  };

  const [isAllZonesSelected, setAllZonesSelected] = useState(false);
  const [selectedZones, setSelectedZones] = useState([]);

  const [zoneCheckboxes, setZoneCheckboxes] = useState(Array(9).fill(false));

  const handleAllZonesSelect = () => {
    if (!isAllZonesSelected) {
      // Select all zones
      const allZoneNumbers = zoneLabels["A"]
        .map((zone) => {
          const zoneKey = Object.keys(zone)[0];
          return zoneKey.match(/\d+/)?.[0] || null;
        })
        .filter(Boolean); // Filter out any null values

      setSelectedZones(allZoneNumbers);
      setAllZonesSelected(true);
      setZoneCheckboxes(Array(zoneLabels["A"].length).fill(true)); // Check all boxes
      setIsNumber3Selected(true);
    } else {
      // Deselect all zones
      setSelectedZones([]);
      setAllZonesSelected(false);
      setZoneCheckboxes(Array(zoneLabels["A"].length).fill(false)); // Uncheck all boxes
    }
  };

  const handleCheckboxSelect = (index, zoneKey) => {
    const zoneNumber = zoneKey.match(/\d+/)?.[0] || null;

    if (zoneNumber) {
      console.log(`Selected zone: ${zoneNumber}`);

      // Toggle zone selection
      if (selectedZones.includes(zoneNumber)) {
        // Remove from selected zones if already selected
        setSelectedZones(selectedZones.filter((zone) => zone !== zoneNumber));
      } else {
        // Add to selected zones if not already selected
        setSelectedZones([...selectedZones, zoneNumber]);
      }
    }

    // Update zone checkboxes
    const newZoneCheckboxes = [...zoneCheckboxes];
    newZoneCheckboxes[index] = !zoneCheckboxes[index];
    setZoneCheckboxes(newZoneCheckboxes);

    // Update "All Zones" checkbox state based on individual selections
    if (!newZoneCheckboxes.some((val) => !val)) {
      setAllZonesSelected(true);
    } else {
      setAllZonesSelected(false);
    }

    setIsNumber3Selected(true);
  };

  useEffect(() => {
    if (quantity > 0) {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate() + quantity); // Add quantity days to startDate
      setEndDate(newEndDate);
    } else {
      setEndDate(startDate); // Set to null when quantity is 0
    }
  }, [quantity, startDate]);

  const decrementQuantity = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  const incrementQuantity = () => {
    if (quantity < 60) {
      setQuantity(quantity + 1);
    }
  };
  const handleLocationDevices = async (event) => {
    const selectedDeviceId = Number(event.target.value);
    const selectedDevice = companyLocationDeviceData.find(
      (device) => device.company_location_device_id === selectedDeviceId
    );

    if (selectedDevice) {
      setSelectedDevice(selectedDevice);
      setDeviceId(selectedDevice.company_location_device_id);
    } else {
      setSelectedDevice(null);
      setDeviceId(null);
    }
  };
  const handleSerialNumberClick = (deviceId) => {
    updateSerialNumber({ deviceId });
  };
  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  const handleInputChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    } else if (value > 60) {
      value = 60;
    }
    setQuantity(value);
  };

  const PRESET_WATER_SCHEDULE = {
    fertilizer: "Fertilizer / A + B (150%)", // "A" "B"
    sod: "Sodding / A + B + C (200%)", // "A" "B" "C"
    extra: "More Water / A + B (150%)", // "A" "B"
    daily: "Daily / A (100%)", // "A"
    flower: "Flower / B (50%)", // "B"
    no_water: "Do Not Water	/ All Programs Off	", // "A" "B" "C"
    less: "Less Water	/ B (50%)", // "B"
    water_now: "Default / A (100%)", // "A"
    flower_alt: "Flower (Every Alternate Day) / B (50%)", // "B"
    run_ab: "Run A", // "A"
    custom_bc: "Custom run B & C", // "B" "C"
    calibrate_flow: "Flow Sensor Calibration", // "A"
  };

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  const togglePrograms = () => {
    setShowPrograms(!showPrograms);
  };

  const tableData = [
    {
      type: "Default",
      combination: "A (100%)",
      frequency: "1, 2 or 3-day Skip",
    },
    {
      type: "More Water",
      combination: "A + B (150%)",
      frequency: "Only on Scheduled Irrigation Days",
    },
    {
      type: "Less Water",
      combination: "B (50%)",
      frequency: "Only on Scheduled Irrigation Days",
    },
    {
      type: "Flower",
      combination: "B (50%)",
      frequency: "Every Day for Specific Time (1 day, 1week, 1 month)",
    },
    {
      type: "Fertilizer",
      combination: "A + B (150%)",
      frequency: "One Time (now/next)",
    },
    {
      type: "Sodding",
      combination: "A + B + C (200%)",
      frequency: "Every Day for specific Time (1week, 1 month)",
    },
    {
      type: "Do Not Water",
      combination: "All Programs Off",
      frequency: "Every Day for specific Time (1 Day, 1week, 1 month)",
    },
  ];

  const handleImageChange = (e, zoneIndex) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

      // Update the image preview for the specific zone index
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        [zoneIndex]: imageUrl,
      }));
    }
  };

  const formatDays = (days) => {
    const dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return dayNames
      .filter((day) => days[day.toLowerCase()] === 1)
      .map((day) => (
        <span key={day} className="block">
          {day}
        </span>
      ));
  };

  const calculateDuration = (start, end) => {
    const startTime = new Date(`2000-01-01T${start}`);
    const endTime = new Date(`2000-01-01T${end}`);
    if (endTime < startTime) endTime.setDate(endTime.getDate() + 1);
    return Math.round((endTime - startTime) / 60000);
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes);

    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };

  // const formatZones = (zones, uploadedImages, zoneLabels = {}, program) => {
  //   return zones.map((zone, index) => {
  //     const [zoneName, duration] = Object.entries(zone)[0];
  //     const zoneNumber = parseInt(zoneName.replace("Zone", "").trim());

  //     // Get the actual zone label from zoneLabels, fallback to empty string if not available
  //     const customLabel = (zoneLabels && zoneLabels[`zone${zoneNumber}`]) || "";

  //     // Combine original zone name with custom label
  //     const displayName = customLabel
  //       ? `${zoneName}: ${customLabel}`
  //       : zoneName;

  //     // Determine if the current program is 'A'
  //     const isZoneA = program === "A";

  //     const matchingImage = uploadedImages.find(
  //       (img) => img.zone_number === zoneNumber
  //     );

  //     const imageElement = isZoneA ? (
  //       matchingImage ? (
  //         <img
  //           src={matchingImage.image_url}
  //           alt={displayName}
  //           className="w-10 h-10 mr-2 cursor-pointer"
  //           onClick={() => toggleFullScreenImage(matchingImage.image_url)}
  //         />
  //       ) : (
  //         <div>
  //           <input
  //             className="hidden"
  //             id={`file_input_${index}`}
  //             type="file"
  //             accept="image/*"
  //             onChange={(e) => handleImageChange(e, index)}
  //           />
  //           <div
  //             onClick={() =>
  //               document.getElementById(`file_input_${index}`).click()
  //             }
  //             className="cursor-pointer inline-flex items-center"
  //           >
  //             {imagePreviews[index] && (
  //               <img
  //                 src={imagePreviews[index]}
  //                 alt="Selected"
  //                 className="w-6 h-6 ml-2"
  //               />
  //             )}
  //           </div>
  //         </div>
  //       )
  //     ) : null;

  //     return (
  //       <div key={index} className="flex justify-between items-center mb-2">
  //         <h1>{`${displayName}: ${duration} minutes `}</h1>{" "}
  //         <div>{imageElement}</div>
  //       </div>
  //     );
  //   });
  // };

  const toggleFullScreenImage = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFullScreenImage(imageUrl);
    }
  };

  const handleSelectedProgramDetails = (
    program,
    start,
    end,
    zone,
    type,
    days
  ) => {
    setEditProgram(true);
    console.log(
      "SelectedProgramDetails: ",
      program,
      start,
      end,
      zone,
      type,
      days
    );
    setSelectedProgramDetails({
      program: program,
      start: start,
      end: end,
      zone: zone,
      type: type,
      days: days,
    });
  };

  const toggleEditProgram = () => {
    setEditProgram(!editProgram);
  };
  const getZoneValue = (zone) => {
    return zoneValues[`Zone${zone}`] !== undefined
      ? zoneValues[`Zone${zone}`]
      : "Select";
  };

  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const handleZoneValueChange = (zone, value) => {
    setZoneValues((prev) => ({ ...prev, [`Zone${zone}`]: value }));

    const updatedZones = selectedProgramDetails.program.zones.map((z) => {
      if (Object.keys(z)[0].trim() === `Zone${zone}`) {
        return { [`Zone${zone}`]: value };
      }
      return z;
    });

    setSelectedProgramDetails({
      ...selectedProgramDetails,
      program: {
        ...selectedProgramDetails.program,
        zones: updatedZones,
      },
    });
    setOpenDropdown(null);
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(editProgram || zoneListModal || showPrograms);
    return () => toggleBodyScroll(false);
  }, [editProgram, zoneListModal, showPrograms]);

  const dateOnly = (date) => {
    return new Date(date).toISOString().split("T")[0];
  };

  const handleDeleteCustomWateringProgram = async (
    company_location_custom_program_id
  ) => {
    swal
      .fire({
        title: "Do you want to delete this custome watering schedule?",
        text: `Custom Watering Schedule ID: "${company_location_custom_program_id} " will be deleted permanently.`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/delete-custom-watering-schedule",
              {
                email: email,
                password: password,
                company_location_custom_program_id:
                  company_location_custom_program_id,
              }
            );
            if (response.status === 200) {
              swal.fire({
                title: "Custom Watering Schedule Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchCustomWateringSchedule();
            } else if (response.data.Data.is_valid == false) {
              const errorMessages = Object.entries(response.data.Data.errors)
                .map(([field, messages]) => `${messages.join(", ")}`)
                .join("\n");

              swal.fire({
                title: errorMessages,
                icon: "error",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }
          } catch (error) {
            swal.fire({
              title: "There is something wrong. Please try again.",
              icon: "warning",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const handleDeactivateCustomWateringProgram = async (
    company_location_custom_program_id
  ) => {
    swal
      .fire({
        title: "Do you want to deactivate this custome watering schedule?",
        text: `Custom Watering Schedule ID: "${company_location_custom_program_id} " will be deleted permanently.`,
        showCancelButton: true,
        confirmButtonText: "Deactivate",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/deactivate-custom-watering-schedule",
              {
                email: email,
                password: password,
                company_location_custom_program_id:
                  company_location_custom_program_id,
              }
            );
            if (response.status === 200) {
              swal.fire({
                title:
                  "Custom watering schedule has been deactivated successfully.",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchCustomWateringSchedule();
            } else if (response.data.Data.is_valid == false) {
              const errorMessages = Object.entries(response.data.Data.errors)
                .map(([field, messages]) => `${messages.join(", ")}`)
                .join("\n");

              swal.fire({
                title: errorMessages,
                icon: "error",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }
          } catch (error) {
            swal.fire({
              title: "There is something wrong. Please try again.",
              icon: "warning",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setDropdown(!dropdown);
    setIsNumber2Selected(true);

    // Find the key corresponding to the selected value
    const programKey = Object.keys(PRESET_WATER_SCHEDULE).find(
      (key) => PRESET_WATER_SCHEDULE[key] === value
    );

    if (programKey) {
      setSelectedProgram(programKey);
    }
  };

  useEffect(() => {
    console.log("selectedProgram: ", selectedProgram);
    console.log("selectedZones: ", selectedZones);
    console.log("Added Date: ", currentDate);
    console.log("Start Date: ", startDate);
    console.log("End Date: ", endDate);
    console.log("Notes: ", notes);
    console.log("Zone List Data: ", zoneListData);
  }, [selectedProgram, selectedZones, endDate, startDate, notes]);

  const createZonesMap = (selectedZones, zoneListData, selectedPreset) => {
    const zonesMap = {};
    const programIds = {
      A: zoneListData.Programs.A?.[0]?.programId,
      B: zoneListData.Programs.B?.[0]?.programId,
      C: zoneListData.Programs.C?.[0]?.programId,
    };

    const presetPrograms = {
      fertilizer: ["A", "B"],
      sod: ["A", "B", "C"],
      extra: ["A", "B"],
      daily: ["A"],
      flower: ["B"],
      no_water: [],
      less: ["B"],
      water_now: ["A"],
      flower_alt: ["B"],
      run_ab: ["A"],
      custom_bc: ["B", "C"],
      calibrate_flow: ["A"],
    };

    const selectedPrograms = presetPrograms[selectedPreset] || [];

    selectedZones.forEach((zoneNumber) => {
      zonesMap[zoneNumber] = {};
      selectedPrograms.forEach((program) => {
        if (programIds[program]) {
          zonesMap[zoneNumber][programIds[program]] = program;
        }
      });
    });

    return zonesMap;
  };

  const handleAddCustomeWateringProgram = async (e) => {
    e.preventDefault();
    console.log("selectedProgram: ", selectedProgram);
    console.log("selectedZones: ", selectedZones);
    console.log("Added Date: ", formatDate(currentDate));
    console.log("Start Date: ", formatDate(startDate));
    console.log("End Date: ", formatDate(endDate));
    console.log("Notes: ", notes);

    const zonesMap = createZonesMap(
      selectedZones,
      zoneListData,
      selectedProgram
    );
    console.log("zonesMap: ", zonesMap);
    let requestBody = {
      company_location_id: selectedLocation.locationId,
      company_location_device_id: selectedDevice.company_location_device_id,
      email: email,
      password: password,
      preset: selectedProgram,
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      note: notes,
      date_added: formatDate(currentDate),
      zones_map: zonesMap,
    };
    console.log("requestBody : ", requestBody);

    // Now you can use zonesMap in your API request
    // const requestBody = {
    //   // ... other fields
    //   zones_map: zonesMap,
    // };
    swal
      .fire({
        title: "Do you want to add this custom watering schedule?",
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/insert-custom-watering-schedule",
              requestBody
            );

            console.log("response : " + response);
            if (response.status === 200) {
              swal.fire({
                title: "Custom Watering Schedule Added Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchCustomWateringSchedule();
              setIsNumber2Selected(false);
              setIsNumber3Selected(false);
              setSelectedValue("Select");
            }
          } catch (error) {
            swal.fire({
              title: "There is something wrong. Please try again.",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    // Initialize zoneInputs with existing zoneLabelNames
    setZoneInputs(zoneLabelNames || {});
  }, [zoneLabelNames]);

  const handleZoneInputChange = (zoneKey, value) => {
    setZoneInputs((prev) => ({ ...prev, [zoneKey]: value }));
  };

  const handleConfirmZoneLabels = () => {
    const confirmedZoneLabels = {};

    // Fill in the confirmed labels for all 42 zones
    for (let i = 1; i <= 42; i++) {
      const zoneKey = `zone${i}`;
      if (i <= zoneListData.Zones.A.length) {
        confirmedZoneLabels[zoneKey] = zoneInputs[zoneKey] || null;
      } else {
        confirmedZoneLabels[zoneKey] = null;
      }
    }

    const confirmedZoneLabelsJSON = JSON.stringify(confirmedZoneLabels);
    console.log("Confirmed Zone Labels:", confirmedZoneLabelsJSON);
    swal
      .fire({
        title: "Do you want to update this zone labels?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/update-zone-labels", {
              company_location_device_id:
                selectedDevice.company_location_device_id,
              email: email,
              password: password,
              zone_labels: confirmedZoneLabelsJSON,
            });

            if (response.status === 200) {
              swal.fire({
                title: `Zone labels updated successfully`,
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              setZoneListModal(false);
              // fetchCustomWateringSchedule();
              fetchZoneList();
            }
          } catch (error) {
            console.error("Error uploading image:", error);
            swal.fire({
              title: `There was an error uploading the image`,
              text: error.response?.data?.message || "Unknown error occurred",
              icon: "error",
            });
          }
        }
      });

    // Close the modal
    // setZoneListModal(false);
  };

  const renderTableRows = () => {
    console.log(zoneListData);
    if (!zoneListData || !zoneListData.Programs) {
      return (
        <tr>
          <td colSpan="8" className="text-center py-4">
            No program data available
          </td>
        </tr>
      );
    }

    return Object.entries(zoneListData.Programs).flatMap(
      ([programLetter, programs]) =>
        programs.map((program, programIndex) => {
          const allZones = zoneListData.Zones[programLetter] || [];
          const programZones = allZones;
          //programIndex === 0 ? allZones.slice(0, 4) : allZones.slice(4);

          return (
            <tr
              key={`${programLetter}-${programIndex}`}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <td className="border border-black dark:border-white px-6 py-4">
                {programLetter}
              </td>
              <td className="border border-black dark:border-white px-6 py-4">
                {formatTime(program.schedule.start_time)}
              </td>
              <td className="border border-black dark:border-white px-6 py-4">
                {formatTime(program.schedule.end_time)}
              </td>
              <td className="border border-black dark:border-white px-6 py-4">
                {calculateDuration(
                  program.schedule.start_time,
                  program.schedule.end_time
                )}
              </td>
              <td className="border border-black dark:border-white px-6 py-4">
                {getProgramType(program.schedule)}
              </td>
              <td className="border border-black dark:border-white px-6 py-4">
                <div className="flex flex-col">{formatDays(program.days)}</div>
              </td>
              <td className="border border-black dark:border-white px-6 py-4">
                <div className="flex flex-col overflow-x-auto">
                  {programZones.map((zone, zoneIndex) => {
                    const [zoneName, duration] = Object.entries(zone)[0];
                    const zoneNumber = parseInt(
                      zoneName.replace("Zone", "").trim()
                    );
                    const customLabel =
                      (zoneLabels && zoneLabels[`zone${zoneNumber}`]) || "";
                    const displayName = customLabel
                      ? `${zoneName}: ${customLabel}`
                      : zoneName;
                    const matchingImage = uploadedImages.find(
                      (img) => img.zone_number === zoneNumber
                    );

                    return (
                      <div
                        key={zoneIndex}
                        className="flex justify-between items-center mb-2"
                      >
                        <h1 className="w-28">{`${displayName}: ${duration} minutes `}</h1>
                        <div className="flex items-center">
                          {programLetter === "A" && (
                            <div>
                              {matchingImage ? (
                                <div className="flex items-center">
                                  <img
                                    src={matchingImage.image_url}
                                    alt={displayName}
                                    className="w-10 h-10 mr-2 cursor-pointer"
                                    onClick={() =>
                                      toggleFullScreenImage(
                                        matchingImage.image_url
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                <div>
                                  {/* <input
                                    className="hidden"
                                    id={`file_input_${programIndex}_${zoneIndex}`}
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleImageChange(e, zoneIndex)
                                    }
                                  /> */}
                                  {/* <div
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          `file_input_${programIndex}_${zoneIndex}`
                                        )
                                        .click()
                                    }
                                    className="cursor-pointer inline-flex items-center"
                                  >
                                    {imagePreviews[zoneIndex] ? (
                                      <img
                                        src={imagePreviews[zoneIndex]}
                                        alt="Selected"
                                        className="w-6 h-6 ml-2"
                                      />
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-6 h-6 ml-2"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                                        />
                                      </svg>
                                    )}
                                  </div> */}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </td>
            </tr>
          );
        })
    );
  };

  const getProgramType = (schedule) => {
    if (schedule.is_regular === 1) return "Regular";
    if (schedule.is_half_sprinkle === 1) return "Half Sprinkle";

    if (schedule.is_half_sprinkle === 1) return "is Day Overlap";

    return "Default";
  };

  useEffect(() => {
    const fetchZoneLabels = async () => {
      try {
        const response = await ApiLink.post("/api/company-location-device", {
          email: email,
          password: password,
          company_location_id: selectedLocation.locationId,
        });

        if (
          response.status === 200 &&
          response.data.Data &&
          response.data.Data.length > 0
        ) {
          const zoneLabelsData = JSON.parse(response.data.Data[0].zone_labels);
          setZoneLabels(zoneLabelsData);
        }
      } catch (error) {
        console.error("Error fetching zone labels:", error);
      }
    };

    if (selectedLocation.locationId) {
      fetchZoneLabels();
    }
  }, [email, password, selectedLocation.locationId]);

  return (
    <>
      <div className="flex justify-between p-2">
        {
          <>
            {selectedDevice ? (
              <div className="text-sm">
                <Link to={`/devices/${selectedDevice.device_id}`}>
                  <h1
                    className="hover:underline cursor-pointer text-black dark:text-white"
                    onClick={() =>
                      handleSerialNumberClick(selectedDevice.device_id)
                    }
                  >
                    {selectedDevice.serial_number}
                  </h1>
                </Link>
                <h1 className="serialNumber text-black dark:text-white font-semibold">
                  {selectedDevice.device_identifier}
                </h1>
                <h1 className="text-black dark:text-white">
                  LDID (Location Device ID):{" "}
                  {selectedDevice.company_location_device_id}
                </h1>
              </div>
            ) : (
              <h1 className="text-black dark:text-white">
                LDID (Location Device ID): N/A
              </h1>
            )}
            <div>
              {companyLocationDeviceData.length > 0 ? (
                <select
                  id="companydevices"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={handleLocationDevices}
                >
                  {companyLocationDeviceData.map((device) => (
                    <option
                      key={device.company_location_device_id}
                      value={device.company_location_device_id}
                    >
                      {device.device_identifier}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </>
        }
      </div>
      <div className="border border-black dark:border-white flex flex-col xl:flex-row p-4 gap-6 bg-white rounded-md justify-between dark:bg-gray-800">
        <div className="leftSide w-full xl:w-1/2 mb-4 xl:mb-0">
          <div className="number1 shadow-md p-3 border border-black dark:border-white">
            {customWateringAddLoading ? (
              <div role="status" className="flex justify-center items-center">
                <svg
                  aria-hidden="true"
                  class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <div className="flex flex-row animate__animated animate__fadeIn text-center items-center p-3 text-xl font-semibold rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                <h1
                  className="rounded-3xl text-sm text-white"
                  style={{
                    padding: 5,
                    width: 30,
                    height: 30,
                    borderRadius: 30 / 2,
                    backgroundColor: `${backgroundColor}`,
                    color: `${textColor}`,
                  }}
                >
                  1
                </h1>
                <div className="flex justify-between items-center">
                  <h1 className="ml-4"> What would you like to do</h1>
                  <div className="flex">
                    <h1
                      className="ml-4 text-sm p-2 text-white rounded-sm cursor-pointer"
                      style={{ backgroundColor: "#3498DB" }}
                      onClick={toggleTable}
                    >
                      Info
                    </h1>

                    <h1
                      className="ml-4 text-sm p-2 text-white rounded-sm cursor-pointer"
                      style={{ backgroundColor: "#3498DB" }}
                      onClick={togglePrograms}
                    >
                      Program
                    </h1>
                  </div>
                </div>
              </div>
            )}

            <div className="relative inline-block mt-4">
              {showTable && (
                <div className="mt-4 p-4 bg-white dark:bg-gray-800 rounded">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-white dark:bg-gray-800">
                      <tr
                        style={{
                          backgroundColor: `${tableHeaderColor}`,
                          color: `${tableHeaderTextColor}`,
                        }}
                        className="border border-black dark:border-white"
                      >
                        <th className="px-6 py-3 border border-black text-xs">
                          Program Types
                        </th>
                        <th className="px-6 py-3 border border-black text-xs">
                          Program Combinations
                        </th>
                        <th className="px-6 py-3 border border-black text-xs">
                          Frequency
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200">
                      {tableData.map((row, index) => (
                        <tr
                          key={index}
                          className="odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                        >
                          <td className="border border-black dark:border-gray-300 text-sm px-6 py-4 flex-col text-black dark:text-white leading-4">
                            {row.type}
                          </td>
                          <td className="border border-black dark:border-gray-300 text-sm px-6 py-4 flex-col text-black dark:text-white leading-4">
                            {row.combination}
                          </td>
                          <td className="border border-black dark:border-gray-300 text-sm px-6 py-4 flex-col text-black dark:text-white leading-4">
                            {row.frequency}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {showPrograms && (
                <div
                  id="static-modal"
                  data-modal-backdrop="static"
                  tabIndex="-1"
                  aria-hidden="true"
                  className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
                  style={{
                    // backdropFilter: "blur(5px)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <div className="relative p-2 max-w-7xl max-h-full">
                    <div className="p-4 relative bg-white rounded-lg shadow dark:bg-gray-800">
                      <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                          Programs
                        </h3>
                        <button
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-toggle="defaultModal"
                          onClick={togglePrograms}
                        >
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                      <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                          <thead
                            className={`text-sm uppercase leading-4`}
                            style={{
                              backgroundColor: `${tableHeaderColor}`,
                              color: `${tableHeaderTextColor}`,
                            }}
                          >
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Program
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Start Time
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                End Time
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Duration (mins)
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Program Type
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Scheduled Days
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black"
                              >
                                Zone Info
                              </th>
                            </tr>
                          </thead>
                          <tbody className="leading-4">
                            {loading ? (
                              <tr>
                                <td colSpan="8" className="h-16">
                                  <div
                                    role="status"
                                    className="flex justify-center items-center h-full"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              renderTableRows(zoneListData)
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {fullScreenImage && (
                <div
                  className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                  onClick={() => setFullScreenImage(null)}
                >
                  <img
                    src={fullScreenImage}
                    alt="Full screen"
                    className="max-h-screen max-w-screen object-contain"
                  />
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              )}
              {customWateringAddLoading ? (
                <div></div>
              ) : (
                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                  style={{
                    backgroundColor: `${backgroundColor}`,
                    color: `${textColor}`,
                  }}
                  onClick={toggleDropdown}
                >
                  {selectedValue}
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
              )}
              {/* <!-- Dropdown menu --> */}
              {dropdown && (
                <div
                  id="dropdown"
                  className="z-10 animate__animated animate__fadeIn bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200 cursor-pointer"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    {Object.values(PRESET_WATER_SCHEDULE).map((option) => (
                      <li key={option}>
                        <div
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={() => handleSelect(option)}
                        >
                          {option}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {isNumber2Selected && (
            <div className="number2 shadow-md p-3 border border-black dark:border-white">
              <div
                className="mt-4 flex flex-row text-center items-center p-3 text-xl
    font-semibold rtl:text-right text-gray-900 bg-white
    dark:text-white dark:bg-gray-800"
              >
                <h1
                  className="rounded-3xl text-sm text-white"
                  style={{
                    padding: 5,
                    width: 30,
                    height: 30,
                    borderRadius: 30 / 2,
                    backgroundColor: `${backgroundColor}`,
                    color: `${textColor}`,
                  }}
                >
                  2
                </h1>
                <h1 className="ml-4"> Please Select a zone</h1>
              </div>
              <div className="animate__animated animate__fadeIn">
                <div className="rounded overflow-x-auto">
                  <div className="ml-4 mt-4">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      className="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      checked={isAllZonesSelected}
                      onChange={handleAllZonesSelect}
                      style={{ color: backgroundColor }}
                    />
                    <label
                      htmlFor="bordered-checkbox-1"
                      className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      All Zones
                    </label>
                  </div>
                  <div className="grid text-sm cursor-pointer grid-cols-3 gap-4 mb-4 ps-4 pt-4 mt-4 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700">
                    {zoneListData.Zones.A &&
                      Array.isArray(zoneListData.Zones.A) &&
                      zoneListData.Zones.A.map((zone, index) => {
                        // Get zone number from the zone object
                        const zoneNumber = index + 1;

                        // Get zone name in format "Zone{number}"
                        const zoneKey = `Zone${zoneNumber}`;

                        if (!zoneKey) {
                          return null;
                        }

                        return (
                          <div className="mt-2" key={index}>
                            <input
                              id={`bordered-checkbox-${index + 2}`}
                              type="checkbox"
                              value=""
                              name="bordered-checkbox"
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              checked={zoneCheckboxes[index]}
                              onChange={() =>
                                handleCheckboxSelect(index, zoneKey)
                              }
                              style={{ color: backgroundColor }}
                            />
                            <label
                              htmlFor={`bordered-checkbox-${index + 2}`}
                              className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              {zoneKey}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isNumber3selected && (
            <div className="animate__animated animate__fadeIn">
              <div className="number3 shadow-md p-3 border border-black dark:border-white">
                <div className="flex  flex-row text-center items-center p-3 text-xl font-semibold rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  <h1
                    className="rounded-3xl text-sm text-white "
                    style={{
                      padding: 5,
                      width: 30,
                      height: 30,
                      borderRadius: 30 / 2,
                      backgroundColor: `${backgroundColor}`,
                      color: `${textColor}`,
                    }}
                  >
                    3
                  </h1>
                  <h1 className="ml-4"> When would you like to start?</h1>
                </div>
                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
                  Star Date
                </p>
                <div style={{ width: 200, marginTop: 10 }}>
                  <div className="relative cursor-pointer">
                    <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderText="Select start date"
                    />
                  </div>
                </div>
                <div className="z-50 ">
                  <div className="mt-4">
                    <form className="">
                      <label
                        htmlFor="quantity-input"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        For how long?
                      </label>
                      <div className="relative flex items-center max-w-[8rem]">
                        <button
                          type="button"
                          id="decrement-button"
                          data-input-counter-decrement="quantity-input"
                          onClick={decrementQuantity}
                          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                        >
                          <svg
                            className="w-3 h-3 text-gray-900 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 2"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 1h16"
                            />
                          </svg>
                        </button>
                        <input
                          type="text"
                          id="quantity-input"
                          data-input-counter
                          aria-describedby="helper-text-explanation"
                          className="bg-gray-200 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          value={quantity}
                          onChange={handleInputChange}
                          required
                        />
                        <button
                          type="button"
                          id="increment-button"
                          data-input-counter-increment="quantity-input"
                          onClick={incrementQuantity}
                          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                        >
                          <svg
                            className="w-3 h-3 text-gray-900 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 1v16M1 9h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="number4 shadow-md p-3 border border-black dark:border-white">
                <div className="flex flex-row text-center items-center p-3 text-xl font-semibold rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  <h1
                    className="rounded-3xl text-sm text-white"
                    style={{
                      padding: 5,
                      width: 30,
                      height: 30,
                      borderRadius: 30 / 2,
                      backgroundColor: `${backgroundColor}`,
                      color: `${textColor}`,
                    }}
                  >
                    4
                  </h1>
                  <h1 className="ml-4"> Note</h1>
                </div>
                <div>
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your message
                  </label>
                  <textarea
                    id="message"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your thoughts here..."
                    style={{ width: 400 }}
                  ></textarea>
                </div>
                <button
                  className="mt-2 p-2 text-white rounded-lg"
                  style={{
                    backgroundColor: `${backgroundColor}`,
                    color: `${textColor}`,
                  }}
                  onClick={handleAddCustomeWateringProgram}
                >
                  Save Schedule
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="rightSide w-full xl:w-1/2 xl:flex-row relative overflow-x-auto border">
          <div className="flex flex-row justify-between items-center border border-black">
            <div className="flex justify-between text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              <h1 className="p-5 ">Custom Watering Schedule</h1>
            </div>
            <div className="p-5">
              <button
                className="p-2 bg-yellow-400 hover:bg-yellow-500 text-white rounded-md text-xs"
                onClick={() => setZoneListModal(true)}
              >
                Zone Info
              </button>
            </div>
          </div>
          {zoneListModal && (
            <div
              id="defaultModal"
              tabindex="-1"
              aria-hidden="true"
              className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
              style={{
                // backdropFilter: "blur(5px)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="relative p-4 w-full max-w-7xl h-full md:h-auto">
                {/* Modal content */}
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                  {/* Modal header */}
                  <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white"></h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                      onClick={() => setZoneListModal(!zoneListModal)}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <form>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-7">
                      {isLoading ? (
                        <div className="col-span-7 text-center py-4">
                          <p>Loading...</p>
                        </div>
                      ) : zoneListData.Zones.A &&
                        zoneListData.Zones.A.length > 0 ? (
                        zoneListData.Zones.A.map((zone, index) => {
                          const zoneKey = `zone${index + 1}`;
                          return (
                            <div key={index} className="flex flex-col">
                              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                {`Zone ${index + 1}`}
                              </label>
                              <input
                                type="text"
                                className="bg-gray-50 border mb-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder={`Zone ${index + 1}`}
                                value={zoneInputs[zoneKey] || ""}
                                onChange={(e) =>
                                  handleZoneInputChange(zoneKey, e.target.value)
                                }
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-span-7 text-center py-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>
                          <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                            No Zone List Found
                          </p>
                        </div>
                      )}
                    </div>
                  </form>

                  <div className="flex items-center justify-end pb-4">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={() => setZoneListModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "#62CB31" }}
                      onClick={() => handleConfirmZoneLabels()}
                    >
                      Confirm Zone Labels
                    </button>
                  </div>
                  {/* Modal footer */}
                </div>
              </div>
            </div>
          )}

          <table className="border border-black dark:border-white animate__animated animate__fadeInRight w-full cursor-pointer text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead
              className="text-sm uppercase text-black dark:text-white bg-gray-200 dark:bg-gray-700 leading-4"
              style={{
                backgroundColor: `${tableHeaderColor}`,
                color: `${tableHeaderTextColor}`,
              }}
            >
              <tr>
                <th scope="col" className="px-6 py-3 border border-black">
                  Start Date
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  End Date
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Description
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-black dark:text-white">
              {loading ? (
                <tr>
                  <td colSpan="7" className="h-64">
                    <div
                      role="status"
                      className="flex justify-center items-center h-full"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : customWateringScheduleData.length > 0 ? (
                customWateringScheduleData.map((wateringSchedule) => (
                  <tr
                    key={wateringSchedule.company_location_custom_program_id}
                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800bg-white border-b dark:bg-gray-800 dark:border-gray-700 overflow-x-auto"
                  >
                    <td className="border border-black dark:border-white px-6 py-4">
                      {wateringSchedule.date_start}
                    </td>
                    <td className="border border-black dark:border-white px-3 py-4 ">
                      {wateringSchedule.date_modified == null ? (
                        <h1>{wateringSchedule.date_end}</h1>
                      ) : (
                        <h1>{dateOnly(wateringSchedule.date_modified)}</h1>
                      )}

                      <h1
                        className={`${
                          wateringSchedule.date_modified == null
                            ? "hidden"
                            : "block"
                        }`}
                      >
                        (Scheduled: {wateringSchedule.date_end})
                      </h1>
                    </td>

                    <th
                      scope="row"
                      className="border border-black dark:border-white px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <div>
                        <div className="flex justify-between gap-6 items-center">
                          <div className="flex flex-col w-24 mr-5">
                            <h1>
                              {PRESET_WATER_SCHEDULE[wateringSchedule.preset]}
                            </h1>
                            <div className="flex flex-col w-48 mr-5">
                              <h1 className="whitespace-nowrap w-72 overflow-x-auto">
                                {wateringSchedule.note}
                              </h1>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div>
                              {wateringSchedule.status === 1 && (
                                <>
                                  {wateringSchedule.can_inactivate == true ? (
                                    <button
                                      className="p-2 text-xs rounded-md font-semibold text-white bg-yellow-400 hover:bg-yellow-500"
                                      onClick={() =>
                                        handleDeactivateCustomWateringProgram(
                                          wateringSchedule.company_location_custom_program_id
                                        )
                                      }
                                    >
                                      Deactivate
                                    </button>
                                  ) : wateringSchedule.can_delete == true ? (
                                    <button
                                      className="p-2 text-xs rounded-md text-white font-semibold bg-red-600 hover:bg-red-700"
                                      onClick={() =>
                                        handleDeleteCustomWateringProgram(
                                          wateringSchedule.company_location_custom_program_id
                                        )
                                      }
                                    >
                                      Delete
                                    </button>
                                  ) : null}
                                </>
                              )}
                              {wateringSchedule.status === 0 && (
                                <button className="p-2 text-xs rounded-md text-gray-600 font-semibold bg-[#EBEFF1]">
                                  Deactivated
                                </button>
                              )}
                              {wateringSchedule.status === 3 && (
                                <button className="p-2 text-xs rounded-md text-gray-600 font-semibold bg-[#EBEFF1]">
                                  Deleted
                                </button>
                              )}
                            </div>
                            <div className="flex gap-2">
                              <div
                                className={`${
                                  wateringSchedule.status === 0
                                    ? "hidden"
                                    : "block"
                                } ${
                                  wateringSchedule.status === 3
                                    ? "hidden"
                                    : "block"
                                }`}
                                onClick={() =>
                                  handleDeactivateCustomWateringProgram(
                                    wateringSchedule.company_location_custom_program_id
                                  )
                                }
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-9">
                          {Object.keys(wateringSchedule.details).map(
                            (zoneNumber) => (
                              <p
                                key={zoneNumber}
                                className="rounded-full text-sm text-white text-center"
                                style={{
                                  padding: 5,
                                  width: 30,
                                  height: 30,
                                  borderRadius: "50%",
                                  marginTop: 5,
                                  backgroundColor: backgroundColor,
                                  color: textColor,
                                }}
                              >
                                {zoneNumber}
                              </p>
                            )
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>

                    <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                      No Custom Watering Schedule Found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
