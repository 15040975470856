import React, { useContext } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";

export default function Reports() {
  const { sideBarOpen } = useContext(ThemeContext);
  return (
    <div
      className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 p-4 mt-20`}
      style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
    >
      <div className="p-4 bg-white h-screen shadow-md rounded-md dark:bg-gray-800">
        <h1 className="text-2xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800 mb-4">
          Reports
        </h1>

        <div
          className="relative overflow-x-auto sm:rounded-lg "
          style={{ width: 200 }}
        >
          <table className="animate__animated animate__fadeIn cursor-pointer text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 leading-4">
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                <th
                  scope="row"
                  className="px-7 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  Apple MacBook Pro 17"
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
