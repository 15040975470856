import React, { useEffect, useState } from "react";
import ApiLink from "../../components/api/ApiLink";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useSelectedCompany } from "../../components/context/apiContext/SelectedCompanyContext";
import { logoutAndClearState } from "../../store/logoutAndClearState";
import { useAuth } from "../../components/context/authContext/AuthContext";
export default function Company8() {
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const [landScapeData, setLandScapeData] = useState([]);
  const { updateSelectedLocation } = useSelectedCompany();
  const dispatch = useDispatch();
  const { logoutUser } = useAuth();

  const fetchLandScape = async () => {
    try {
      const response = await ApiLink.post("/api/get-land-scape-data", {
        email: email,
        password: password,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setLandScapeData(response.data.Data);
        } else {
          setLandScapeData([]);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setLandScapeData([]);
    }
  };

  useEffect(() => {
    fetchLandScape();
  }, [email, password]);

  // const handleClickLandsScape = (
  //   company_location_id,
  //   name,
  //   company_id,
  //   city,
  //   state,
  //   zip,
  //   address_line1,
  //   type
  // ) => {
  //   updateSelectedLocation({
  //     locationId: company_location_id,
  //     locationName: name,
  //     companyId: company_id,
  //     city: city,
  //     state: state,
  //     zip: zip,
  //     address: address_line1,
  //     type: type,
  //   });
  // };

  return (
    <div
      id="defaultModal"
      tabindex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 font-inter"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="relative p-4 min-w-7xl max-w-7xl h-full md:h-auto my-8">
        {/* <!-- Modal content --> */}
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 overflow-y-auto max-h-[90vh]">
          {/* <!-- Modal header --> */}
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Please Select an Account Location
            </h3>
            <button
              type="button"
              className="border border-black hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                />
              </svg>

              <span
                onClick={logoutUser}
                className="font-medium text-gray-900 ml-1 dark:text-gray-300"
              >
                Logout
              </span>
            </button>
          </div>

          {landScapeData.map((landScape) => (
            <div key={landScape.company_location_id}>
              <div className="flex flex-col cursor-pointer">
                <h1
                  // onClick={() =>
                  //   handleClickLandsScape(
                  //     landScape.company_location_id,
                  //     landScape.name,
                  //     landScape.company_id,
                  //     landScape.city,
                  //     landScape.state,
                  //     landScape.zip,
                  //     landScape.address_line1,
                  //     landScape.type
                  //   )
                  // }
                  className="text-black dark:text-white text-lg font-medium hover:underline"
                >
                  <Link to={`/location/${landScape.company_location_id}`}>
                    {landScape.name}
                  </Link>
                </h1>
                <h1 className="text-black dark:text-white text-md font-normal">
                  {landScape.name} ({landScape.company_id}{" "}
                  {landScape.address_line1}, {landScape.city}, {landScape.state}
                  , {landScape.zip} )
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
